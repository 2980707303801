@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');
@import '../../../../styles//mixins.scss';

.section {
  margin: 150px 0;
  width: 100%;

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 80px !important;

    @include mobile {
      display: block;
    }

    .serviceItem {
      position: relative;
      flex-basis: calc(25% - 20px);
      text-align: center;

      @include mobile {
        width: 100%;
        margin: 40px 0;
      }

      h3 {
        font-weight: 600;
        font-size: 52px !important;
        line-height: 64px;
        text-align: center;
        letter-spacing: -0.03em;

        color: #FFFFFF;
        color: #fff;
      }
      p {
        color: #9A98AA;
      }
    }
  }
}