@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.purple {
  background: -webkit-linear-gradient(358deg, #947BF2, #947BF2) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.bg2 {
  background-image: url("/assets/images/48095923.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.hero {
  background-image: url("/assets/images/home/herobg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  padding: 0 50px;
  margin-top: -40px;

  position: relative;
  overflow: hidden;

  @include mobile {
    background-position: center;
    background-size: auto;
    height: 80vh;
    padding: 20px;
    margin-top: -90px;
  }
  .overlay {
    background: rgb(3, 1, 30);
    background: linear-gradient(0deg, #03011e 0%, rgba(255, 255, 255, 0) 50%, #03011e 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include mobile {
      background: rgb(3, 1, 30);
      background: linear-gradient(180deg, #03011e 0%, rgba(3, 1, 30, 0) 0%, #03011e 90%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .container {
    display: flex;
    justify-content: center; /* horizontal center */
    align-items: center; /* vertical center */
    height: 100%;
  }

  .heading {
    position: relative;
    bottom: 0;
    width: 100%;
    margin: auto;

    @include mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 150px;
      padding-left: 20px;
    }

    .heading_content {
      margin-top: -180px;
      width: 100%;
    }

    h1 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 71px;
      
      width: fit-content;
      color: #fff0fa;

      @include mobile {
        font-size: 42px;
        line-height: 42px;
      }

      span {
        background: linear-gradient(104.76deg, #9B83F9 2.7%, #8164F5 24.47%, #27AEF8 47.07%, #31A2FA 73.41%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;

      color: #BFC0D1;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .button {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 209px;
    height: 56px;
    color: #02001f;
    text-decoration: none;
    background-color: #59d6e6;
    border: 1px solid #59d6e6;
    border-radius: 12px;

    margin-top: 40px;
    transition: ease-in-out 0.25s;

    .icon {
      font-size: 28px;
    }

    &:hover {
      color: #02001f;
      background-color: #33919e;
    }

    @include mobile {
      width: 100%;
    }
  }
}