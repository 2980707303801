@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.animateIn {
  .header {
    opacity: 1;
    transition: all 1s;
  }
  .image {
    transform: rotate(0) scale(1);
    opacity: 1;
    transition: all 2s;
  }
}

.animateOut {
  h3 {
    opacity: 0;
    transition: all 1s;
  }
  .image {
    transform: rotate(90deg) scale(0);
    opacity: 0;
    transition: all 2s;
  }
  
}

.section {
  width: 100%;
  margin: 200px 0;

  //padding: 0 50px 200px 50px;
  //padding-bottom: 100px;
  @include mobile {
    display: none;
    padding: 20px;

    .break {
      display: none !important;
    }
  }

  .header {
    margin: 40px 0;
    text-align: center;
    padding-bottom: 50px;
    h3 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      color: #FFFFFF;

      span {
        background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    p {
      margin-top: 14px;
      color: #BFC0D1;
    }
  }

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    //max-width: 700px;
    width: 100%;
    margin: auto;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, #03011e -20%, transparent, transparent);
      mask-image: linear-gradient(to bottom, transparent, white, transparent);
      -webkit-mask-image: linear-gradient(to bottom, #03011e, transparent, transparent);
    }
    img {
      width: 100%;
      margin: auto;
    }
  }
}