$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

$background: #313143;

.googleBtn {
  width: 230px;
  height: 50px;
  background-color: $background;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin:5px;
  user-select: none;
  .googleIconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    margin-left: 1px;
    height: 50px;
    border-radius: 10px;
    background-color: transparent;
    width: 20%;
  }

  .text {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    align-items: center;
  }

  .googleIcon {
    width: 20px;
    height: 20px;
  }

  .btnText {
    color: $white;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }

  &:hover {
    background-color: #45455f;
  }

  &:active {
    background: $button-active-blue;
  }
}


.fbBtn {
  width: 230px;
  height: 50px;
  background-color: #4051b5;
  border-radius: 10px;
  border: 2px solid #4051b5;
  cursor: pointer;
  color: white;

  .googleIconWrapper {
    position: absolute;
    margin-top: 5px;
    margin-left: 1px;
    width: 35px;
    height: 36px;
    border-radius: 2px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .googleIcon {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    width: 40px;
    height: 40px;
  }
  .btnText {
    margin: 11px 1px 0 40px;
    color: white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);