@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

/* Partner CSS Start */
.partnerSection {
  .partners {
    display: flex;
    flex-wrap: wrap;
    .partnerImage {
      flex-basis: calc(25% - 20px);
      width: 217px;
      height: 48px;
      margin: 125px auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @include mobile {
        flex-basis: calc(30% - 20px);
        width: 217px;
        height: 48px;
        margin: 20px auto;
      }
    }
  }
  .partnerText {
    text-align: center;
    font-weight: 600;
    font-size: 42px;
    line-height: 56px;
    
    text-align: center;
    letter-spacing: -0.04em;
    
    color: #fff !important;
    
    
    letter-spacing: 0.06em;
    text-transform: uppercase;
    
    span {
      background: linear-gradient(108.72deg, #A089F7 32.63%, #8164F5 45.41%, #27AEF8 54.14%, #31A2FA 68.25%), #F6F5FF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    @include mobile {
      font-size: 28px;
    }
  }
}

.discover {
  width: 100%;
  padding: 100px 50px;
  text-align: center;

  /* background-image: url('../../../staticAssets/missionbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 */

  background: linear-gradient(270deg, #00000000, #0c063b, #21053a, #00000000);
  background-size: 600% 600%;
  animation: gradient 10s ease infinite;
  //margin: 100px 0;

  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background: rgb(2,0,31);
    background: linear-gradient(180deg, #03011e 0%, rgba(2,0,31,0) 50%, rgba(2,0,31,0) 50%, #03011e 100%);
  }

  @include mobile {
    padding: 150px 0;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    .row {
      display: flex;
      justify-content: center;
    }
  }

  .container {
    max-width: 1450px !important;
    margin: auto;
  }

  .discoverContainer {
    margin: 100px 0;
    background-image: url("/assets/images/home/hero2_lines.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position: bottom;

    @include mobile {
      padding: 0 20px;
    }
  }

  .header {
    h3 {
      padding: 20px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 53px;
      text-align: left;
      letter-spacing: -0.02em;
      color: #fff0fa;

      span {
        background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      @include mobile {
        padding: 0;
        font-size: 28px;
        line-height: 24px;
      }
    }
  }

  .items {
    width: 1100px;
    margin: auto;

    @include mobile {
      width: 100% !important;
      margin-bottom: 0;
    }

    .item {
      background: transparent !important;

      .card {
        width: 100%;
        height: 100%;
        background-color: transparent !important;
        box-shadow: none;
        padding: 5px;

        @include mobile {
          margin: auto;
        }

        .content {
          display: flex;
          align-items: center;
          gap: 80px;
          height: 100%;

          @include mobile {
            display: block;
          }

          .details {
            width: 100%;
            text-align: left;

            h3 {
              font-weight: 600;
              font-size: 48px;
              line-height: 56px;
              letter-spacing: -0.02em;
              color: #fff0fa;

              margin: 20px 0 18px;

              span {
                background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              @include mobile {
                padding: 0;
                font-size: 32px;
                line-height: 34px;
              }
            }

            .sub_title {
              font-weight: 500;
              font-size: 13.2997px;
              line-height: 17px;
              letter-spacing: 0.06em;
              text-transform: uppercase;
              -webkit-font-feature-settings: "cv05"on, "cv11"on;
              font-feature-settings: "cv05"on, "cv11"on;
              color: #86E2EE;
            }

            .desc {
              font-weight: 500;
              font-size: 16px !important;
              line-height: 19px;
              letter-spacing: 0.02em;
              li {
                font-size: 16px;
                font-weight: 700;
                
                
                color: #999CB6;
              }

              p {
                color: #999CB6;
                b {
                  color: #fff;
                }

                img {
                  width: 28px;
                }
              }
            }
          }
        }

        .image_container {
          border-radius: 8px;
          max-width: 400px;
          width: 100%;

          img {
            width: 100%;
          }

          @include mobile {
            width: 100%;
            padding: 20px 0;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .button {
    margin-top: 40px;
  }
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}