@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.about {
  width: 100%;
  //height: 970px;
  padding: 50px 50px;

  //margin: 100px 0;
  @include mobile {
    padding: 20px;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    padding: 20px 0;

    .column {
      width: 50% !important;
    }
  }

  .container {
    max-width: 1200px !important;
    //width: calc(100% - 500px);
    margin: auto;
  }

  .bottomCards {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    gap: 20px;
    margin-top: 80px !important;

    //padding: 100px 0;
    .cardItem {
      background: rgba(255, 255, 255, 0.04);
      width: calc(50% - 20px);
      height: 400px;
      padding: 10px;
      border-radius: 16px;
      p {
        padding: 40px 30px;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;

        color: #D9DAE8;
      }
    }

    @include mobile {
      display: block;
      width: 100%;

      .cardItem {
        padding: 20p 5px;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 16px;
        width: 100%;

        .content {
          padding: 20px 0;
          p {
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;

            color: #fff;
          }
        }
      }
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 13.2997px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-feature-settings: 'cv05'on, 'cv11'on;

    color: #86E2EE;
  }

  h3 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    color: #fff0fa;

    margin: 20px 0 18px;

    span {
      background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @include mobile {
      padding: 0;
      font-size: 48px;
      line-height: 56px;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    /* or 172% */


    color: #999CB6;
  }

  .featured_nft_image {
    width: 200px;
  }


  .item {
    height: 600px;
    width: 100%;
    margin: auto;
    margin-top: 56px;
    background-color: #04041a !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 24px;
    transition: ease-in-out 0.3s;

    @include mobile {
      height: 300px;
      width: 100%;
      background-position: right !important;
    }

    .video {
      height: 100%;
    }
    .loop_player {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    .playButton {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 100%;

      img {
        width: 125px;
        transition: ease-in-out 500ms;

        &:hover {
          width: 100px;
          cursor: pointer;
        }

        &:active {
          width: 125px;
          opacity: 0.5;
          transition: ease-in-out 100ms;
        }
      }
    }
  }
}