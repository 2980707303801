@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.section {
  text-align: center;
  padding: 50px 0;

  .banner {
    background-image: url("/assets/images/home/partnerbg.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;

    width: 100%;
    height: 350px;
    border-radius: 24px;

    text-align: center;

    padding: 50px !important;
    margin: 100px auto;

    @include mobile {
      height: auto;
      padding: 25px !important;
      margin: 50px auto;
    }

    .content {
      margin-bottom: 30px;

      h4 {
        font-weight: 700;
        font-size: 48px;
        line-height: 49px;
        letter-spacing: -0.03em;
        color: #ffffff;

        @include mobile {
          font-size: 32px;
          line-height: 30px;
        }

        span {
          background: linear-gradient(104.76deg, #9B83F9 2.7%, #8164F5 24.47%, #27AEF8 47.07%, #31A2FA 73.41%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }
    }
  }
}