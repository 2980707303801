.ui.modal {
  top: -5% !important;
  border-radius: 15px !important;
  background: #0f0f17 !important;
  height: auto;
}

.ui.mini.modal {
  width: 500px;
  top: 25% !important;
  left: 45%;
  border: solid 1px #232227;
  @include mobile {
    top: 5% !important;
    left: 50%;
    width: 95%;
  }
}

.ui.modal>.header {
  background-color: transparent !important;
  color: #89F5C2 !important;
  text-align: center;
  text-transform: uppercase;
}

.ui.modal>.content {
  background: #0f0f17 !important;
  color: var(--green);
  padding-top: 25px;
  .input-label {
    color: #fff !important;
    font-weight: normal;
  }
  .login-input {
    color: #fff;
    background: #34323a;
    border: 2px solid #34323a;
  }
  .login-input:focus {
    color: #fff;
    background: #34323a;
    border: 2px solid #59d6e6;
  }
  .ui.form{
    span {
      color: #59d6e6;
    }
    button.ui.button.forgot-password{
      background: transparent !important;
      border: solid 2px #59d6e6;
      margin-bottom: $padding;
      float: left;
    }
  }
  .login_footer {
    margin-top: 25px;
    button {
      color: var(--header-back) !important;
      background: #59d6e6;
      border: solid 2px #59d6e6;
      border-radius: 10px;
      margin-top: 15px;
      transition: ease-in-out 0.3s;
      &:hover {
        background: #59d6e6;
      }
      &:disabled {
        background: #161621 !important;
        border: solid 2px #161621;
      }
    }
  }
  .forgot-password {
    border: none !important;
    background: transparent !important;
    &:hover {
      background: transparent !important;
      color: #59d6e6 !important;
    }
    &:disabled {
      background: #303030 !important;
      border: solid 2px #59d6e6;
    }
  }
}

.action-divider {
  font-size: 16px !important;
  text-align: center;
  text-transform: uppercase;
}

.ui.modal>.actions {
  background: #0f0f17 !important;
  display: block;
  width: 100%;
  .action-divider {
    color: #fff;
  }
  button {
    margin-left: 0em !important;
  }
  .signup {
    background: transparent !important;
    border: solid 2px #59d6e6 !important;
    text-transform: uppercase;
    &:hover {
      background: #59d6e6 !important;
      border: solid 2px #59d6e6;
    }
  }
  .action-button {
    color: var(--header-back) !important;
    background: rgba(136, 125, 234, 1);
    border: solid 2px rgba(136, 125, 234, 1);
    border-radius: 10px;
    margin-top: 15px;
    transition: ease-in-out 0.3s;
    &:hover {
      background: rgb(119, 109, 209);
      border: solid 2px rgb(119, 109, 209);
    }
  }
  .cancel-button {
    background: transparent;
    color: var(--text-color);
  }
  .cancel-button:hover {
    color: var(--green);
  }
  .submit-button {
    color: var(--header-back) !important;
    background: rgba(136, 125, 234, 1);
    border: solid 2px rgba(136, 125, 234, 1);
    border-radius: 10px;
    transition: ease-in-out 0.3s;
    &:hover {
      background: rgb(119, 109, 209);
      border: solid 2px rgb(119, 109, 209);
    }
  }
  .forgot-password{
    background: transparent;
    margin-bottom: $padding;
    float: left;
    color: var(--green);
    margin: auto;
  }
}
@keyframes pop-swirl {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.mini, .loopsignup{-webkit-animation: pop-swirl 1s ease forwards; /*trigger the keyframe*/}
.loginModalHeader{
  display: inline-flex !important;
  width: 100%;
  padding-right: 1.25rem !important;
}
.loginModalHeader>p{
  width: 100%;
  height: 32px;
  margin-bottom: 0;
  font-size: 18px;
  padding-top: 3px;
}
.loginCloseBtn{
  height: 32px !important;
  width: 36px !important;
  padding: 0 !important;
}