@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.section {
  .header {
    margin: 40px 0;
    text-align: center;
    h3 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      color: #FFFFFF;

    }
  
    b {
      color: #59d6e6;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      letter-spacing: 0.06em;
      text-transform: uppercase;
    }
    span {
      //display: block;
      background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      color: #fff;
    }
  }

  width: 100%;

  //padding: 0 50px 200px 50px;
  //padding-bottom: 100px;
  @include mobile {
    padding: 150px 20px;

    .break {
      display: none !important;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    gap: 20px;
    margin-top: 80px !important;

    //padding: 100px 0;
    .service_item {
      width: 322px;
      flex-basis: calc(33% - 20px);
      padding: 40px;
      background: rgba(255, 255, 255, 0.04);
      border-radius: 16px;
      opacity: 0;
      transform: scale(0);
      transition: ease-in-out 400ms;
      b {
        color: #fff;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.147);
        cursor: pointer;
      }
    }

    .animateItem {
      opacity: 1;
      transform: scale(1);
    }

    @include mobile {
      display: block;
      width: 100%;

      .service_item {
        margin-bottom: 40px;
        padding-bottom: 60px;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 16px;
        width: 100%;
      }
    }

    div {
      width: 100%;
    }

    .serviceImage {
      width: 56px !important;
      height: 56px !important;
    }

    .content {
      padding: 20px 0;

      h4 {
        font-family: 'Lexend';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 44px;

        letter-spacing: -0.03em;

        color: #FFFFFF;
      }

      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #999CB6;
      }
    }

    a {
      color: #887dea;
      text-decoration: none;

      &:hover {
        color: #6359c1;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 209px;
      height: 56px;
      color: #887dea;
      background-color: transparent;
      border: 1px solid #887dea;
      border-radius: 12px;
      transition: ease-in-out 0.25s;

      .icon {
        font-size: 28px;
      }

      &:hover {
        color: #fff;
        background-color: #887dea8f;
      }

      @include mobile {
        width: 100%;
      }
    }
  }

  .banner {
    background-image: url("/assets/images/marketbg_01.png") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 500px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    text-align: left;

    padding: 50px !important;
    margin: 100px auto;

    @include mobile {
      height: auto;
      padding: 25px !important;
      margin: 50px auto;
    }

    .qrcode {
      width: 70px;
      border-radius: 14px;
      padding: 5px;
      background-color: #fff;

      @include mobile {
        display: none;
      }
    }

    .left {
      width: 30%;

      @include mobile {
        width: 100%;
      }

      @include tablet {
        width: 100%;
      }
    }

    .right {
      @include mobile {
        display: none;
      }

      @include tablet {
        display: none;
      }
    }

    .content {
      margin: 50px 0;

      h4 {
        font-weight: 700;
        font-size: 48px;
        line-height: 49px;
        letter-spacing: -0.03em;
        color: #ffffff;

        @include mobile {
          font-size: 32px;
          line-height: 30px;
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        opacity: 0.9;

        @include mobile {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    .buttons {
      display: flex !important;
      gap: 20px;

      @include mobile {
        display: block;
      }

      img {
        transition: ease-in-out 0.2s;

        @include mobile {
          width: 70%;
          margin: auto;
          padding: 10px 0;
        }

        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }
}