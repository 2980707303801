@charset "UTF-8";
/* ======  ALL COLORS =========*/
/*  dark theme colors */
/*
$css-vars-use-native: true; //TODO: Remove that to support old browsers
$green: #4dd4b6;
$my-message: rgb(108, 42, 252);
$wide-screen-profile-gradient: linear-gradient(90deg, rgb(33, 45, 52), rgb(43, 83, 81), rgb(31, 31, 41), rgb(31, 31, 41), rgb(31, 31, 41), rgb(31, 31, 41), rgb(31, 31, 41));
$narrow-screen-profile-gradient: linear-gradient(90deg, #212d34, #212d34, #2b5351, #212d34, #1f1f29);

$header-back: rgb(48, 48, 60);
$page-back: rgb(44, 44, 55);
// light gray for active icons and text
$icon-light: rgb(245, 245, 245);
// darker than icon-light for unactive icons and text
$icon-secondary: rgb(147, 147, 153);
$text-secondary: rgb(116, 116, 124);
$button-light: rgb(226, 226, 226);
//background of the page
$back-color: rgb(26, 26, 36);
$background-dark: #000;
$background-light: rgb(51, 51, 63);

$message-summary-header: rgb(32, 32, 41);
$my-message: rgb(108, 42, 252);
$text-color: white;
$title-dark: rgba(255, 255, 255, 0.4);
@include css-vars((
        --green: $green,
        --red: $red,
        --my-message: $my-message,
        --wide-screen-profile-gradient: $wide-screen-profile-gradient,
        --narrow-screen-profile-gradient: $narrow-screen-profile-gradient,
        --header-back: $header-back,
        --page-back: $page-back,
        --icon-light: $icon-light,
        --icon-secondary: $icon-secondary,
        --text-secondary: $text-secondary,
        --button-light: $button-light,
        --back-color: $back-color,
        --background-dark: $background-dark,
        --background-light: $background-light,
        --message-summary-header: $message-summary-header,
        --text-color: $text-color,
        --title-dark: $title-dark
));
*/
/*  light theme colors */
@import url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.11/semantic.min.css");
@import url("https://rsms.me/inter/inter-ui.css");
@import url("https://fonts.googleapis.com/css?family=Lato|Lora");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans");
:root {
  --green: #368f7c;
  --pink: #C83E93;
  --red: #e53843;
  --my-message: #b7a2e6;
  --wide-screen-profile-gradient: linear-gradient(90deg, #a7c9df, #94c2e0, #d9d9e6, #d9d9e6, #d9d9e6, #d9d9e6, #d9d9e6);
  --narrow-screen-profile-gradient: linear-gradient(90deg, #d9d9e6, #a7c9df, #94c2e0, #a7c9df, #d9d9e6);
  --header-back: whitesmoke;
  --page-back: #e3e3e9;
  --icon-light: #1d1a1a;
  --icon-secondary: #434349;
  --text-secondary: #8f8f96;
  --button-light: #474545;
  --back-color: white;
  --background-dark: white;
  --background-light: whitesmoke;
  --message-summary-header: #efefef;
  --text-color: black;
  --title-dark: rgba(73, 68, 68, 0.4);
  --social-light: whitesmoke;
  --social-dark: white;
  --loader-light: #efefef;
  --primary-bg: #1e1e2a;
  --primary-border: rgba(255, 255, 255, 0.066);
  --formheader: #18182f; }

/* =========================== */
/* ======  RESPONSIVE BREAKPOINTS  =========*/
/* ======  ELEMENTS  =========*/
.p_5 {
  padding: 5px !important; }

.p_10 {
  padding: 10px !important; }

.p_15 {
  padding: 15px !important; }

.p_20 {
  padding: 20px !important; }

.p_25 {
  padding: 25px !important; }

.pt_5 {
  padding-top: 5px !important; }

.pt_10 {
  padding-top: 10px !important; }

.pt_15 {
  padding-top: 15px !important; }

.pt_20 {
  padding-top: 20px !important; }

.pt_25 {
  padding-top: 25px !important; }

.pb_5 {
  padding-bottom: 5px !important; }

.pb_10 {
  padding-bottom: 10px !important; }

.pb_15 {
  padding-bottom: 15px !important; }

.pb_20 {
  padding-bottom: 20px !important; }

.pb_25 {
  padding-bottom: 25px !important; }

.px_5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px_10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.px_15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px_20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.px_25 {
  padding-left: 25px !important;
  padding-right: 25px !important; }

html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; }

body {
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  width: 100%;
  height: auto;
  font-weight: 400;
  background-color: var(--page-back);
  color: var(--text-color);
  font-family: 'Lexend', 'Inter UI', sans-serif; }

h3 {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 53px;
  letter-spacing: -0.03em;
  color: #FFFFFF; }

h4 {
  font-weight: 700;
  font-size: 48px;
  line-height: 49px;
  letter-spacing: -0.03em;
  color: #ffffff; }
  @media (max-width: 900px) {
    h4 {
      font-size: 32px;
      line-height: 30px; } }

p {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px; }

*::-webkit-scrollbar {
  display: none;
  width: 0; }

*::-webkit-scrollbar-button {
  display: none; }

*::-webkit-scrollbar-track {
  background-color: transparent;
  margin-top: 80px !important; }

*::-webkit-scrollbar-track-piece {
  background-color: transparent; }

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 1px solid var(--pink);
  border-radius: 53px; }

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--pink);
  border: 0px solid transparent;
  box-shadow: none; }

#root {
  width: 100%;
  overflow: hidden !important; }

.ui.header {
  color: var(--green); }

.ui.button {
  width: auto;
  height: auto; }

.ui.button {
  width: 100%;
  border: solid 2px #C83E93;
  background: var(--back-color);
  border: 1px solid transparent; }
  .ui.button:hover .icon {
    color: var(--header-back); }
  .ui.button:active {
    transform: scale(0.9); }

.ui.segment.pushable {
  background-color: var(--back-color) !important; }

.trybe_runner {
  margin-top: 3rem;
  border: solid 5px var(--text-color); }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.ui.statistics .statistic {
  margin-right: 0; }

.ui.statistics .value {
  color: var(--green) !important;
  font-family: 'Lexend', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-weight: 100 !important; }

.ui.statistics .label {
  color: var(--green) !important; }

.ui.statistic .label {
  color: var(--title-dark);
  text-align: left; }

.ui.statistic .value {
  color: var(--green);
  text-align: left; }

.mainModal {
  padding: 30px 15px; }
  .mainModal .modalTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    /* identical to box height, or 129% */
    text-align: center;
    letter-spacing: -0.03em;
    color: #FFFFFF; }
  .mainModal p {
    width: 300px;
    margin: auto;
    text-align: center;
    color: rgba(246, 245, 255, 0.6); }
  .mainModal .modalFields {
    margin: 25px 0 !important; }
  .mainModal input {
    padding: 25px !important;
    margin: 10px 0 !important;
    color: #fff !important;
    background: rgba(255, 255, 255, 0.03) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 12px !important;
    transition: ease-in-out 0.3s !important; }
    .mainModal input:focus {
      background: transparent !important;
      border: 1px solid #6959E6 !important;
      box-shadow: 0px 0px 8px rgba(0, 33, 188, 0.5) !important; }
  .mainModal span {
    color: #E36B7D; }
  .mainModal .modalButton {
    margin: 10px 0;
    padding: 17px 28px;
    background: #6959E6;
    color: #fff;
    box-shadow: 2px 2px 6px rgba(33, 57, 143, 0.4);
    border-radius: 12px !important; }
    .mainModal .modalButton:hover {
      background: #6959e6b4;
      color: #fff; }

.app {
  color: var(--text-color);
  min-height: 100%;
  padding: 0;
  margin: 0;
  width: 100%; }
  .app a:hover {
    color: var(--green); }
  .app .tr-container .ui.grid > .column:not(.row) {
    padding: 0 7px; }
  .app .tr-block-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 24px; }
  .app .tr-rounded-box {
    border-radius: 8px;
    background-color: var(--back-box-color); }
    .app .tr-rounded-box__text {
      font-size: 10px;
      line-height: 1.4;
      text-transform: uppercase; }
    .app .tr-rounded-box__icon {
      width: 36px;
      height: 36px;
      border-radius: 13px;
      margin: 0 auto 12px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .app .tr-rounded-box--green {
      background-color: rgba(77, 212, 182, 0.2); }
    .app .tr-rounded-box--blue {
      background-color: rgba(41, 205, 251, 0.2); }
    .app .tr-rounded-box--pink {
      background-color: rgba(238, 51, 116, 0.2); }
    .app .tr-rounded-box--yellow {
      background-color: rgba(204, 238, 63, 0.2); }
    .app .tr-rounded-box--orange {
      background-color: rgba(238, 154, 51, 0.2); }
  .app .tr-expl-wrapper {
    max-width: 984px; }
  .app .tr-expl-item {
    margin-bottom: 24px;
    padding-left: 66px;
    position: relative;
    padding-right: 50px; }
    .app .tr-expl-item a {
      text-transform: uppercase;
      color: var(--green); }
    .app .tr-expl-item__count {
      left: 0;
      position: absolute;
      color: #4dd4b6;
      font-size: 20px;
      display: flex;
      height: 42px;
      width: 42px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: rgba(77, 212, 182, 0.1); }

.app-body {
  height: 100% !important;
  position: static;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  color: var(--text-color); }
  .app-body .ui.container {
    max-width: 100vw !important; }

.downloadPage {
  max-width: 1050px;
  margin: 0 auto 50px;
  padding: 60px 20px 0; }

.downloadPage * {
  color: white !important; }

.downloadPageContent h6 {
  font-size: 46px;
  margin-bottom: 20px; }

.downloadPageContent p {
  opacity: .8;
  font-weight: 300;
  font-size: 18px !important;
  margin: 25px 0; }

.downloadPageContent {
  margin-bottom: 80px;
  margin-top: 80px; }

.downloadPageVideos {
  display: flex;
  justify-content: space-between; }

.downloadPageVideosLeft {
  width: calc(50% - 15px); }

.downloadPageVideosLeft iframe {
  width: 100%;
  border-radius: 10px; }

.downloadPageVideosLeft h6 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px; }

.downloadButtons button {
  padding: 0 !important;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 20px; }
  .downloadButtons button a {
    text-decoration: none; }

.downloadButtons button img {
  margin: 0 10px;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out; }

.downloadButtons button:hover img:last-child {
  transform: rotate(-90deg); }

.downloadButtons {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.downloadBottom {
  padding-top: 100px;
  padding-bottom: 100px; }

.downloadBottom h6 {
  font-size: 46px; }

.downloadBottom p {
  line-height: inherit !important;
  font-size: 18px !important;
  font-weight: 300;
  opacity: .8;
  padding-top: 10px !important; }

.downloadBottom h5 {
  font-size: 24px;
  font-weight: 400;
  padding-top: 40px;
  padding-bottom: 10px; }

.downloadBottom ul {
  list-style: none;
  margin: 0 0 80px;
  padding: 0;
  font-weight: 300;
  opacity: .8;
  line-height: 30px; }

.downloadBottom h4 {
  font-size: 24px;
  font-weight: 400; }

.downloadBottom h4 img {
  max-width: 100%;
  display: block;
  margin-top: 15px;
  margin-bottom: 30px; }

.downloadBottom h4 + p {
  position: relative;
  padding-left: 35px;
  padding-top: 0; }

.downloadBottom h4 + p img {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px; }

.categories .search-input .ui.action.input {
  width: 100%; }

.categories .search-input input {
  background: var(--background-light);
  transition: border .3s ease;
  border-radius: 6px; }
  .categories .search-input input:focus {
    background: var(--page-back);
    border-radius: 6px;
    color: var(--text-color); }

.categories .search-input .icon {
  color: var(--text-secondary); }

.categories .search-input .ui.button {
  width: 100%;
  border: solid 2px #C83E93;
  background: var(--back-color);
  border: 1px solid transparent; }
  .categories .search-input .ui.button:hover .icon {
    color: var(--header-back); }

.categories__mb {
  margin-bottom: 400px !important; }

.categories__mt {
  margin-top: 100px !important; }

.categories img {
  border-radius: 4px; }

.categories h2 {
  color: var(--text-color); }

.categories h3 {
  color: var(--text-color); }

.categories h5 {
  color: var(--title-dark); }

.categories .categories_bg {
  background: var(--background-light) !important;
  margin-top: 40px !important;
  padding: 20px;
  text-align: center; }
  .categories .categories_bg:hover {
    color: var(--text-color);
    background-color: var(--green);
    border: 1px solid var(--green); }
  .categories .categories_bg__active {
    background-color: var(--green) !important; }

.categories .ui.horizontal.segments {
  margin-bottom: 1.9rem; }

.app-header {
  background-color: var(--message-summary-header);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color); }

.ui.inverted.menu {
  background-color: var(--message-summary-header); }

.ui.segment {
  background-color: var(--back-color);
  border-radius: 0 !important; }

.ui.page.modals.dimmer.transition.visible.active {
  background-color: rgba(0, 0, 0, 0.5); }

img.emoji {
  width: 20px; }

@media only screen and (max-width: 1024px) {
  .app .tr-expl-item {
    padding: 0 !important; }
  .hide-tablet {
    display: none !important; }
  .post-container {
    max-width: 850px;
    margin: auto !important; } }

@media only screen and (max-width: 767px) {
  .downloadButtons button {
    margin-right: 0;
    font-size: 15px; }
  .downloadBottom h6,
  .downloadPageContent h6 {
    font-size: 26px; }
  .downloadPageVideos {
    flex-direction: column; }
  .downloadPageVideosLeft {
    width: 100%; }
  .downloadPageVideosLeft:first-child {
    margin-bottom: 50px; } }

@media only screen and (min-width: 1025px) {
  .hide-desctop {
    display: none !important; }
  .post-container {
    max-width: 1500px;
    margin: auto !important; } }

/*
canvas {
  width: 100%; */
.button_outline {
  background: transparent !important;
  color: #887DEA !important;
  border: solid 2px #887DEA !important;
  border-radius: 12px !important; }
  .button_outline:hover {
    background: #887DEA !important;
    color: #fff !important; }

.btn_sm {
  width: fit-content !important; }

.scrollable {
  display: flex;
  /* Set width or min-width based on your requirements */ }

@media (max-width: 900px) {
  .roadmap-section {
    padding: 100px 0; } }

.roadmap-section h3 {
  font-family: 'Lexend';
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 48px;
  line-height: 53px;
  letter-spacing: -0.03em;
  color: #FFFFFF !important; }
  @media (max-width: 900px) {
    .roadmap-section h3 {
      font-size: 28px; } }

.roadmap-section .roadmap {
  width: 100%;
  height: 400px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  color: #fff;
  position: relative;
  cursor: move; }
  @media (max-width: 900px) {
    .roadmap-section .roadmap {
      padding: 0 40px; } }
  .roadmap-section .roadmap .roadmap-item {
    display: flex;
    flex-direction: column;
    width: 350px; }
    .roadmap-section .roadmap .roadmap-item .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;
      padding: 10px 0;
      color: #F6F5FF; }
    .roadmap-section .roadmap .roadmap-item .description {
      width: 270px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(246, 245, 255, 0.6); }
    .roadmap-section .roadmap .roadmap-item .bottom {
      margin-top: 166px;
      padding-top: 30px;
      border-top: solid 4px #28254F;
      z-index: 2;
      position: relative; }
      .roadmap-section .roadmap .roadmap-item .bottom::before {
        content: '';
        position: absolute;
        top: -12px;
        left: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background: #28254F;
        border: 1px solid #494581;
        border-radius: 50px; }
      .roadmap-section .roadmap .roadmap-item .bottom.bottom_active {
        border-top: solid 4px #89F5C2; }
        .roadmap-section .roadmap .roadmap-item .bottom.bottom_active::before {
          content: '';
          position: absolute;
          top: -17px;
          left: 0;
          right: 0;
          width: 30px;
          height: 30px;
          background: #89F5C2;
          border: 1px solid #fff;
          box-shadow: 0px 1px 47px #89F5C2;
          border-radius: 50px; }
    .roadmap-section .roadmap .roadmap-item .top {
      margin-top: 58px;
      padding-bottom: 30px;
      border-bottom: solid 4px #28254F;
      z-index: 2;
      position: relative; }
      .roadmap-section .roadmap .roadmap-item .top::after {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background: #28254F;
        border: 1px solid #494581;
        border-radius: 50px; }
      .roadmap-section .roadmap .roadmap-item .top.top_active {
        border-bottom: solid 4px #89F5C2; }
        .roadmap-section .roadmap .roadmap-item .top.top_active::after {
          content: '';
          position: absolute;
          bottom: -17px;
          left: 0;
          right: 0;
          width: 30px;
          height: 30px;
          background: #89F5C2;
          border: 1px solid #fff;
          box-shadow: 0px 1px 47px #89F5C2;
          border-radius: 50px; }
  .roadmap-section .roadmap .date {
    width: fit-content;
    font-weight: bold;
    margin-bottom: 5px;
    border-radius: 8px;
    padding: 5px 10px;
    color: #999CB6;
    background: #201E4E; }

header {
  height: 110px !important; }
  header .combinedBtn button.btn.loginbtn.headerBtn.headerBtn--login {
    width: auto !important; }
  header .progress-loop {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background: #eeeeee26;
    display: none; }
  header .bell {
    margin: auto !important;
    height: auto !important; }
  header a.navbar-brand:hover {
    opacity: 1; }
  header #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    padding: 8px 11px !important;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 8px; }
    header #mainNav .navbar-nav .nav-item .nav-link span {
      color: #787b9c; }
    header #mainNav .navbar-nav .nav-item .nav-link .active span {
      color: #fff !important; }
    header #mainNav .navbar-nav .nav-item .nav-link:hover {
      background: #1c376b;
      border: 1px solid transparent;
      border-radius: 8px; }
  header #mainNav .active span {
    color: #fff !important; }
  header .navbar_dropdown {
    position: relative; }
    header .navbar_dropdown::after {
      content: '\25bc';
      margin-left: -15px;
      font-size: 10px;
      color: #787B9C; }
  header #mainNav .navbar-nav .navbar_dropdown:hover span {
    color: #887cec; }
  header #mainNav .navbar-nav .nav-item .nav-link:hover span {
    color: #fff !important; }
  header .dropdown ul {
    left: 0;
    position: absolute;
    padding: 10px 20px;
    transition: all 0.5s ease;
    margin-top: 1rem;
    background: #1b1b1b;
    border-radius: 10px;
    font-size: 12px;
    min-width: 100px;
    list-style: none;
    text-align: left; }
    header .dropdown ul .dropdown-item:hover {
      background: transparent !important;
      color: var(--pink); }
  header .combinedBtn .btn.headerBtn {
    font-weight: 600 !important;
    width: 131px;
    height: 44px;
    padding: 5px 5px !important;
    line-height: 22px; }
  header .signup-banner {
    background: var(--green);
    color: var(--back-color);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0; }
    header .signup-banner a,
    header .signup-banner a:hover {
      color: var(--back-color); }
  header .ui.container {
    width: 100%; }
    @media (max-width: 599px) {
      header .ui.container {
        width: 100% !important;
        padding: 0;
        margin-left: 0 !important;
        margin-right: 0 !important; } }
    @media (min-width: 600px) {
      header .ui.container {
        max-width: 1200px !important; } }
  header .ui.menu:after {
    display: none;
    content: none; }
  header .ui.inverted.secondary.menu {
    height: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between; }
    @media (max-width: 992px) {
      header .ui.inverted.secondary.menu {
        justify-content: center; } }
    header .ui.inverted.secondary.menu div.item {
      margin: 0;
      padding: 0; }
    header .ui.inverted.secondary.menu div.item.navigation {
      width: 100%;
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
      margin-right: auto;
      margin-left: auto; }
      header .ui.inverted.secondary.menu div.item.navigation a {
        transition: color 0.3s;
        margin: 0 0.33333rem;
        text-transform: capitalize;
        color: #FFFFFF;
        opacity: 0.8;
        font-weight: 500 !important;
        font-size: 18px; }
        header .ui.inverted.secondary.menu div.item.navigation a:hover {
          color: var(--green); }
    header .ui.inverted.secondary.menu .greenButton {
      padding: 0.66667rem;
      border-radius: 50px; }
      header .ui.inverted.secondary.menu .greenButton i {
        margin-right: 0.5rem; }
        @media (max-width: 599px) {
          header .ui.inverted.secondary.menu .greenButton i {
            margin: 0; } }
      header .ui.inverted.secondary.menu .greenButton:hover {
        color: var(--background-dark) !important;
        background-color: var(--green); }
    header .ui.inverted.secondary.menu .green-bordered-button {
      padding: 0.66667rem;
      border-radius: 50px; }
      header .ui.inverted.secondary.menu .green-bordered-button i {
        margin-right: 0.5rem; }
        @media (max-width: 599px) {
          header .ui.inverted.secondary.menu .green-bordered-button i {
            margin: 0; } }
      header .ui.inverted.secondary.menu .green-bordered-button:hover {
        color: var(--background-dark) !important;
        background-color: var(--green); }
    header .ui.inverted.secondary.menu div.item.profile .ui.toggle.checkbox {
      margin: 0 1rem; }
      header .ui.inverted.secondary.menu div.item.profile .ui.toggle.checkbox .box:before,
      header .ui.inverted.secondary.menu div.item.profile .ui.toggle.checkbox label:before {
        background-color: var(--background-dark);
        border: solid 2px;
        border-color: --text-color; }
      header .ui.inverted.secondary.menu div.item.profile .ui.toggle.checkbox.checked label::before {
        background-color: var(--green) !important; }
      header .ui.inverted.secondary.menu div.item.profile .ui.toggle.checkbox label {
        color: var(--text-color); }
    header .ui.inverted.secondary.menu div.item.profile .ui.toggle.checkbox label:after {
      border: solid 2px;
      border-color: var(--text-color) !important; }
    header .ui.inverted.secondary.menu div.item.profile > div {
      background-color: transparent;
      display: flex; }
      header .ui.inverted.secondary.menu div.item.profile > div .avatar-wrapper {
        border: 1px solid var(--green);
        width: 7vh;
        height: 7vh;
        border-radius: 3.5vh;
        justify-content: center;
        align-items: center;
        display: flex; }
        header .ui.inverted.secondary.menu div.item.profile > div .avatar-wrapper img {
          margin: 1%;
          width: 80%;
          height: 80%; }
      header .ui.inverted.secondary.menu div.item.profile > div .ui.button {
        color: var(--text-color); }
        @media (max-width: 599px) {
          header .ui.inverted.secondary.menu div.item.profile > div .ui.button {
            margin: 0.33333rem; } }
      header .ui.inverted.secondary.menu div.item.profile > div .ui.button i {
        color: var(--text-color);
        position: relative;
        font-size: 1.3rem; }
        header .ui.inverted.secondary.menu div.item.profile > div .ui.button i .ui.floating.label {
          padding: 0.1rem 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          right: -0.5rem;
          bottom: -0.5rem;
          top: auto;
          left: auto; }
    header .ui.inverted.secondary.menu div.item.search {
      flex: 0.8; }
      header .ui.inverted.secondary.menu div.item.search .search-input {
        width: 25vw; }
        header .ui.inverted.secondary.menu div.item.search .search-input .ui.action.input {
          width: 100%; }
        header .ui.inverted.secondary.menu div.item.search .search-input input {
          background: var(--page-back);
          transition: border 0.3s ease;
          border-radius: 6px; }
          header .ui.inverted.secondary.menu div.item.search .search-input input:focus {
            background: var(--page-back);
            border-radius: 6px;
            color: var(--text-color); }
        header .ui.inverted.secondary.menu div.item.search .search-input .icon {
          color: var(--text-secondary); }
        header .ui.inverted.secondary.menu div.item.search .search-input .ui.button {
          border: none;
          background: var(--back-color);
          border: 1px solid transparent; }
          header .ui.inverted.secondary.menu div.item.search .search-input .ui.button:hover .icon {
            color: var(--header-back); }
    header .ui.inverted.secondary.menu div.item.home {
      color: var(--text-color);
      display: flex;
      justify-content: space-between; }
      header .ui.inverted.secondary.menu div.item.home a.logo {
        width: auto; }
        header .ui.inverted.secondary.menu div.item.home a.logo > .ui.image {
          width: 60%; }
    header .ui.inverted.secondary.menu .ui.button,
    header .ui.inverted.secondary.menu .ui.button:hover {
      padding: 0;
      background-color: transparent;
      color: var(--page-back);
      font-size: 16px;
      font-weight: 500;
      line-height: 1.38;
      margin: 0 0 0 24px; }
      header .ui.inverted.secondary.menu .ui.button i,
      header .ui.inverted.secondary.menu .ui.button:hover i {
        margin: 0; }
    header .ui.inverted.secondary.menu .ui.button.login {
      color: #4dd4b6; }
      header .ui.inverted.secondary.menu .ui.button.login:hover {
        color: #2ec09f; }
    header .ui.inverted.secondary.menu .ui.button.register-button {
      color: var(--back-color);
      padding: 10px 30px;
      border-radius: 4px;
      background-color: #4dd4b6; }
      header .ui.inverted.secondary.menu .ui.button.register-button:hover {
        background-color: #2ec09f; }
  header .left_menu_toggle i {
    font-size: 34px !important;
    color: var(--text-color) !important;
    margin: 25px; }

@media only screen and (max-width: 1500px) {
  header .combinedBtn .btn.headerBtn {
    font-size: 16px !important;
    width: 110px;
    height: 40px; } }

@media only screen and (max-width: 1440px) {
  header .combinedBtn .btn.headerBtn:first-child, header .combinedBtn .btn.headerBtn:nth-child(2) {
    width: auto; } }

@media only screen and (max-width: 1340px) {
  #mainNav .navbar-nav .nav-item .nav-link span,
  header #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 14px !important; } }

@media only screen and (max-width: 1280px) {
  header .combinedBtn {
    margin-right: 0; }
  header .ui.container .container a.navbar-brand.fw-bold {
    width: auto !important; } }

@media screen and (max-width: 1024px) {
  .left_menu_toggle i {
    font-size: 34px !important;
    color: var(--text-color) !important; }
  .mobile-toggle i {
    font-size: 34px !important;
    color: var(--text-color) !important;
    z-index: 99999; }
  .app .ui.vertical.custom-mobile-menu.menu {
    height: 100vh !important;
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    padding-right: 2.8rem !important;
    z-index: 9999; }
  .app .ui.vertical.custom-mobile-menu.menu {
    border: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    box-shadow: none;
    padding: 68px 15px 15px 60px;
    background-color: var(--page-back) !important; }
  .app .ui.vertical.custom-mobile-menu.menu .item a.active {
    opacity: 1;
    color: #29cdfb !important; }
  .custom-mobile-menu a {
    color: var(--text-color) !important;
    font-size: 14px !important;
    line-height: 2rem !important;
    font-weight: 800 !important; }
  .ui.secondary.inverted.menu .link.item,
  .ui.secondary.inverted.menu a.item {
    color: var(--text-color) !important; }
  .combinedBtn button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
    padding: 15px 15px !important;
    height: 44px !important; } }

@media only screen and (max-width: 768px) {
  header .signup-banner a {
    font-size: 10px; }
  header {
    padding: 10px; }
    header .ui.container {
      width: 100%; }
    header .ui.inverted.secondary.menu .ui.button,
    header .ui.inverted.secondary.menu .ui.button:hover {
      font-size: 12px; }
    header .ui.inverted.secondary.menu div.item.navigation a {
      font-size: 12px; } }

/*semantic ui style override*/
@media only screen and (max-width: 767px) {
  .ui.container {
    width: 100% !important; } }

header .ui.container .container a.navbar-brand.fw-bold img {
  height: auto !important; }

#mainNav {
  position: sticky;
  top: 100px;
  width: 100%;
  padding: 0;
  backdrop-filter: blur(38px);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
  z-index: 9999999; }
  #mainNav .container {
    max-width: 1440px; }

#mainNav .navbar-brand {
  font-family: 'Lexend', sans-serif; }

#mainNav .navbar-brand:hover, #mainNav .navbar-brand:active {
  color: #2937f0; }

#mainNav .navbar-toggler {
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  color: #fff; }

#mainNav .navbar-nav .nav-item .nav-link {
  text-transform: capitalize;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 18px;
  padding: 10px; }

#mainNav .navbar-nav .nav-item .nav-link.active {
  color: #fff !important;
  background: rgba(29, 56, 106, 0.48);
  border: 1px solid #1c376b;
  border-radius: 8px; }

.cztoggle-menu-mobile {
  display: none;
  cursor: pointer; }

.cztoggle-menu-web {
  display: block; }

.combinedBtn .btn.headerBtn {
  background: linear-gradient(90.24deg, #FA2CAB -1.6%, #9B69FC 99.79%), #FA2CAB;
  border-radius: 5px !important;
  color: #fff !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  height: 35px;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 0; }
  .combinedBtn .btn.headerBtn--login {
    background: none !important;
    color: #fff !important; }
  .combinedBtn .btn.headerBtn--connect {
    cursor: default;
    width: 200px !important;
    border-radius: 10px !important;
    color: #ffffff30 !important;
    font-weight: 600;
    font-size: 13px !important;
    line-height: 19px;
    background: transparent !important;
    border: 2px solid #2b2a37 !important;
    text-transform: uppercase !important; }
    .combinedBtn .btn.headerBtn--connect img {
      filter: grayscale(1) brightness(30%);
      margin: 0 20px; }
    .combinedBtn .btn.headerBtn--connect:disabled {
      color: #ffffff30 !important;
      font-weight: 600;
      font-size: 13px !important;
      line-height: 19px;
      background: transparent !important;
      border: 2px solid #2b2a37 !important; }
      .combinedBtn .btn.headerBtn--connect:disabled img {
        filter: grayscale(1) brightness(30%); }

.combinedBtn header .combinedBtn button.btn.headerBtn {
  width: 90px;
  height: 36px; }

.darkHeader {
  background: linear-gradient(0deg, #120510, #120510) !important; }

.mr-auto {
  margin-right: auto; }

.combinedBtn {
  display: flex;
  gap: 10px; }

.ui.grid.user-online img {
  width: 70px;
  border-radius: 43px;
  padding: 10px; }

@media only screen and (max-width: 1024px) {
  .cztoggle-menu-mobile {
    display: block; }
  .cztoggle-menu-web {
    display: none;
    cursor: pointer; }
  div#navbarResponsive {
    display: none !important; }
  .ui.four.column.grid.categories.cz-categories .row > .column {
    width: 50%;
    margin-bottom: 10px; }
  .categories.cz-categories a .categories_bg__active h3.ui.header {
    padding: 20px 0; }
  .categories.cz-categories > .cat-cz-both .categories.categories__mt .row > .column {
    width: 100% !important;
    margin: 0 !important; }
  .cat-cz-both .categories.categories__mb .row .ui.secondary.segment.categories_bg {
    margin: 0 !important; } }

.sidebar {
  min-width: 300px !important;
  overflow-x: hidden;
  /* .navbar_dropdown:hover .navbar_dropdown_content {
    display: grid !important;
    cursor: pointer !important;
  } */ }
  .sidebar .active {
    width: 100%;
    color: #fff !important;
    background: rgba(105, 89, 230, 0.3);
    border: 1px solid #6959E6;
    border-radius: 8px; }
  .sidebar .nav-link {
    width: 100%;
    position: relative;
    margin: 10px;
    margin-left: 20px !important;
    padding: 8px 11px;
    border: 1px solid transparent;
    border-radius: 8px; }
    .sidebar .nav-link a {
      display: block;
      color: #fff !important;
      text-align: left;
      font-size: 20px;
      transition: 400ms ease-in-out; }
      .sidebar .nav-link a:hover {
        color: #fff !important; }
    .sidebar .nav-link:hover {
      width: 100%;
      color: #fff !important;
      background: rgba(105, 89, 230, 0.09);
      border: 1px solid transparent;
      border-radius: 8px; }
  .sidebar .navbar_dropdown::before {
    content: '\25bc';
    position: absolute;
    margin-left: -50px;
    top: 20px;
    font-size: 10px;
    color: #787B9C; }

.navbar_dropdown_content {
  transition: all 0.5s ease;
  border-radius: 6px;
  font-size: 14px;
  list-style: none;
  text-align: left;
  margin-top: 5px;
  padding: 0 40px; }
  .navbar_dropdown_content a {
    color: black;
    padding: 5px 16px;
    text-decoration: none;
    display: block;
    justify-content: flex-start !important; }
  .navbar_dropdown_content .disabled a span {
    color: #767676 !important; }
  .navbar_dropdown_content li:first-child {
    display: block !important; }

.social-sidebar-section {
  margin-top: 100px !important; }
  .social-sidebar-section .socials {
    margin: auto; }
    .social-sidebar-section .socials ul {
      padding: 0; }
      .social-sidebar-section .socials ul li {
        list-style: none;
        display: inline-block;
        padding: 0 15px; }
        .social-sidebar-section .socials ul li a {
          display: block; }
          .social-sidebar-section .socials ul li a img {
            width: 32px;
            display: inline-block; }

.cz-expanded-items a span {
  position: relative;
  display: flex;
  align-items: center; }

.cz-expanded-items a span img:nth-child(2) {
  position: absolute;
  opacity: 0; }

.cz-expanded-items a span.cz-sidebarActive img {
  opacity: 0; }

.cz-expanded-items a span.cz-sidebarActive img:nth-child(2) {
  opacity: 1; }

a.item.cz-sidebarActive span {
  color: #c83e93; }

.sidebar-wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0; }
  .sidebar-wrapper .ui.segment.pushable {
    width: 100%;
    height: 100%;
    margin: 0;
    border: none;
    overflow: hidden; }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .ui.segment,
    .sidebar-wrapper .ui.segment.pushable > div:first-child .ui.inverted.segment,
    .sidebar-wrapper .ui.segment.pushable > div:first-child .ui.primary.inverted.segment {
      background-color: transparent; }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .segment {
      margin: 0; }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-main .ui.inverted.segment.menu-list-wrapper {
      margin-bottom: 100px;
      color: var(--text-color); }
      .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-main .ui.inverted.segment.menu-list-wrapper .checkbox label {
        color: var(--text-color); }
      .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-main .ui.inverted.segment.menu-list-wrapper .item {
        text-align: left; }
        .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-main .ui.inverted.segment.menu-list-wrapper .item a {
          color: var(--text-color) !important; }
        .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-main .ui.inverted.segment.menu-list-wrapper .item a:hover {
          color: var(--green) !important; }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-main .trybe_runner {
      margin-top: 3rem;
      border: solid 5px var(--text-color); }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-notifications .ui.inverted.segment.section-title .ui.header {
      color: var(--green); }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-chat-summary .ui.inverted.segment.button-back-wrapper {
      padding: 0.5rem;
      display: flex;
      margin: 0;
      justify-content: flex-end;
      align-items: center; }
      .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-chat-summary .ui.inverted.segment.button-back-wrapper .ui.button {
        background-color: transparent;
        color: var(--button-light);
        margin: 0;
        padding: 0.5rem;
        margin-right: 2rem; }
        .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-chat-summary .ui.inverted.segment.button-back-wrapper .ui.button i {
          font-size: 2rem;
          line-height: 2rem;
          margin: 0; }
      .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-chat-summary .ui.inverted.segment.button-back-wrapper .button-chat-fullscreen {
        color: var(--button-light); }
    .sidebar-wrapper .ui.segment.pushable > div:first-child .sidebar-chat-summary .ui.feed.channel-list-wrapper {
      width: 100%; }
    @media only screen and (max-width: 991px) {
      .sidebar-wrapper .ui.segment.pushable .sidebar a,
      .sidebar-wrapper .ui.segment.pushable .sidebar button {
        font-size: 20px !important;
        width: auto; } }
    @media (max-width: 599px) {
      .sidebar-wrapper .ui.segment.pushable .sidebar-main .ui.inverted.segment.menu-list-wrapper {
        overflow: scroll;
        margin-bottom: 100px;
        color: var(--text-color); }
        .sidebar-wrapper .ui.segment.pushable .sidebar-main .ui.inverted.segment.menu-list-wrapper .checkbox label {
          color: var(--text-color); }
        .sidebar-wrapper .ui.segment.pushable .sidebar-main .ui.inverted.segment.menu-list-wrapper .item {
          text-align: left; }
          .sidebar-wrapper .ui.segment.pushable .sidebar-main .ui.inverted.segment.menu-list-wrapper .item a {
            color: var(--text-color) !important; }
          .sidebar-wrapper .ui.segment.pushable .sidebar-main .ui.inverted.segment.menu-list-wrapper .item a:hover {
            color: var(--green) !important; }
      .sidebar-wrapper .ui.segment.pushable .sidebar-main .trybe_runner {
        margin-top: 3rem;
        border: solid 5px var(--text-color); }
      .sidebar-wrapper .ui.segment.pushable ::-webkit-scrollbar {
        width: 0px;
        /* remove scrollbar space */
        background: transparent;
        /* optional: just make scrollbar invisible */ } }
    .sidebar-wrapper .ui.segment.pushable .pusher {
      height: 90vh;
      width: 100%;
      left: 0;
      top: 0;
      position: fixed;
      z-index: 1; }
    .sidebar-wrapper .ui.segment.pushable .ui.icon.input.icon.search-input input {
      background: transparent;
      border: 1px solid var(--text-secondary);
      transition: border .3s ease;
      border-radius: 20px; }
      .sidebar-wrapper .ui.segment.pushable .ui.icon.input.icon.search-input input:focus {
        background: transparent;
        border: 1px solid var(--text-color);
        border-radius: 20px;
        color: var(--text-color); }
    .sidebar-wrapper .ui.segment.pushable .ui.icon.input.icon.search-input .icon {
      color: var(--text-color); }
  .sidebar-wrapper a,
  .sidebar-wrapper a:hover {
    color: inherit; }

.closeBtn {
  font-size: 30px;
  width: 20%;
  float: right;
  border-radius: 10px;
  margin-right: -30px;
  margin-top: -10px;
  /*   &::before {
    color: white;
    width: 100%;
    height: 100%;
    transform: rotate(135deg);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  } */ }

.menuText {
  font-size: 20px;
  float: left; }

.sidebarVisible {
  z-index: 9; }

.sideLogo {
  width: 100px !important;
  margin-top: 10px !important;
  margin-bottom: 30px !important; }

.copyright {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.015em;
  color: #88859B !important;
  text-align: center;
  padding: 50px 0; }

.footer_wrap {
  max-width: 1440px;
  margin: auto;
  padding: 50px 10px;
  border-top: 2px;
  border-bottom: 2px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff1b, rgba(255, 255, 255, 0)) 1; }
  .footer_wrap .emailList {
    width: 100%; }
    .footer_wrap .emailList input {
      background: #161529 !important;
      border-radius: 12px !important;
      padding: 20px 15px; }
  .footer_wrap footer {
    border-top: 1px solid #e5e7ea;
    padding-top: 51px;
    padding-bottom: 21px; }
    .footer_wrap footer h4 {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 600;
      color: #2c2c38;
      margin-bottom: 12px; }
    .footer_wrap footer .ui.list {
      margin-bottom: 36px; }
    .footer_wrap footer .ui.list .list > a.item,
    .footer_wrap footer .ui.list > a.item {
      font-size: 13px;
      color: #4dd4b6 !important;
      line-height: 1.54;
      font-weight: 500; }
    .footer_wrap footer button {
      padding: 10px !important;
      margin: 5px !important; }
      .footer_wrap footer button .svg-inline--fa {
        font-size: 1.7em; }
    .footer_wrap footer .telegram {
      color: white !important;
      background: #0088cc !important; }
    .footer_wrap footer .discord {
      color: white !important;
      background: #7289da !important; }

.social-footer-section .social-footer ul {
  padding: 0; }
  .social-footer-section .social-footer ul li {
    list-style: none;
    display: inline-block;
    margin-right: 10px; }
    .social-footer-section .social-footer ul li a {
      display: inline-flex;
      width: 42px;
      height: 42px;
      background: #171F36;
      border-radius: 40px; }
      .social-footer-section .social-footer ul li a img {
        width: 21px;
        margin: auto;
        opacity: 0.5; }

@media only screen and (max-width: 768px) {
  .footer-wrapper footer .ui.list {
    margin-bottom: 10px; }
  .social-footer-section .social-footer ul {
    padding: 0;
    text-align: center;
    margin-top: 20px; }
    .social-footer-section .social-footer ul li {
      margin: 0 5px; } }

.ui.modal {
  top: -5% !important;
  border-radius: 15px !important;
  background: #0f0f17 !important;
  height: auto; }

.ui.mini.modal {
  width: 500px;
  top: 25% !important;
  left: 45%;
  border: solid 1px #232227; }
  @media (max-width: 900px) {
    .ui.mini.modal {
      top: 5% !important;
      left: 50%;
      width: 95%; } }

.ui.modal > .header {
  background-color: transparent !important;
  color: #89F5C2 !important;
  text-align: center;
  text-transform: uppercase; }

.ui.modal > .content {
  background: #0f0f17 !important;
  color: var(--green);
  padding-top: 25px; }
  .ui.modal > .content .input-label {
    color: #fff !important;
    font-weight: normal; }
  .ui.modal > .content .login-input {
    color: #fff;
    background: #34323a;
    border: 2px solid #34323a; }
  .ui.modal > .content .login-input:focus {
    color: #fff;
    background: #34323a;
    border: 2px solid #59d6e6; }
  .ui.modal > .content .ui.form span {
    color: #59d6e6; }
  .ui.modal > .content .ui.form button.ui.button.forgot-password {
    background: transparent !important;
    border: solid 2px #59d6e6;
    margin-bottom: 1rem;
    float: left; }
  .ui.modal > .content .login_footer {
    margin-top: 25px; }
    .ui.modal > .content .login_footer button {
      color: var(--header-back) !important;
      background: #59d6e6;
      border: solid 2px #59d6e6;
      border-radius: 10px;
      margin-top: 15px;
      transition: ease-in-out 0.3s; }
      .ui.modal > .content .login_footer button:hover {
        background: #59d6e6; }
      .ui.modal > .content .login_footer button:disabled {
        background: #161621 !important;
        border: solid 2px #161621; }
  .ui.modal > .content .forgot-password {
    border: none !important;
    background: transparent !important; }
    .ui.modal > .content .forgot-password:hover {
      background: transparent !important;
      color: #59d6e6 !important; }
    .ui.modal > .content .forgot-password:disabled {
      background: #303030 !important;
      border: solid 2px #59d6e6; }

.action-divider {
  font-size: 16px !important;
  text-align: center;
  text-transform: uppercase; }

.ui.modal > .actions {
  background: #0f0f17 !important;
  display: block;
  width: 100%; }
  .ui.modal > .actions .action-divider {
    color: #fff; }
  .ui.modal > .actions button {
    margin-left: 0em !important; }
  .ui.modal > .actions .signup {
    background: transparent !important;
    border: solid 2px #59d6e6 !important;
    text-transform: uppercase; }
    .ui.modal > .actions .signup:hover {
      background: #59d6e6 !important;
      border: solid 2px #59d6e6; }
  .ui.modal > .actions .action-button {
    color: var(--header-back) !important;
    background: #887dea;
    border: solid 2px #887dea;
    border-radius: 10px;
    margin-top: 15px;
    transition: ease-in-out 0.3s; }
    .ui.modal > .actions .action-button:hover {
      background: #776dd1;
      border: solid 2px #776dd1; }
  .ui.modal > .actions .cancel-button {
    background: transparent;
    color: var(--text-color); }
  .ui.modal > .actions .cancel-button:hover {
    color: var(--green); }
  .ui.modal > .actions .submit-button {
    color: var(--header-back) !important;
    background: #887dea;
    border: solid 2px #887dea;
    border-radius: 10px;
    transition: ease-in-out 0.3s; }
    .ui.modal > .actions .submit-button:hover {
      background: #776dd1;
      border: solid 2px #776dd1; }
  .ui.modal > .actions .forgot-password {
    background: transparent;
    margin-bottom: 1rem;
    float: left;
    color: var(--green);
    margin: auto; }

@keyframes pop-swirl {
  0% {
    transform: scale(0.8); }
  100% {
    transform: scale(1); } }

.mini, .loopsignup {
  -webkit-animation: pop-swirl 1s ease forwards;
  /*trigger the keyframe*/ }

.loginModalHeader {
  display: inline-flex !important;
  width: 100%;
  padding-right: 1.25rem !important; }

.loginModalHeader > p {
  width: 100%;
  height: 32px;
  margin-bottom: 0;
  font-size: 18px;
  padding-top: 3px; }

.loginCloseBtn {
  height: 32px !important;
  width: 36px !important;
  padding: 0 !important; }

.ui.inverted.segment.notifications-list .ui.feed .event .label {
  display: flex;
  align-items: center; }

.ui.inverted.segment.notifications-list .ui.feed .event .content {
  color: var(--text-color); }
  .ui.inverted.segment.notifications-list .ui.feed .event .content .summary {
    color: var(--icon-light);
    font-weight: 400; }
    .ui.inverted.segment.notifications-list .ui.feed .event .content .summary .user {
      color: var(--icon-light); }
    .ui.inverted.segment.notifications-list .ui.feed .event .content .summary .date {
      color: var(--text-secondary);
      font-size: 0.8rem; }

.realtime-notification-wrapper {
  position: fixed;
  bottom: 0;
  left: 0; }
  .realtime-notification-wrapper .notification-wrapper {
    width: 18vw;
    min-width: 300px;
    height: 15vh;
    padding: 0.5rem; }
    .realtime-notification-wrapper .notification-wrapper .ui.feed {
      height: 80%;
      background-color: var(--title-dark);
      display: flex;
      flex-flow: column;
      align-items: flex-end; }
      .realtime-notification-wrapper .notification-wrapper .ui.feed .close {
        background-color: transparent; }
      .realtime-notification-wrapper .notification-wrapper .ui.feed .event {
        padding: 0.25rem;
        font-size: 0.9rem; }

ul li {
  font-size: 1.2em !important; }

html body header {
  background: transparent !important; }

html body header.cz-header-fixed {
  background: #02001f !important; }

.community_Tab.marketPlace .accordion .accordion-itemActive button span {
  background: #02001f !important; }

.community_Tab .accordion .accordion-itemActive button.collapsed::after {
  opacity: 1; }

.community_Tab .accordion .accordion-itemActive button span {
  background: #02001f !important; }

main.homepage {
  background: #000000 !important; }

.home {
  min-height: 430px;
  background-color: #03011e; }
  .home .home_container {
    max-width: 1440px !important;
    margin: auto; }
  .home .heading_h3 {
    width: fit-content;
    font-weight: 800;
    font-size: 66px;
    line-height: 64px;
    letter-spacing: -0.03em;
    color: #fff0fa; }
    @media (max-width: 900px) {
      .home .heading_h3 {
        font-size: 30px !important;
        line-height: 44px; } }
    .home .heading_h3__small {
      margin-bottom: 50px;
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em; }
      .home .heading_h3__small span {
        display: inline !important; }
    .home .heading_h3 span {
      display: block;
      background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .home .header_section {
    background-image: url("../staticAssets/images/home/_291471.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position-x: right;
    height: 900px;
    width: 100%;
    padding: 0 50px;
    margin-top: -140px; }
    @media (max-width: 900px) {
      .home .header_section {
        background-position: center;
        background-size: cover;
        height: 500px;
        padding: 20px; } }
    .home .header_section .heading {
      width: 50%;
      margin-top: 30vh; }
      @media (max-width: 900px) {
        .home .header_section .heading {
          width: 100%;
          margin-top: 20vh; } }
      .home .header_section .heading h3 {
        width: fit-content;
        font-weight: 800;
        font-size: 66px;
        line-height: 64px;
        letter-spacing: -0.03em;
        color: #fff0fa; }
        @media (max-width: 900px) {
          .home .header_section .heading h3 {
            font-size: 36px;
            line-height: 44px; } }
      .home .header_section .heading span {
        display: block;
        background: -webkit-linear-gradient(358deg, #e659d6, #8877ea, #86e2ee);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
      .home .header_section .heading p {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
        opacity: 0.9; }
        @media (max-width: 900px) {
          .home .header_section .heading p {
            font-size: 16px; } }
  .home .section {
    width: 100%; }
    .home .section__one {
      background-image: url("../staticAssets/images/home/background01.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding: 0 0 100px 0; }
      @media (max-width: 900px) {
        .home .section__one {
          padding: 0 0 50px 0; } }
    .home .section__cta {
      background-image: url("../staticAssets/images/home/background04.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding: 100px 0; }
      @media (max-width: 900px) {
        .home .section__cta {
          padding: 50px 0; } }
    @media (max-width: 900px) {
      .home .section {
        padding: 20px; }
        .home .section .break {
          display: none !important; } }
    .home .section .services {
      display: flex;
      justify-content: space-between;
      gap: 80px; }
      @media (max-width: 900px) {
        .home .section .services {
          display: block; }
          .home .section .services .service_item {
            padding-bottom: 60px; } }
      .home .section .services div {
        width: 100%; }
      .home .section .services .serviceImage {
        width: 100% !important; }
      .home .section .services .content {
        padding: 20px 0; }
        .home .section .services .content h4 {
          font-weight: 600;
          font-size: 26px;
          line-height: 32px;
          font-feature-settings: "cv05" on, "cv11" on;
          color: #f6f5ff; }
        .home .section .services .content p {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #999cb6; }
        @media (max-width: 900px) {
          .home .section .services .content {
            text-align: center; } }
      .home .section .services a {
        color: #887dea;
        text-decoration: none; }
        .home .section .services a:hover {
          color: #6359c1; }
      .home .section .services button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 209px;
        height: 56px;
        color: #887dea;
        background-color: transparent;
        border: 1px solid #887dea;
        border-radius: 12px;
        transition: ease-in-out 0.25s; }
        .home .section .services button .icon {
          font-size: 28px; }
        .home .section .services button:hover {
          color: #fff;
          background-color: #887dea8f; }
        @media (max-width: 900px) {
          .home .section .services button {
            width: 100%; } }
    .home .section .banner {
      background-image: url("../staticAssets/images/home/bannerbg.png") !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      height: 500px;
      border-radius: 24px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      padding: 50px !important;
      margin: 100px auto; }
      @media (max-width: 900px) {
        .home .section .banner {
          height: auto;
          padding: 25px !important;
          margin: 50px auto; } }
      .home .section .banner .qrcode {
        width: 70px;
        border-radius: 14px;
        padding: 5px;
        background-color: #fff; }
        @media (max-width: 900px) {
          .home .section .banner .qrcode {
            display: none; } }
      .home .section .banner .left {
        width: 30%; }
        @media (max-width: 900px) {
          .home .section .banner .left {
            width: 100%; } }
        @media (min-width: 901px) and (max-width: 1199px) {
          .home .section .banner .left {
            width: 100%; } }
      .home .section .banner .right img {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1; }
      @media (max-width: 900px) {
        .home .section .banner .right {
          display: none; } }
      @media (min-width: 901px) and (max-width: 1199px) {
        .home .section .banner .right {
          display: none; } }
      .home .section .banner .content {
        margin: 50px 0; }
        .home .section .banner .content h4 {
          font-weight: 700;
          font-size: 48px;
          line-height: 49px;
          letter-spacing: -0.03em;
          color: #ffffff; }
          @media (max-width: 900px) {
            .home .section .banner .content h4 {
              font-size: 32px;
              line-height: 30px; } }
        .home .section .banner .content p {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          opacity: 0.9; }
          @media (max-width: 900px) {
            .home .section .banner .content p {
              font-size: 16px;
              line-height: 18px; } }
      .home .section .banner .buttons {
        display: flex;
        gap: 20px; }
        @media (max-width: 900px) {
          .home .section .banner .buttons {
            display: block; } }
        .home .section .banner .buttons img {
          transition: ease-in-out 0.2s; }
          @media (max-width: 900px) {
            .home .section .banner .buttons img {
              width: 70%;
              margin: auto;
              padding: 10px 0; } }
          .home .section .banner .buttons img:hover {
            transform: scale(0.95); }
  .home .communityInfo {
    padding-bottom: 100px; }
    @media (max-width: 900px) {
      .home .communityInfo .column {
        width: 100% !important;
        padding: 30px 0 !important; } }
    @media (min-width: 901px) and (max-width: 1199px) {
      .home .communityInfo .column {
        width: 100% !important;
        padding: 50px 0 !important; } }
    .home .communityInfo h4 {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 12px;
      text-align: center;
      color: #EAE8F7; }
      @media (max-width: 900px) {
        .home .communityInfo h4 {
          padding: 0;
          font-size: 28px;
          line-height: 24px; } }
    .home .communityInfo p {
      width: 300px;
      margin: auto;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #999CB6; }
  .home .community {
    width: 100%;
    padding-top: 100px;
    padding-top: 100px; }
    .home .community .bg {
      background-image: url("../staticAssets/images/home/background03.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      width: 100%;
      padding: 100px 50px 0 50px; }
      @media (max-width: 900px) {
        .home .community .bg {
          padding: 0; } }
      @media (min-width: 901px) and (max-width: 1199px) {
        .home .community .bg {
          padding: 0; } }
    .home .community .container {
      max-width: 1440px !important;
      margin: auto; }
    .home .community span {
      display: block;
      color: #59d6e6;
      text-align: center;
      font-size: 14px;
      margin-bottom: 20px; }
    .home .community h3 {
      padding: 20px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #fff0fa; }
      @media (max-width: 900px) {
        .home .community h3 {
          padding: 0;
          font-size: 28px;
          line-height: 30px;
          letter-spacing: 0; } }
    .home .community .details {
      font-weight: 600;
      font-size: 28px;
      line-height: 30px; }
    .home .community .featured_nft_image {
      width: 200px; }
    .home .community .swiper-container {
      padding-top: 55px;
      padding-bottom: 55px;
      overflow: hidden; }
    .home .community .swiper-slide {
      border: 12px solid #ea1e7d00 !important;
      border-radius: 392px;
      transform: scale(0.5);
      transition: ease-in-out 0.5s; }
      .home .community .swiper-slide .details,
      .home .community .swiper-slide .nft_button {
        display: none; }
    .home .community .swiper-slide-active {
      width: 522px !important;
      box-shadow: 0 0px 0px 10px #EA1E7D !important;
      border-radius: 392px;
      transform: scale(1);
      transition: ease-in-out 0.5s; }
      @media (max-width: 900px) {
        .home .community .swiper-slide-active {
          width: 322px !important;
          height: 222px !important; } }
      @media (min-width: 901px) and (max-width: 1199px) {
        .home .community .swiper-slide-active {
          width: 422px !important; } }
      .home .community .swiper-slide-active .details,
      .home .community .swiper-slide-active .nft_button {
        display: block; }
    .home .community .swiper-button-next,
    .home .community .swiper-container-rtl .swiper-button-prev {
      right: 400px;
      left: auto; }
    .home .community .swiper-button-prev,
    .home .community .swiper-container-rtl .swiper-button-prev {
      left: 375px;
      right: auto; }
    .home .community .swiper-button-next,
    .home .community .swiper-button-prev {
      color: #fff !important;
      width: 0 !important;
      height: 50px !important; }
      .home .community .swiper-button-next::after,
      .home .community .swiper-button-prev::after {
        padding: 15px 20px;
        font-size: 25px;
        font-weight: 800;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.08);
        border-radius: 40px; }
      @media (max-width: 900px) {
        .home .community .swiper-button-next,
        .home .community .swiper-button-prev {
          display: none; } }
      @media (min-width: 901px) and (max-width: 1199px) {
        .home .community .swiper-button-next,
        .home .community .swiper-button-prev {
          display: none; } }
    .home .community .swiper-wrapper {
      margin-left: -40px !important; }
      @media (max-width: 900px) {
        .home .community .swiper-wrapper {
          margin-left: 5vw !important; } }
      @media (min-width: 901px) and (max-width: 1199px) {
        .home .community .swiper-wrapper {
          margin-left: -5vw !important; } }
    .home .community .swiper-container {
      background: transparent !important;
      border-color: transparent; }
    .home .community .collection {
      width: 522px !important;
      height: 315px;
      background-color: #000 !important;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 392px;
      overflow: hidden; }
      @media (max-width: 900px) {
        .home .community .collection {
          height: 200px !important;
          width: 300px !important; } }
      .home .community .collection__influencer {
        background: linear-gradient(160deg, rgba(0, 0, 0, 0) 46.46%, #a4034e 100%), linear-gradient(334deg, #77148f 61.77%, #3f005f 100%); }
        .home .community .collection__influencer .image {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: transparent !important; }
      .home .community .collection .collection_details {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        padding: 20px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 20%, rgba(14, 14, 24, 0.6), 70%, rgba(23, 23, 40, 0) 100%);
        border-radius: 392px !important; }
        .home .community .collection .collection_details .project_image img {
          max-width: 60px;
          border-radius: 50px; }
        .home .community .collection .collection_details p {
          color: #fff; }
          .home .community .collection .collection_details p span {
            color: rgba(246, 245, 255, 0.6); }
        .home .community .collection .collection_details .nft_button {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #887cec; }
  .home .featured_collections {
    background-image: url("../staticAssets/images/home/background02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    padding: 100px 50px; }
    @media (max-width: 900px) {
      .home .featured_collections {
        padding: 10px; } }
    @media (min-width: 901px) and (max-width: 1199px) {
      .home .featured_collections {
        padding: 20px 0; } }
    .home .featured_collections .container {
      max-width: 1440px !important;
      margin: auto; }
    .home .featured_collections h3 {
      padding: 20px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #fff0fa; }
      @media (max-width: 900px) {
        .home .featured_collections h3 {
          padding: 0;
          font-size: 28px;
          line-height: 24px; } }
    .home .featured_collections .featured_nft_image {
      width: 200px; }
    .home .featured_collections .swiper-container {
      padding-top: 55px;
      padding-bottom: 55px; }
    .home .featured_collections .swiper-pagination-bullet {
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 50%;
      background: rgba(136, 124, 235, 0.15);
      border: 2px solid #887ceb;
      opacity: 0.2; }
    .home .featured_collections .swiper-pagination-bullet-active {
      opacity: 1;
      background: #887ceb;
      border: 1px solid #887ceb; }
    .home .featured_collections .swiper-slide {
      border: 1px solid rgba(255, 255, 255, 0.12) !important;
      border-radius: 24px;
      transform: scale(0.8);
      transition: ease-in-out 0.5s; }
    .home .featured_collections .swiper-slide-active {
      border: 1px solid rgba(255, 255, 255, 0.12) !important;
      transform: scale(1);
      transition: ease-in-out 0.5s; }
    .home .featured_collections .swiper-button-next,
    .home .featured_collections .swiper-button-prev {
      color: #fff !important;
      background-image: none !important;
      width: 0 !important;
      height: 50px !important; }
      .home .featured_collections .swiper-button-next::after,
      .home .featured_collections .swiper-button-prev::after {
        padding: 15px;
        font-size: 25px;
        font-weight: 800;
        border-radius: 40px; }
    .home .featured_collections .swiper-container {
      background: transparent !important;
      border-color: transparent; }
    .home .featured_collections .collection {
      width: 100%;
      height: 619px;
      width: 532px;
      background-color: #000 !important;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 24px; }
      @media (max-width: 900px) {
        .home .featured_collections .collection {
          height: 519px;
          width: 100%; } }
      .home .featured_collections .collection .collection_details {
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        padding: 20px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 20%, rgba(14, 14, 24, 0.6), 70%, rgba(23, 23, 40, 0) 100%);
        border-radius: 24px !important; }
        .home .featured_collections .collection .collection_details .project_image img {
          max-width: 60px;
          border-radius: 50px; }
        .home .featured_collections .collection .collection_details p {
          color: #fff; }
          .home .featured_collections .collection .collection_details p span {
            color: rgba(246, 245, 255, 0.6);
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 17px !important;
            text-align: center !important; }
        .home .featured_collections .collection .collection_details .nft_button {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #887cec; }

.banner {
  position: sticky;
  top: 80px;
  left: auto;
  right: auto;
  z-index: 9999;
  height: 25px;
  width: 100%;
  background: #090817 !important;
  margin: auto;
  padding-top: 1px;
  color: #fff; }

.banner-track {
  position: relative;
  width: 100%;
  height: 40px;
  overflow-x: hidden; }

.banner-text {
  white-space: nowrap;
  will-change: transform;
  padding-top: 1px;
  text-align: center; }
  .banner-text__bold {
    font-weight: bold; }
  .banner-text span {
    text-align: center;
    font-weight: 200;
    margin-left: 25px; }

@keyframes marquee {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-50%); } }

.swiper-full-mobile {
  margin: 0px auto !important; }

.mobileApp .swiper-slide img {
  border-radius: 12px !important; }

.team-slider .swiper-full-mobile .swiper-slide img {
  transform: scale(0.8);
  opacity: 1;
  margin-top: 315px; }

.team-slider .swiper-full-mobile .swiper-slide.swiper-slide-prev img {
  transform: scale(1) !important;
  margin-top: 40px !important; }

.team-slider .swiper-full-mobile .swiper-slide.swiper-slide-next img {
  transform: scale(1) !important;
  margin-top: 40px !important; }

.team-slider .swiper-slide.swiper-slide-active img {
  transform: scale(1);
  margin-top: 0 !important; }

.team-slider .swiper-container.swiper-full-mobile .swiper-button-prev,
.team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
  top: 100px;
  left: 37%;
  width: 38px;
  height: 38px;
  padding: 0;
  background-size: contain;
  border-radius: 100px;
  background-position: center;
  background: linear-gradient(151.28deg, rgba(227, 227, 227, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
  border: 1px solid #423441; }

.team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
  left: auto;
  right: 37%; }

.team-slider .swiper-container.swiper-full-mobile .swiper-button-prev:after,
.team-slider .swiper-container.swiper-full-mobile .swiper-button-next:after {
  color: white;
  font-size: 14px; }

.footerLogo > img {
  width: 115px; }

.tutorialLink {
  text-decoration: none; }

.homepage.dexpage .mainSection .ui.list {
  display: flex;
  justify-content: center; }

.app-body {
  background: #03011e !important; }

.homebg_section.HomeTopBanner .featured_collections {
  padding: 50px;
  background: #202d45;
  background: linear-gradient(50deg, #202d45 0%, #0a0917 35%, #19172c 100%);
  min-height: 430px;
  max-height: 430px; }
  .homebg_section.HomeTopBanner .featured_collections .collection_profile {
    display: inline-flex;
    width: 100%; }
    .homebg_section.HomeTopBanner .featured_collections .collection_profile img {
      width: 46px;
      border: 1px solid #3f4758;
      border-radius: 12px; }
  .homebg_section.HomeTopBanner .featured_collections .collection_details {
    padding-left: 10px; }
  .homebg_section.HomeTopBanner .featured_collections .ui.card,
  .homebg_section.HomeTopBanner .featured_collections .ui.cards > .card {
    width: 400px !important; }
  .homebg_section.HomeTopBanner .featured_collections h3 {
    color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    letter-spacing: 0.003em;
    max-width: 1450px !important;
    margin: auto;
    padding-bottom: 40px; }
    @media only screen and (max-width: 768px) {
      .homebg_section.HomeTopBanner .featured_collections h3 {
        font-size: 20px; } }
  .homebg_section.HomeTopBanner .featured_collections .ui.card.team_member .image {
    max-width: 100%;
    margin: 0;
    border-radius: 10px !important; }
  .homebg_section.HomeTopBanner .featured_collections .swiper-container {
    max-width: 1450px !important;
    background: transparent !important; }
  .homebg_section.HomeTopBanner .featured_collections .team_member .meta {
    display: block;
    padding-top: 70px !important;
    padding-bottom: 15px !important;
    justify-content: center; }
  .homebg_section.HomeTopBanner .featured_collections .collection .meta img {
    width: 36px !important;
    height: 36px;
    border-radius: 20px !important;
    margin: 0 10px !important; }
  .homebg_section.HomeTopBanner .featured_collections .collection .description {
    letter-spacing: 0.005em;
    color: #ffffff !important;
    opacity: 0.8;
    font-size: 18px;
    font-weight: 400;
    max-width: 840px;
    text-align: center;
    margin: 15px auto; }
  .homebg_section.HomeTopBanner .featured_collections .collection .header span {
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    opacity: 0.8; }
  .homebg_section.HomeTopBanner .featured_collections .collection .header {
    display: block !important;
    justify-content: center !important;
    flex-direction: column;
    align-items: center;
    color: #fff !important; }
  .homebg_section.HomeTopBanner .featured_collections .ui.card.collection {
    background: transparent;
    box-shadow: none;
    overflow: hidden; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection .status {
      position: absolute;
      top: 20px;
      right: 2px;
      padding: 2px 5px;
      min-width: 80px;
      text-align: center;
      border-radius: 6px 0 0 6px;
      background-color: var(--pink);
      color: #fff;
      transition: ease-in-out 0.3s; }
      .homebg_section.HomeTopBanner .featured_collections .ui.card.collection .status_cmsoon {
        background-color: #2a2836;
        color: #ffffff70; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection .nft_button {
      display: block;
      position: absolute;
      left: auto;
      bottom: 100px;
      z-index: -1;
      transition: ease-in-out 0.3s; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection .featured_nft_image {
      min-height: 158px;
      max-height: 158px;
      overflow: hidden;
      border-radius: 20px !important;
      transition: ease-in-out 0.2s;
      border: solid 2.5px transparent !important; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection .details {
      color: #fff; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .status {
      right: -200px; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .featured_nft_image {
      min-height: 250px;
      max-height: 250px;
      transition: ease-in-out 0.2s;
      border: solid 2.5px #c83e93 !important; }
      .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .featured_nft_image_cmsoon {
        border: solid 2.5px rgba(238, 238, 238, 0.14902) !important; }
    .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .content {
      transition: ease-in-out 0.2s; }
      .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .content .collection_details {
        text-align: center;
        margin: auto; }
      .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .content .details {
        display: none; }
      .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .content .image img {
        display: none; }
      .homebg_section.HomeTopBanner .featured_collections .ui.card.collection:hover .content .nft_button {
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 10px;
        left: 0; }

.homebg_section.HomeTopBanner .mainSection {
  margin-top: 10px; }

.homeText h1.ui.header.headerContent,
.mainSection .homeText .ui.list {
  text-align: left; }

.inline-photo {
  opacity: 0;
  transform: translateY(10em) rotateZ(0deg);
  transition: transform 4s 0.25s cubic-bezier(0, 1, 0.3, 1), opacity 0.3s 0.25s ease-out;
  will-change: transform, opacity; }

.inline-photo.is-visible {
  opacity: 1;
  transform: rotateZ(-2deg); }

.inline-photo.show-on-scroll.is-visible {
  transform: rotateZ(0deg) !important; }

.homepage h1,
.homepage h2,
.homepage h3,
.homepage h4,
.homepage h5,
.homepage h6 {
  color: #2c2c38; }

.homepage *:hover {
  transition: all 0.2s ease-in-out; }

.homepage p {
  font-size: 16px;
  color: #666666;
  line-height: 1.38; }

.homepage .ui.divided.grid:not([class*="vertically divided"]) > .row > .column {
  box-shadow: none; }

.homepage .ui.card.team_member .header {
  text-align: center; }

.homepage .p40 {
  padding: 0 40px; }

.homepage + .footer-wrapper footer {
  background-color: #fff; }

.footer-wrapper footer {
  display: none; }

.img-responsive {
  max-width: 100%; }

.app-body .tr-primary-button {
  width: auto;
  margin-right: 0;
  line-height: 1.38;
  color: #fff;
  padding: 14px 48px;
  border-radius: 25px;
  box-shadow: 0 6px 16px 0 rgba(77, 212, 182, 0.8);
  background-color: #4dd4b6;
  transition: all 0.3s; }
  .app-body .tr-primary-button img {
    top: -1px;
    margin-right: 8px;
    position: relative;
    display: inline-block; }
  .app-body .tr-primary-button.white {
    color: #4dd4b6;
    background-color: #fff; }
  .app-body .tr-primary-button.play {
    margin-left: 24px;
    padding-left: 36px;
    padding-right: 33px; }
  .app-body .tr-primary-button:hover {
    color: #fff;
    background-color: #2ec09f;
    box-shadow: 0 6px 16px 0 rgba(77, 212, 182, 0.9); }

.app-body .iconsInner {
  background: linear-gradient(180deg, #281e2a 21.03%, #120510 100%);
  border-radius: 20px;
  padding: 15px;
  width: 415px;
  float: right;
  min-height: 546px; }

.app-body .iconProgress ul {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  list-style: none;
  margin-bottom: 0;
  margin: 8px 0px; }

.app-body li.iconTxtWrp,
.app-body li.grphImg {
  display: flex;
  align-items: center; }

.app-body .topIcons h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  opacity: 0.9;
  margin-bottom: 20px;
  margin-top: 15px;
  line-height: 21.31px; }

.app-body .headerTextUp {
  margin-top: -50px; }

.app-body .imgTxt h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: #e9e8ea;
  margin-bottom: 5px; }

.app-body .iconProgress p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.6; }

.app-body .iconTxtWrp .imgTxt {
  padding-left: 15px;
  width: 150px; }

.app-body .rankHigh h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-bottom: 5px; }

.app-body .homepage .rankHigh p {
  font-family: Lexend;
  font-style: normal;
  font-weight: normal;
  font-size: 17.1636px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #0ed18b; }

.app-body ul.activehover {
  background: #4c444a;
  padding: 14px 0px;
  position: relative; }

.app-body ul.activehover::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 30px;
  background: #4c444a;
  top: 0;
  left: -30px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px; }

.app-body ul.activehover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 30px;
  background: #4c444a;
  top: 0;
  right: -30px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px; }

.app-body .tr-section-title {
  font-size: 25px;
  margin-bottom: 36px; }

.app-body .ui.container {
  width: 100%;
  max-width: 1450px !important;
  padding: 0 14px; }

.app-body .ui.grid {
  margin-top: 0;
  margin-bottom: 0; }

.app-body .ui.vertical.tabular.menu {
  border: 0; }
  .app-body .ui.vertical.tabular.menu .item {
    color: #666666;
    cursor: pointer;
    border-radius: 4px !important;
    line-height: 1.4;
    font-size: 20px;
    padding: 20px 24px;
    margin-bottom: 20px;
    border: solid 1px #e5e7ea;
    box-shadow: 0 6px 24px 0 rgba(255, 255, 255, 0.1);
    transition: all 0.3s; }
    .app-body .ui.vertical.tabular.menu .item.active {
      border-width: 0;
      color: #4dd4b6;
      font-weight: bold;
      box-shadow: 0 6px 24px 0 rgba(51, 51, 51, 0.1); }

.app-body .section-hero {
  padding-top: 158px; }
  .app-body .section-hero__title p {
    color: #2c2c38;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.33;
    margin-bottom: 36px;
    letter-spacing: 0.2px; }
    .app-body .section-hero__title p .lined {
      font-weight: 400; }
    .app-body .section-hero__title p .bold {
      font-weight: 800; }
  .app-body .section-hero__description {
    line-height: 1.4;
    font-size: 20px;
    color: #2c2c38;
    margin-bottom: 36px;
    text-transform: uppercase; }

.app-body .section-tabs {
  color: #2c2c38;
  padding: 140px 0; }
  .app-body .section-tabs__image {
    margin-bottom: 24px; }
  .app-body .section-tabs .ui.segment {
    padding: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    background-color: #fff; }

.app-body .section-cta {
  text-align: center;
  padding: 50px 0;
  background-color: rgba(77, 212, 182, 0.08); }
  .app-body .section-cta__title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px; }
  .app-body .section-cta__desc {
    color: #2c2c38;
    max-width: 594px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 36px; }

.app-body .section-posts {
  padding-top: 80px;
  padding-bottom: 36px; }
  .app-body .section-posts__image {
    height: 200px;
    background-size: cover;
    background-position: 50% 50%; }
  .app-body .section-posts__link {
    color: #4dd4b6;
    padding-right: 22px;
    position: relative; }
    .app-body .section-posts__link img {
      right: 0;
      top: 50%;
      position: absolute;
      transform: translate(0, -50%);
      transition: all 0.3s; }
    .app-body .section-posts__link:hover {
      color: #2ec09f !important; }
      .app-body .section-posts__link:hover img {
        transform: translate(3px, -50%); }
  .app-body .section-posts__row-colored {
    background-color: rgba(77, 212, 182, 0.08); }
  .app-body .section-posts .tr-section-title {
    margin-bottom: 26px;
    text-align: center; }
  .app-body .section-posts .card {
    padding-top: 22px;
    padding-bottom: 22px; }
  .app-body .section-posts .ui.card > :first-child,
  .app-body .section-posts .ui.cards > .card > :first-child {
    border-radius: 4px !important; }
  .app-body .section-posts .ui.card > .extra,
  .app-body .section-posts .ui.cards > .card > .extra {
    border: 0 !important; }

.app-body .container .grid .card {
  box-shadow: none;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: transparent; }
  .app-body .container .grid .card img {
    object-fit: cover;
    max-height: 167px; }
  .app-body .container .grid .card .content {
    padding: 0;
    border: 0; }
    .app-body .container .grid .card .content .header {
      color: #2c2c38;
      font-size: 20px;
      color: #666666;
      margin-top: 24px;
      margin-bottom: 12px; }
    .app-body .container .grid .card .content .description {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 24px; }
  .app-body .container .grid .card .content + .extra > a {
    color: #4dd4b6; }
    .app-body .container .grid .card .content + .extra > a:hover {
      color: var(--my-message); }

.app-body .container .grid .section-video {
  padding: 5% 15%; }

.app-body .container .grid .section-presale-title {
  color: var(--green);
  font-size: 1.5em;
  text-align: center; }

.app-body .container .grid .section-presale .column {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .app-body .container .grid .section-presale .column button.ui.button {
    background-color: var(--green);
    color: var(--background-light);
    width: 50%; }

.app-body .container .grid .section-head-title {
  color: var(--green);
  text-align: center; }

.app-body .container .grid .section-head p {
  text-align: center;
  width: 50%;
  margin: auto; }

.app-body .container .grid .section-triangles-title {
  font-size: 2.5em;
  color: var(--green);
  height: 100%;
  display: flex;
  align-items: center; }

.app-body .container .grid .cards-container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 0; }

.app-body .container .grid .section-blockchain {
  padding-left: 1rem;
  padding-right: 1rem; }
  .app-body .container .grid .section-blockchain img {
    width: 100vw; }

.app-body .container .grid .section-partners-title {
  color: var(--green);
  font-size: 1.5em;
  text-align: center; }

.app-body .container .grid .section-partners img {
  margin: 0 auto; }

.ui.grid.cz-new-js .ui.container {
  overflow: hidden; }

.sixteen.wide.computer.mobile.tablet.column.join-community-card.join-community-card-home {
  padding: 0 35px; }

.modal.display-block.modal-team .modal-main {
  width: 380px;
  max-width: 95%;
  border-radius: 10px;
  text-align: center;
  padding: 70px 20px;
  background: #272527; }

.modal.display-block.modal-team .modal-main span {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: rotate(38deg);
  background: #000;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 100%;
  font-size: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer; }

.modal.display-block.modal-team .modal-main > div * {
  text-align: center;
  margin: 0px auto 15px; }

.bg-gradient-loop.team-section .ui.container.cz-support {
  width: 100%;
  max-width: 1170px !important;
  padding: 0 15px; }

.bg-gradient-loop.team-section .innerboxx .content:hover {
  background: #373537;
  cursor: pointer; }

.modal.display-block.modal-team .modal-main button:disabled {
  opacity: 0.6;
  cursor: not-allowed; }

.bg-gradient-loop.marketpalce_wrap.community_Tab.partners.community_dex {
  padding-top: 200px;
  padding-bottom: 170px;
  background-image: url(/marketplace-img.png);
  background-repeat: no-repeat;
  background-size: 40.5%;
  background-position: center right;
  background-color: #120510; }

.bg-gradient-loop.community_Tab.partners.community_dex {
  padding-top: 200px;
  padding-bottom: 170px;
  background-image: url(/dex_img.png);
  background-repeat: no-repeat;
  background-size: 40.5%;
  background-position: center right; }

.bg-gradient-loop.community_Tab.partners.community_dex .ui.container {
  max-width: 80% !important; }

.bg-gradient-loop.community_Tab.partners.community_dex .column.yield {
  width: 55% !important;
  max-width: 55%;
  z-index: 1; }

.bg-gradient-loop.community_Tab.partners.community_dex .column {
  width: 45% !important;
  max-width: 45%;
  height: 100%; }

.bg-gradient-loop.marketpalce_wrap.community_Tab.partners.community_dex {
  padding-top: 95px; }

.bg-gradient-loop.footer_wrap .ui.container {
  width: 100%;
  max-width: 1170px !important;
  padding: 0 15px; }

.bg-gradient-loop.community_Tab.partners.performers_wrap
.innerboxx_image
.markets_widget {
  width: 100%;
  padding-left: 0;
  padding-right: 0; }

body
.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.table {
  padding: 10px 0 !important;
  background: none !important; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.markets_header {
  padding: 0 20px; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 15px 0; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box
.img-box {
  border-radius: 100%;
  width: 50px !important;
  height: 50px;
  margin-right: 16px; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box
img {
  border-radius: 100%;
  width: 100% !important;
  height: auto; }

.community_slider .swiper-slide .learnMore {
  position: absolute;
  bottom: 0;
  right: 20px;
  margin: 0 !important; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child {
  height: 110px; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
td {
  padding: 0 0 !important; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-box,
.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-reading,
.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.graph-box {
  background: #ffffff; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-box::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: -42px;
  background: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-reading::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  right: -42px;
  background: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: -1; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row
td
.icon-reading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  z-index: 1; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row
td
.graph-box {
  display: flex;
  position: relative;
  align-items: center;
  padding: 35px 0;
  width: 50px;
  text-align: center;
  justify-content: center; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
.graph-box {
  width: 100%;
  z-index: 2; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row
td
.icon-reading
p {
  color: #ffffff;
  font-size: 18.39px; }

.community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-reading
p {
  color: #3d444b; }

.community_Tab.partners.performers_wrap
.innerboxx_image
.markets_widget
.ui.table
.portfolio-table-row
td
p
small {
  color: #9b9cab;
  font-weight: 400;
  font-size: 17.16px; }

.community_Tab.partners.performers_wrap
.innerboxx_image
.markets_widget
.ui.table
.portfolio-table-row:first-child
td:first-child
p {
  color: #3d444b;
  font-weight: 600;
  font-size: 18.39px;
  z-index: 1;
  text-align: left; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box
p {
  text-align: left; }

.bg-gradient-loop.community_Tab.partners.community_dex .learnMore {
  margin-top: 45px;
  margin-left: 66px; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
.icon-box,
.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
.icon-reading {
  padding: 19px 0; }

.community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
.icon-reading {
  width: 130px; }

.bg-gradient-loop.extension_wrap .ui.container.cz-support {
  max-width: 100%;
  width: 75%; }

.bg-gradient-loop.extension_wrap .support-section ul li {
  font-size: 18px !important;
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 300;
  line-height: 25px; }

.counterWrap ul li {
  color: #fff;
  position: relative; }

.counterWrap ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding-left: 0; }

.partnerSection .titleWrap h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 129.5%;
  letter-spacing: -0.01em;
  color: #ffffff;
  text-align: left; }

.partnerSection .titleWrap {
  margin-bottom: 0px; }

.partnerSection .ui.segment {
  background: rgba(250, 155, 155, 0);
  border-radius: 2px;
  padding: 0px;
  display: flex;
  height: 55px;
  align-items: center;
  align-content: center;
  border: none; }

.partnerSection .ui.segment img.ui.image {
  margin: auto; }

.partnerSection {
  margin-bottom: 145px; }

div#shiva {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff; }

.counterWrap ul li p {
  font-style: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.07em;
  color: #ffffff;
  opacity: 0.6;
  margin-top: 8px; }

.counterWrap ul li::after {
  content: "";
  position: absolute;
  right: -50%;
  height: 35px;
  width: 1px;
  background: rgba(62, 65, 75, 0.8);
  top: 2px; }

.counterWrap ul li:last-child::after {
  display: none; }

.LoopPositive .marketInner {
  background: transparent; }

.LoopPositive .marketInner h2 {
  max-width: 290px; }

p.bringText {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.07em; }

.homepage .bg-gradient-loop.start-learning {
  display: block; }

.EarnToken .row.join-community button.ui.button.btn.headerBtn {
  width: auto; }

.EarnToken .row.join-community h2 {
  margin: 0px auto !important;
  padding: 0px; }

.blockWrap {
  background: #171016;
  border-radius: 12px;
  height: 100%; }

.row.join-community .blockText p {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.7; }

.blockText h3 {
  color: #fff !important;
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 107.5%;
  letter-spacing: -0.015em; }

.blockText {
  padding: 20px; }

.blockText .d-flex {
  align-items: center; }

.blockText .d-flex h4 {
  color: #ffff;
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-left: 15px; }

.blockWrap.middleBlock {
  background: #221721; }

h2.bottomImg {
  position: relative; }

h2.bottomImg::after {
  position: absolute;
  content: "";
  left: 30px;
  width: 350px;
  height: 5px;
  background-repeat: no-repeat;
  bottom: -5px; }

.tesi-design p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px; }

.grphImg img.ui.image {
  width: 64px; }

@media only screen and (max-width: 768px) {
  .timeline .timelineEndUpcoming {
    top: -20px !important;
    width: 50px !important;
    left: -15px !important; }
  span.ImgBtm.Big::before {
    max-width: 100%; }
  .banner {
    position: sticky;
    top: 60px;
    left: auto;
    right: auto;
    z-index: 9999;
    height: 45px;
    width: 100%;
    background: linear-gradient(90.24deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
    margin: auto;
    padding-top: 9px; }
  .bg-gradient-loop.team-section .team-box .column.innerboxx {
    padding: 0; }
  .app-body .section-hero {
    padding-top: 58px; }
    .app-body .section-hero__title {
      font-size: 32px; }
    .app-body .section-hero .tr-primary-button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px; }
  .app-body .section-tabs {
    padding: 60px 0; }
    .app-body .section-tabs .ui.grid {
      flex-direction: column; }
    .app-body .section-tabs [class*="twelve wide"].column,
    .app-body .section-tabs [class*="four wide"].column {
      width: 100% !important; }
    .app-body .section-tabs .ui.vertical.tabular.menu {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between; }
      .app-body .section-tabs .ui.vertical.tabular.menu:after {
        display: none; }
      .app-body .section-tabs .ui.vertical.tabular.menu .item {
        width: calc(50% - 5px);
        padding: 10px 12px;
        font-size: 12px;
        margin-bottom: 10px; } }

.section-about {
  padding-bottom: 36px; }
  .section-about__image {
    height: 350px;
    background-size: cover;
    background-position: 50% 50%; }
  .section-about__link {
    color: #4dd4b6;
    padding-right: 22px;
    position: relative; }
    .section-about__link img {
      right: 0;
      top: 50%;
      position: absolute;
      transform: translate(0, -50%);
      transition: all 0.3s; }
    .section-about__link:hover {
      color: #2ec09f !important; }
      .section-about__link:hover img {
        transform: translate(3px, -50%); }
  .section-about__row-colored {
    background-color: rgba(77, 212, 182, 0.08); }

.section-about-hero {
  padding-top: 90px !important; }
  .section-about-hero__title p {
    color: #2c2c38;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.33;
    margin-bottom: 36px;
    letter-spacing: 0.2px; }
    .section-about-hero__title p .lined {
      font-weight: 400; }
    .section-about-hero__title p .bold {
      font-weight: 200; }

.homebg_section {
  padding-bottom: 0px;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat; }

.homebg_section .mainSection {
  /*	background-image: url(/img/bannerImg.jpg); */
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 690px;
  display: flex;
  align-items: center; }

.mainSection .ui.grid.hero_section {
  position: relative;
  z-index: 99;
  padding-top: 50px;
  padding-bottom: 150px; }

span.subHeading {
  text-transform: capitalize !important; }

h1.ui.header.headerContent {
  font-family: Lexend;
  font-style: normal;
  font-weight: bold;
  font-size: 62px;
  line-height: 109%;
  text-shadow: 2px 0px 0px #fff;
  color: #ffffff;
  width: 100%;
  margin: auto;
  max-width: 800px; }
  h1.ui.header.headerContent span {
    color: #ffffff !important; }

h1.ui.header.headerContent .logo_text {
  margin: 0;
  width: 100%; }

h1.ui.header.headerContent span {
  display: block;
  position: relative;
  margin: 0 auto 0px;
  text-transform: uppercase; }

.ui.container.feed.cz-con-new.pre_loader_grid {
  align-items: center; }

.row.pre_loader_wrap .column.pre_loader_box {
  width: 33.33%;
  max-width: 33.33%; }

.row.pre_loader_wrap .column.pre_loader_box .ui.segment {
  background: rgba(255, 255, 255, 0.0784314) !important; }

.ui.container.feed.cz-con-new.pre_loader_grid .ui.grid {
  height: 100%; }

.ui.container.feed.cz-con-new.pre_loader_grid .ui.segment {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.mainSection .ui.image.line {
  display: none; }

.cz-home-set-icons .cz-icons {
  margin-left: 22px !important; }

.cz-home-set-icons .cz-icons .socialLink {
  border-radius: 100%;
  background-image: url(/img/social-bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  transition: 0.5s;
  margin-right: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.cz-home-set-icons .cz-icons .socialLink:last-child {
  margin: 0; }

.cz-home-set-icons .cz-icons .socialLink:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  transition: 0.5s; }

.cz-home-set-icons .cz-icons .socialLink img.ui.image {
  width: 25px;
  margin: 0;
  padding: 0;
  text-align: center; }

.community_wrap {
  position: relative;
  /* background-image: url(/img/left-bg.png), url(/img/right-bg.png) !important; */
  background-repeat: no-repeat !important;
  background-position: left, right !important;
  background-size: cover !important;
  background: linear-gradient(0deg, #120510, #120510) !important; }

.community_slider {
  z-index: 1;
  -webkit-backdrop-filter: blur(73.58px) !important;
  backdrop-filter: blur(73.58px) !important;
  padding: 40px 30px 40px !important;
  position: relative;
  background: url(/img/community_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.community_slider .swiper-container {
  background: transparent !important;
  max-width: 85%;
  overflow: visible; }

.community_slider .swiper-container .swiper-slide.swiper-slide-next,
.community_slider .swiper-container .swiper-slide.swiper-slide-active,
.community_slider .swiper-container .swiper-slide,
.community_slider .swiper-container .swiper-slide.swiper-slide-duplicate-prev {
  opacity: 1; }

.community_slider .swiper-button-prev,
.community_slider .swiper-button-next {
  color: #fff;
  background: transparent; }

.community_slider .swiper-button-prev {
  left: -100px; }

.community_slider .swiper-button-next {
  right: -100px; }

.community_slider .swiper-button-prev::after,
.community_slider .swiper-button-next::after {
  font-size: 20px; }

.community_slider .swiper-container .swiper-slide .img-box {
  margin-right: 30px; }

.community_slider .swiper-slide .text-box {
  padding-right: 10px; }

.community_slider .swiper-slide h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #ffffff !important;
  opacity: 0.9;
  margin-top: 0px;
  margin-bottom: 18px; }

.community_slider .swiper-slide p {
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 25px;
  opacity: 0.8; }

.community_slider .swiper-container .swiper-slide {
  position: relative;
  display: flex;
  align-items: normal; }

.community_slider .swiper-container .swiper-slide.swiper-slide-next::before {
  position: absolute;
  content: "";
  height: 100px;
  width: 1px;
  top: 0;
  left: -45px;
  opacity: 20%;
  background: #ffffff; }

.community_slider .swiper-container .swiper-slide.swiper-slide-next::after {
  position: absolute;
  content: "";
  height: 100px;
  width: 1px;
  top: 0;
  right: -20px;
  opacity: 20%;
  background: #ffffff; }

.bg-gradient-loop.loop_screen_home {
  display: none; }

.mainSection .ui.list {
  margin-bottom: 30px;
  margin-top: 30px; }

.mainSection .ui.list .item {
  display: inline-block;
  position: relative;
  margin-right: 30px;
  padding: 0; }

.mainSection .ui.list .item:last-child {
  margin: 0; }

.mainSection .ui.list .item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 3px;
  background: #fff;
  right: -16px;
  top: 0;
  opacity: 0.6; }

.mainSection .ui.list .item:last-child::before {
  display: none; }

.mainSection .ui.list .item a {
  text-decoration: none;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff; }

.mainSection .ui.list .item a:hover {
  opacity: 0.6; }

img.ui.image.line {
  margin: 0 auto; }

p.subHeading {
  color: #ffffff;
  font-weight: 400;
  font-size: 22px;
  opacity: 0.8;
  margin-top: 20px; }

button.ui.button.btn.headerBtn {
  background: linear-gradient(90.24deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 0;
  position: relative;
  z-index: 9;
  transition: 0.5s; }

button.ui.button.btn.headerBtn:hover {
  transform: translateY(-5px); }

header .combinedBtn .btn.headerBtn {
  position: relative; }

a.learnMore {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin-left: 32px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  cursor: pointer; }

a.learnMore:hover {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.learnMore img {
  margin-left: 9px; }

.ui.grid.loopCard.engagementCard .ui.grid {
  padding: 0 !important; }

.ui.grid.loopCard.engagementCard .ui.grid p {
  padding-left: 0;
  padding-right: 0; }

.ui.grid.loopCard.engagementCard .ui.grid.enga > .ui.grid {
  padding: 8px 0 !important; }

.main_action_btn {
  /*	display: flex; */
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
  position: relative;
  z-index: 100; }

.mainSection.cz-exchange .headerBtnBox.position-relative {
  margin-top: 20px; }

img.ui.image.homebg {
  position: absolute;
  display: none;
  opacity: 0;
  top: 0;
  left: 0; }

img.ui.image.screen_one {
  margin: 0 auto; }

.our_partner img.ui.image.img-fluid {
  max-width: 100%;
  width: 100%; }

.innerboxx img.ui.image.img-fluid {
  margin: 0 auto; }

.innerBox h6.ui.header {
  color: #ffffff;
  opacity: 0.9;
  font-weight: 600;
  font-size: 32px;
  margin: 0; }

.innerBox p {
  letter-spacing: 0.07em;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.6;
  margin-left: 16px;
  font-weight: 400; }

.innerBox {
  display: flex;
  align-items: center; }

.hero_features {
  width: 100%;
  margin: 60px auto 0 !important;
  display: flex;
  justify-content: space-between; }

.hero_features span {
  width: 1px;
  height: 37px;
  background: rgba(62, 65, 75, 0.8);
  font-size: 0;
  padding: 0 !important; }

.bg-gradient-loop {
  background: linear-gradient(0deg, #120510, #120510);
  padding-top: 5rem;
  padding-bottom: 5rem; }

.ui.grid.our_partner {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.community_Tab h6.ui.header.textGradient.h6 {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  float: left; }

.community_Tab h2.ui.header {
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: 20px !important;
  margin-top: 16px;
  clear: both; }

.row.join-community h2 b {
  position: relative; }

.row.join-community h2 b img {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0; }

.join-community-card .swiper-slide a .image img {
  height: 244px !important;
  border-radius: 10px; }

.community_Tab p {
  letter-spacing: 0.005em;
  color: #ffffff !important;
  font-size: 18.39px;
  font-weight: 400;
  line-height: 1.5; }

img.img-fluid {
  max-width: 100%;
  height: auto; }

.row.our_partner {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important; }

.appDownload h2 {
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 46px; }

.appDownload p {
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 25px; }

.download_btns {
  margin-top: 40px;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important; }

.download_btns a:first-child {
  margin-right: 20px; }

.appDownload_box {
  max-width: 545px !important;
  margin: 0 auto; }

.textGradient {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

h2.ui.header.textGradient.farming {
  font-size: 32px;
  margin-bottom: 8px !important;
  display: block; }

.community_Tab .accordion button {
  background: transparent;
  border: 0;
  color: #fff;
  letter-spacing: -0.015em;
  font-size: 28px;
  padding: 0;
  line-height: 0;
  outline: 0;
  display: flex;
  align-items: center;
  position: relative; }

.community_Tab .accordion button span {
  background: #252525;
  width: 36px;
  height: 36px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px; }

.bg-gradient-loop.cz-mobi-screens {
  padding-bottom: 20px; }

.cz-all-screens.swiper-full-mobile .swiper-slide img {
  border-radius: 10px; }

.community_Tab .accordion-body,
.community_Tab .accordion-body {
  padding: 9px 10px 9px 50px;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400; }

.community_Tab .accordion .accordion-item {
  margin-bottom: 0px;
  border: 0;
  padding: 20px; }

.bg-gradient-loop.integrationTab.community_Tab {
  display: none; }

.row.support-section {
  padding-top: 0px !important; }

.row.support-section .innerboxx {
  display: flex !important;
  align-items: center !important; }

#accordionLoop,
#accordionLoop2 {
  margin-top: 46px;
  padding-left: 46px; }

div#accordionLoop1 {
  margin-top: 46px;
  padding-left: 46px; }

.accordion .accordianLine {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 99999;
  height: 100%; }

.community_Tab .accordion button::after {
  content: url("/img/mouse.svg");
  position: absolute;
  left: -46px;
  top: 7px;
  opacity: 1;
  transition: 0.6s;
  transform: translate(-50%, 0%); }

.community_Tab .accordion button.collapsed::after {
  opacity: 0; }

.ui.grid.financial_freedom {
  background: #1e1a1d;
  border-radius: 20px;
  padding: 110px 75px 50px;
  overflow: hidden; }

img.integrationImg {
  max-width: initial !important; }

.financial_freedom h2.ui.header.integrationTabH2,
.row.support-section h2,
.row.exchange-section h2,
.loop-text h2,
.row.newsletter-section h2 {
  letter-spacing: -0.015em !important;
  color: #ffffff !important;
  margin-top: 16px !important;
  margin-bottom: 20px !important;
  font-size: 46px !important;
  font-weight: 600;
  line-height: 50px !important; }

.financial_freedom .appDownload_box {
  max-width: 650px !important;
  margin: 0 auto; }

.row.join-community h2 {
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: 20px !important;
  margin-top: 16px !important;
  display: block;
  max-width: 600px;
  text-align: center;
  margin: 0 auto; }

.row.join-community p {
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 0.8;
  font-size: 17px;
  font-weight: 400;
  max-width: 840px;
  text-align: center;
  margin: 15px auto; }

.row.join-community button.ui.button.btn.headerBtn {
  width: 240px;
  display: block;
  margin: 32px auto; }

.join-community-card .card,
.start-learning-card .card {
  background: #171016 !important;
  border-radius: 12px;
  overflow: hidden; }

.join-community-card .card:hover,
.start-learning-card .card:hover {
  background: #221721 !important;
  -webkit-transform: unset;
  transform: unset; }

.start-learning-card .swiper-slide .image img {
  height: 200px !important; }

.row.rewards-section.cz-rewards
.stretched.twelve.wide.column
.ui.bottom.attached.segment.active.tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: none; }

.row.rewards-section.cz-rewards
.stretched.twelve.wide.column
.ui.bottom.attached.segment.active.tab
span {
  width: 70%;
  margin: 10px auto;
  font-size: 21px;
  color: white;
  line-height: 25px;
  font-weight: 300;
  opacity: 0.8; }

.row.rewards-section.cz-rewards .four.wide.column a.item span {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 35px;
  margin-right: 10px;
  border-radius: 100px;
  background: #252525;
  color: white; }

.row.rewards-section.cz-rewards .four.wide.column a.item:hover span,
.row.rewards-section.cz-rewards .four.wide.column a.item.active span {
  background: linear-gradient(90.24deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab; }

.row.rewards-section.cz-rewards .four.wide.column a.item {
  border: none;
  box-shadow: none;
  font-size: 24px;
  color: white;
  background-color: transparent; }

.row.rewards-section.cz-rewards .four.wide.column a.item:hover,
.row.rewards-section.cz-rewards .four.wide.column a.item.active {
  color: #fff;
  background-color: #ffffff0d;
  border-radius: 100px !important;
  font-weight: normal; }

.join-community-card .card .content,
.start-learning-card .card .content {
  padding: 24px !important; }

.row.rewards-section.cz-rewards .ui.grid .four.wide.column {
  width: 35% !important; }

.row.rewards-section.cz-rewards .ui.grid .stretched.twelve.wide.column {
  width: 65% !important; }

.join-community-card .card .content .header,
.start-learning-card .card .content .header {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26px !important;
  letter-spacing: -0.015em !important;
  text-align: left !important;
  color: #fff !important;
  margin: 0 0 10px 0 !important; }

.join-community-card .card .content .description,
.start-learning-card .card .content .description {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;
  text-align: left !important;
  color: #fff !important;
  margin: 0 0 16px 0 !important; }

.join-community-card .card .content img {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  margin-right: 8px; }

.join-community-card .meta span {
  font-family: Lexend;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff; }

.join-community-card {
  display: flex !important;
  justify-content: space-between;
  margin-top: 45px !important; }

.start-learning-card {
  display: flex !important;
  justify-content: space-between; }

.start-learning-card .card {
  width: 100% !important; }

.start-learning {
  margin-top: -10rem; }

.bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container {
  padding: 0 50px; }

.start-learning-card .swiper-button-prev:after,
.start-learning-card .swiper-button-next:after {
  font-size: 14px; }

.bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide {
  opacity: 1; }

.bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide.swiper-slide-duplicate-prev {
  opacity: 0; }

.bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide.swiper-slide-prev {
  opacity: 0; }

.bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide.swiper-slide-next {
  opacity: 1; }

.bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide.swiper-slide-active {
  opacity: 1; }

.start-learning-card .swiper-button-prev,
.start-learning-card .swiper-button-next {
  width: 38px;
  padding: 0px;
  color: #fff;
  height: 38px;
  left: 0 !important;
  background-size: contain;
  border-radius: 100px;
  background-position: center;
  background: linear-gradient(151.28deg, rgba(227, 227, 227, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
  border: 1px solid #423441; }

.start-learning-card .swiper-button-next {
  left: auto !important;
  right: 0 !important; }

.bg-gradient-loop.community_Tab.partners.community_partners .ui.container {
  max-width: 95% !important; }

.bg-gradient-loop.community_Tab.partners.community_partners
.ui.container
.innerboxx {
  width: 40% !important;
  z-index: 1;
  padding-right: 85px; }

.bg-gradient-loop.community_Tab.partners.community_partners .innerboxx_image {
  width: 60% !important; }

.bg-gradient-loop.community_Tab.partners.performers_wrap .ui.container {
  max-width: 75% !important; }

.bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx_image {
  width: 50% !important;
  max-width: 50%; }

.bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
  width: 50% !important;
  max-width: 50%;
  padding-left: 135px;
  margin-left: auto; }

.community_Tab.partners.performers_wrap .ui.container .markets_widget {
  background: linear-gradient(180deg, #281e2a 21.03%, #120510 100%) !important;
  padding: 20px 0px;
  width: 100%;
  margin: auto;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px; }
  .community_Tab.partners.performers_wrap .ui.container .markets_widget .markets_header {
    font-size: 24px !important; }
  .community_Tab.partners.performers_wrap .ui.container .markets_widget .table {
    padding: 35px 0 !important;
    background: none !important; }

.row.support-section p,
.row.exchange-section p,
.loop-text p,
.row.newsletter-section p {
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 0.8;
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 20px; }

.bg-gradient-loop .ui.container.cz-support {
  max-width: 1440px !important; }

.row.exchange-section h2.ui.header b {
  display: inline-block;
  position: relative; }

.row.exchange-section h2.ui.header b img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -4px; }

.mainSection.cz-exchange.exchange_banner_area {
  background: linear-gradient(0deg, #120510, #120510);
  padding-top: 9.5rem;
  padding-bottom: 1rem; }

.mainSection.cz-exchange.exchange_banner_area .headerContent {
  text-align: center;
  letter-spacing: -0.02em;
  color: #fff;
  font-weight: 600;
  font-size: 56px;
  display: block;
  max-width: 920px;
  margin: 0 auto 10px;
  position: relative;
  z-index: 1;
  line-height: 1.28571429em; }

.mainSection.cz-exchange.exchange_banner_area .ui.image.line {
  display: block; }

.row.support-section a.learnMore {
  margin-left: 0;
  margin-top: 30px; }

.support-img {
  padding-right: 80px !important; }

.support-img .ui.image.img-fluid {
  width: 100%; }

.bg-gradient-loop.feedback_wrap .loop-img {
  padding-left: 0px !important; }

.bg-gradient-loop.feedback_wrap .ui.image.img-fluid {
  width: 100%; }

.bg-gradient-loop.feedback_wrap .loop-text {
  width: 100%;
  max-width: 580px;
  padding-left: 60px; }

.bg-gradient-loop.decentralized_wrap .ui.container.cz-support {
  max-width: 1318px !important; }

.row.exchange-section {
  background: #1e1a1d;
  border-radius: 20px;
  padding: 70px 60px 80px !important;
  overflow: hidden; }

.row.exchange-section {
  align-items: center !important; }

.exchange-img {
  display: flex !important;
  justify-content: flex-end; }

.exchange-img img {
  max-width: 100% !important; }

.loop-text {
  width: 100%;
  max-width: 580px;
  padding-left: 100px; }

.loop-text p b {
  font-weight: 600; }

.row.loop-section {
  align-items: center !important; }

.row.exchange-section .headerBtnBox {
  margin-top: 30px; }

.start-learning .innerboxx a.learnMore {
  width: 160px;
  display: block;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center; }

.start-learning-card a.learnMore {
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  margin-left: 0 !important; }

.start-learning-card a.learnMore:hover {
  color: #a145b4; }

.start-learning-card .card .content .header {
  font-size: 23px !important; }

.reward-bg {
  padding: 120px 70px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important; }

.rewards-section a.item {
  font-size: 24px;
  text-decoration: none;
  height: 72px;
  padding: 23px 25px !important;
  margin-bottom: 28px;
  max-width: 380px;
  border-radius: 40px;
  display: flex !important;
  align-items: center;
  transition: all ease-in 0.3s; }

.rewards-section a.item:hover,
.rewards-section a.item:active {
  background: #221620; }

.rewards-text {
  position: relative; }

.image.rewa-bg img {
  max-width: 100%;
  margin: 0 auto;
  display: block; }

.rewards-section a.item b {
  width: 32px;
  height: 32px;
  background: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 18px;
  margin-right: 10px; }

.bg-gradient-loop.cz-rewards-hub .ui.container {
  max-width: 1440px !important; }

.image.rewa-bg {
  position: absolute !important;
  top: -40px;
  right: 0;
  width: 68%;
  z-index: 0 !important; }

.row.rewards-section.cz-rewards > div {
  position: relative;
  z-index: 1; }

.row.newsletter-section {
  background: linear-gradient(90.24deg, #fa2cab 2.4%, #9b69fc 99.79%), #fa2cab;
  border-radius: 20px;
  padding: 5px 10px 5px 50px !important;
  align-items: center !important;
  overflow: hidden;
  margin-top: 0px !important;
  justify-content: space-between !important; }

.newsletter-img {
  display: flex !important;
  justify-content: flex-end;
  top: -25px; }

.newsletter-img img {
  max-width: 100%; }

.row.newsletter-section form.ui.form {
  display: flex;
  align-items: center;
  margin-top: 30px !important;
  justify-content: space-between; }

.row.newsletter-section .field {
  margin: 0 10px 0 0 !important;
  width: 100%; }

.row.newsletter-section .ui.form .field input {
  background: #ffffff29;
  border: 1px solid #fff;
  height: 100%;
  color: #fff !important;
  font-size: 20px;
  color: white;
  border-radius: 10px; }

.row.newsletter-section button.ui.button {
  background: #fff;
  color: #f72dad;
  border-radius: 10px;
  font-size: 20px;
  width: 139px;
  height: 55px;
  padding: 0 21px;
  margin: 0; }

.row.newsletter-section .seven.wide.computer.newsletter-form {
  width: 514px !important;
  padding: 55px 0 75px; }

.row.newsletter-section .nine.wide.computer.newsletter-img {
  width: calc(100% - 620px) !important; }

.bg-gradient-loop.footer_wrap .footer-section .about-company {
  padding-right: 80px; }

.about-company p {
  margin: 20px 0;
  color: #fff;
  font-weight: 300;
  line-height: 20px;
  opacity: 0.8;
  font-size: 14px; }

.about-company small {
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  opacity: 0.8; }

.bg-gradient-loop.footer_wrap .footer-section .link-design .item {
  font-weight: 300;
  opacity: 0.8;
  margin-bottom: 8px; }

.bg-gradient-loop.footer_wrap .footer-section .link-design .item:hover {
  opacity: 1; }

.link-design h2,
.address-design h2 {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #fff !important;
  margin-bottom: 24px !important; }

.link-design a.item {
  font-size: 14px;
  text-decoration: none;
  color: #88859b !important;
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px; }

.address-design a {
  text-decoration: none;
  font-weight: 300;
  opacity: 0.8; }

.address-design a:hover {
  opacity: 1; }

.address-design p {
  font-size: 14px;
  color: #fff !important;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 5px;
  display: flex; }

.address-design p span {
  margin-right: 10px; }

.tesi-design {
  background: #221721;
  padding: 60px 24px 60px;
  margin: 0 5px;
  border-radius: 12px;
  color: #fff;
  position: relative; }

.tesi-design p {
  color: #fff; }

.tesi-design i {
  width: 10px !important;
  height: 10px !important;
  display: block;
  background: #ffffff80;
  border-radius: 10px;
  font-size: 0; }

i.active {
  background: #fff; }

.pagination {
  display: flex;
  gap: 5px;
  margin-top: 30px; }

.image.twitter-icon {
  position: absolute;
  right: 26px;
  bottom: 18px; }

.image.q-icon {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 20px; }

.image.q-icon1 {
  margin-left: 25px; }

.tesi-design span {
  position: relative; }

.newsletter-img .swiper-pagination {
  text-align: left !important;
  left: 24px !important;
  bottom: 18px !important; }

.newsletter-img .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.2; }

.newsletter-img .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff; }

a.item:hover b {
  background: linear-gradient(90.24deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab; }

.team_member .meta {
  display: flex;
  padding-top: 70px !important;
  padding-bottom: 15px !important;
  justify-content: center; }

.team_member .meta img {
  width: 36px !important;
  height: 36px;
  border-radius: 20px !important;
  margin: 0 10px !important; }

.cz-icons.cz-onlydesktop {
  opacity: 0.8;
  z-index: 9999; }

.team_member .description {
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 400;
  max-width: 840px;
  text-align: center;
  margin: 15px auto; }

.row.appDownload .appDownload_box {
  padding-bottom: 60px; }

.team_member .header small {
  display: flex;
  flex-direction: column !important;
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  opacity: 0.8; }

.meta.social-menu-image {
  padding: 0 30px; }

.team_member .header {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  align-items: center;
  color: #fff !important; }

.ui.card.team_member {
  background: transparent;
  box-shadow: none; }

.ui.card.team_member .image {
  max-width: 220px;
  margin: auto;
  border-radius: 200px !important; }

.memberImg img {
  border-radius: 125px; }

.team-slider .swiper-slide.swiper-slide-active {
  transform: scale(1); }

.team-slider .swiper-slide.swiper-slide.swiper-slide-prev {
  transform-origin: left; }

.swiper-full-mobile .swiper-slide.swiper-slide-next {
  transform-origin: right; }

.swiper-full-mobile .swiper-slide.swiper-slide-next ~ .swiper-slide {
  transform-origin: right; }

.swiper-full-mobile .swiper-slide {
  transform-origin: left; }

.team-slider .swiper-slide {
  transform: scale(0.6); }

.team-slider .swiper-slide .content {
  display: none; }

.team-slider .swiper-slide.swiper-slide-active .content {
  display: block; }

.row.loop-section {
  margin: 0 0px 0 -14px; }

.row.join-community.start-learning,
.row.newsletter-section,
.row.footer-section,
.row.exchange-section,
.row.support-section,
.row.appDownload,
.row.our_partner,
.row.join-community {
  margin: 0; }

.bg-gradient-loop.cz-rewards-hub {
  display: none; }

.bg-gradient-loop.testimonial_slider {
  padding-bottom: 30px;
  padding-top: 30px; }

.bg-gradient-loop.team-section {
  background: #211c20;
  padding: 55px 15px 40px; }

.bg-gradient-loop.footer_wrap {
  padding-top: 95px; }

.bg-gradient-loop.team-section .ui.header.textGradient.h6 {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  float: left; }

.bg-gradient-loop.team-section h2.ui.header {
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #ffffff;
  margin-bottom: 20px !important;
  margin-top: 16px;
  margin-bottom: 0;
  clear: both; }

.bg-gradient-loop.team-section .view_more {
  text-align: right;
  margin-top: 30px !important; }

.bg-gradient-loop.team-section .view_more a {
  text-decoration: none;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 0;
  text-align: right;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle; }

.bg-gradient-loop.team-section .view_more a:hover {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  background-clip: border-box, border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.bg-gradient-loop.team-section .view_more a img.ui.image {
  margin-left: 10px;
  vertical-align: middle;
  margin-top: 3px; }

.bg-gradient-loop.team-section .innerboxx {
  margin-bottom: 10px !important; }

.bg-gradient-loop.team-section .team-box .innerboxx {
  margin-bottom: 30px !important; }

.bg-gradient-loop.team-section .innerboxx .content {
  background: #272527;
  border-radius: 10px;
  height: 100%;
  padding: 20px 30px; }

.bg-gradient-loop.team-section .innerboxx .content .header {
  font-family: Lexend;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 154.5%;
  letter-spacing: -0.015em;
  color: #ffffff; }

.bg-gradient-loop.team-section .innerboxx .content .description {
  font-family: Lexend;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ababab; }

.bg-gradient-loop.team-section .innerboxx .content .ui.list .item {
  display: inline-block;
  margin-right: 22px;
  position: relative;
  font-family: Lexend;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #ffffff;
  padding: 0; }

.bg-gradient-loop.team-section .innerboxx .content .ui.list .item:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 3px;
  background: #514a4f;
  right: -12px;
  top: 0;
  opacity: 0.6; }

.bg-gradient-loop.team-section
.innerboxx
.content
.ui.list
.item:last-child:before {
  display: none; }

.bg-gradient-loop.newsletter-wrap {
  padding-bottom: 160px;
  padding-top: 50px; }

body .bg-gradient-loop.community_Tab.partners .column {
  width: 50% !important;
  max-width: 50%; }

.cz-feedSection.cz-feedSection-padding.official_news {
  padding: 0; }

.cz-feedSection.cz-feedSection-padding.official_news .feed.cz-con-new {
  background: #131313;
  border-radius: 10px; }

.bg-gradient-loop.community_Tab.partners.community_partners {
  padding-top: 150px;
  padding-bottom: 80px; }

.bg-gradient-loop.feedback_wrap {
  padding-top: 40px; }

@media screen and (max-width: 1499px) {
  .community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-box::before {
    left: -22px; }
  .community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-reading::after {
    right: -22px; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
    padding-left: 85px; }
  .bg-gradient-loop.community_Tab.partners.community_dex {
    padding-top: 145px;
    padding-bottom: 170px; } }

@media screen and (max-width: 1399px) {
  .team-slider .swiper-slide.swiper-slide-active .content {
    display: block; } }

@media screen and (max-width: 1299px) {
  .bg-gradient-loop.community_Tab.partners.performers_wrap .ui.container {
    max-width: 95% !important; } }

@media screen and (max-width: 1199px) {
  .imgTxt h5,
  .imgTxt p {
    text-align: left; }
  .iconTxtWrp img.ui.image {
    height: auto !important; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-prev,
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    left: 36%; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    left: auto;
    right: 36%; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box,
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-reading {
    padding: 15px 0; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.graph-box {
    padding: 35px 0; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box
.ui.image {
    width: 40px !important;
    height: auto !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex .ui.container {
    max-width: 95% !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex {
    padding-top: 100px !important;
    padding-bottom: 80px; }
  .community_slider .swiper-container .swiper-slide.swiper-slide-next:after {
    display: none; }
  .community_slider .swiper-container .swiper-slide {
    opacity: 0; }
  .row.pre_loader_wrap .column.pre_loader_box:last-child {
    display: none; }
  .row.pre_loader_wrap .column.pre_loader_box {
    width: 50%;
    max-width: 50%; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
    width: 50% !important;
    margin-left: auto;
    padding-left: 10px;
    margin-right: 0; }
  .bg-gradient-loop.team-section .innerboxx .content .description {
    font-size: 14px;
    line-height: 22px; }
  .bg-gradient-loop.team-section .innerboxx .content .header {
    font-size: 20px; }
  .bg-gradient-loop.team-section .innerboxx .content {
    padding: 20px 26px; }
  .community_slider .swiper-button-prev {
    left: -70px; }
  .community_slider .swiper-button-next {
    right: -70px; }
  .community_slider
.swiper-container
.swiper-slide.swiper-slide-duplicate-prev {
    opacity: 0; }
  .appDownload p {
    font-size: 15px;
    line-height: 20px; } }

.marketDown::before {
  margin-top: 7px; }

@media screen and (max-width: 1024px) {
  .cz-expanded-items.cz-expanded-itemsActive .item {
    padding: 5px 7.5px !important; }
  .cz-tokens-mobile
> div
> div
.cz-tokens-mobile-td.cz-left-td:nth-child(2)
.ui.grid
div
div {
    padding-right: 10px !important; }
  .cz-tokens-mobile-td .ui.grid .icon-table {
    padding: 0; }
  .ui.grid.page-wrapper.editor_wrap
.ui.grid.cz-new-js
#editor
.sc-CtfFt
*
.sc-brqgnP {
    padding: 0 10px; }
  .ui.grid.page-wrapper.editor_wrap
.ui.grid.cz-new-js
#editor
.sc-CtfFt
*
.sc-kafWEX.jrGuKT {
    width: 0; }
  .ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor .sc-CtfFt * {
    width: 100%; }
  .row.rewards-section.cz-rewards .four.wide.column a.item {
    font-size: 20px;
    height: auto;
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .row.rewards-section.cz-rewards .ui.grid .four.wide.column {
    width: 310px !important; }
  .row.rewards-section.cz-rewards .ui.grid .stretched.twelve.wide.column {
    width: calc(100% - 310px) !important; }
  .row.rewards-section.cz-rewards
.stretched.twelve.wide.column
.ui.bottom.attached.segment.active.tab
span {
    width: 85%; }
  .ui.grid.hero_features {
    display: flex;
    align-items: center; }
  .ui.grid.hero_features .innerBox {
    flex-direction: column; }
  .innerBox p {
    margin-left: 0; } }

@media screen and (max-width: 860px) {
  .row.rewards-section.cz-rewards
.stretched.twelve.wide.column
.ui.bottom.attached.segment.active.tab
span {
    width: 100%; }
  .row.newsletter-section .seven.wide.computer.newsletter-form {
    width: calc(100% - 200px) !important; }
  .row.newsletter-section .nine.wide.computer.newsletter-img {
    width: 200px !important; } }

.bg-gradient-loop.team-section .team-box {
  margin: 0 !important; }

@media screen and (max-width: 768px) {
  .left-animation-div .dateTxtInner {
    justify-content: flex-start;
    min-height: 100px; }
  header.cz-header-fixed {
    padding: 10px 0 !important; }
  .community_slider .swiper-slide h4 {
    margin-top: 0px;
    margin-bottom: 10px; }
  .bg-gradient-loop.team-section .team-box .column.innerboxx {
    width: 100% !important; }
  .bg-gradient-loop.team-section .innerboxx .content {
    padding: 20px 20px; }
  .bg-gradient-loop.team-section .innerboxx {
    margin-bottom: 15px !important; }
  .bg-gradient-loop.team-section .view_more {
    text-align: left;
    margin-top: 0px !important; }
  .bg-gradient-loop.team-section .view_more a {
    text-align: left;
    justify-content: flex-start; }
  .team-slider .swiper-slide.swiper-slide-active .content {
    width: 100%; }
  .community_slider .swiper-button-prev {
    left: 0 !important; }
  .community_slider .swiper-button-next {
    right: 0; }
  .community_slider .swiper-container {
    max-width: 100%;
    padding: 0 40px; }
  .bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide {
    opacity: 0; }
  .ui.container.cz-support.cz-support-set,
  .sixteen.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.start-learning-card {
    padding: 0; }
  .row.newsletter-section .seven.wide.computer.newsletter-form {
    width: 100% !important;
    padding: 0;
    text-align: center; }
  .row.newsletter-section .nine.wide.computer.newsletter-img {
    width: 200px !important;
    margin: 0 auto; }
  .row.rewards-section.cz-rewards .four.wide.column {
    width: 100% !important; }
  .row.rewards-section.cz-rewards .stretched.twelve.wide.column {
    width: 100% !important; }
  .row.rewards-section.cz-rewards
.stretched.twelve.wide.column
.ui.bottom.attached.segment.active.tab
span {
    width: 100%; }
  .row.rewards-section.cz-rewards .four.wide.column a.item {
    float: left;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0 0 10px;
    height: 35px;
    width: 50%;
    font-size: 14px;
    align-items: center; }
  .row.rewards-section.cz-rewards .four.wide.column a.item span {
    width: 26px;
    height: 26px;
    line-height: 26px; }
  .row.rewards-section.cz-rewards .ui.grid .four.wide.column {
    width: 100% !important; }
  .row.rewards-section.cz-rewards .ui.grid .stretched.twelve.wide.column {
    width: 100% !important;
    padding-right: 0;
    text-align: center; }
  .row.rewards-section.cz-rewards
.stretched.twelve.wide.column
.ui.bottom.attached.segment.active.tab
span {
    font-size: 16px; }
  .ui.grid.financial_freedom {
    padding: 60px 30px 50px; }
  .row.our_partner {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .bg-gradient-loop {
    padding: 30px 15px !important; }
  .yield {
    margin-top: 50px !important; }
  .financial_freedom .appDownload_box:nth-child(1) {
    order: 1;
    margin-top: 50px !important; }
  img.ui.image.line {
    max-width: 80% !important; }
  .row.exchange-section .column.innerboxx {
    order: 2; }
  .row.exchange-section {
    padding: 50px 10px 50px !important;
    margin-left: 0; }
  .row.loop-section {
    align-items: center !important;
    margin: 0; }
  .exchange-img {
    justify-content: center; }
  .exchange-img img {
    margin-bottom: 20px; }
  .support-img {
    padding-right: 15px !important;
    display: flex !important;
    justify-content: center; }
  .row.support-section {
    margin-left: 0; }
  .loop-text {
    max-width: 100%;
    padding-left: 15px;
    margin-top: 30px;
    padding-right: 15px; }
  .row.newsletter-section {
    padding: 30px 10px 30px !important; }
  .row.appDownload,
  .hero_section .row,
  .row.newsletter-section {
    margin: 0; }
  .row.join-community {
    margin: 0 0 50px; }
  .row.our_partner {
    margin: 50px 0 20px; }
  .homebg_section .mainSection {
    min-height: 100vh; }
  .support-text {
    margin-top: 30px; } }

@media screen and (max-width: 600px) {
  .homebg_section .mainSection {
    min-height: 400px; }
  .bg-gradient-loop.extension_wrap .support-section ul li {
    font-size: 14px !important;
    line-height: 25px; }
  .community_slider .swiper-container .swiper-slide .img-box {
    margin-right: 20px;
    width: 32px;
    height: 32px;
    min-width: 32px; }
  .community_Tab h2.ui.header {
    margin-bottom: 10px !important;
    margin-top: 16px; }
  .homebg_section .mainSection {
    background-size: cover;
    background-image: url(/img/black-hole-mobile.png); }
  .mainSection .ui.grid.hero_section .wide.column {
    padding: 0; }
  .mobileSignUpBox {
    margin-top: 100px !important; }
  .sidebarVisible.sidebar-wrapper .sidebar.menu p.closeBtn {
    right: 50px !important;
    width: 30px !important;
    height: 30px !important;
    top: 5px !important; }
  .sidebarVisible.sidebar-wrapper .sidebar.menu p.closeBtn::before {
    left: 7px !important;
    top: -6px !important;
    font-size: 40px !important; }
  .bg-gradient-loop.newsletter-wrap {
    padding-bottom: 60px !important; }
  .row.newsletter-section button.ui.button {
    height: 45px;
    font-size: 18px; }
  .row.newsletter-section .field {
    height: 45px; }
  .row.newsletter-section .ui.form .field input {
    height: 45px;
    font-size: 16px; }
  .bg-gradient-loop.feedback_wrap {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .row.support-section p,
  .row.exchange-section p,
  .loop-text p,
  .row.newsletter-section p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 15px; }
  .mainSection .ui.list {
    margin-bottom: 10px;
    text-align: center;
    margin-top: 25px; }
  .cz-home-set-icons .cz-icons .socialLink img.ui.image {
    width: 18px; }
  .cz-home-set-icons .cz-icons .socialLink {
    width: 34px;
    height: 34px;
    margin-right: 18px; }
  .ui.grid.hero_section
.headerBtnBox.position-relative
button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0,
  a.tutorialLink {
    height: 45px;
    margin: 0 auto; }
  .cz-home-set-icons .cz-icons {
    margin-left: 18px !important; }
  .ui.grid.hero_section .headerBtnBox.position-relative {
    display: block;
    max-width: 500px;
    margin: 30px auto;
    text-align: center !important; }
  a.tutorialLink button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
    width: 100% !important;
    font-size: 15px;
    height: 45px; }
  .row.join-community button.ui.button.btn.headerBtn {
    width: 220px;
    height: 45px;
    display: block;
    margin: 20px auto;
    font-size: 15px; }
  body header {
    height: 60px !important; }
  .bg-gradient-loop {
    padding: 15px 15px !important; }
  .bg-gradient-loop.cz-mobi-screens {
    display: none; }
  body .row.support-section {
    padding-top: 40px !important; }
  .cz-tokens-mobile
> div
> div
.cz-tokens-mobile-td.cz-left-td:nth-child(2)
.ui.grid {
    padding-left: 10px !important; }
  .mainSection.cz-exchange.exchange_banner_area {
    padding-top: 9.5rem; }
  .mainSection.cz-exchange.exchange_banner_area .headerContent {
    font-size: 25px;
    padding: 0px; }
  .ui.grid.page-wrapper.editor_wrap
.ui.grid.cz-new-js
#editor
.sc-kTUwUJ.boRwMT
.sc-hXRMBi {
    width: 95% !important;
    padding: 0 5px; }
  .ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor .sc-eMigcr {
    width: 35px; }
  .ui.grid.page-wrapper.editor_wrap
.ui.grid.cz-new-js
#editor
.sc-kTUwUJ.boRwMT
.sc-dqBHgY.jNprZY {
    display: block;
    padding: 0 10px; }
  .bg-gradient-loop.team-section h2.ui.header {
    font-size: 42px; }
  .mainSection .ui.list .item::before {
    right: -15px; }
  .mainSection .ui.list .item {
    margin-right: 25px; }
  .mainSection .ui.list .item a {
    font-size: 15.79px;
    line-height: 13px; }
  .mainSection {
    padding-bottom: 40px; }
  .community_slider .swiper-container {
    padding: 0 30px; }
  .community_slider {
    background-size: cover; }
  .community_wrap .community_slider::before {
    opacity: 0; }
  .row.newsletter-section .newsletter-form {
    order: 2; }
  .our_partner img.ui.image.img-fluid {
    margin: 0 auto; }
  .seven.wide.computer.sixteen.wide.mobile.nine.wide.tablet.column.yield
.accordion-body {
    padding-left: 38px; }
  h1.ui.header.headerContent {
    font-size: 46.19px;
    padding: 0px;
    line-height: 45.72px; }
  p.subHeading {
    font-size: 18px;
    padding: 0 20px; }
  .community_Tab h2.ui.header,
  .appDownload h2,
  .financial_freedom h2.ui.header.integrationTabH2,
  .row.join-community h2,
  .row.exchange-section h2,
  .loop-text h2,
  .row.newsletter-section h2,
  .row.support-section h2 {
    font-size: 28px !important;
    line-height: 30px !important; }
  .hero_features span {
    display: none; }
  .hero_features {
    margin: 40px auto 0 !important;
    justify-content: space-around;
    display: none; }
  .ui.grid.hero_features .innerBox {
    margin-bottom: 25px;
    width: 50%; }
  .community_Tab p {
    font-size: 16px !important;
    line-height: 22px; }
  .innerBox h6.ui.header {
    font-size: 24px; }
  .community_Tab .accordion button {
    font-size: 16px !important; }
  .community_Tab .accordion .accordion-item {
    margin-bottom: 14px !important;
    padding-top: 12px;
    padding-bottom: 12px; }
  .community_Tab .accordion-body,
  .community_Tab .accordion-body {
    font-size: 14px !important;
    line-height: 18px !important; }
  .appDownload p {
    font-size: 15px;
    line-height: 21px; }
  .our_partner_main .innerboxx {
    order: 1;
    margin-top: 30px !important; }
  .community_Tab .accordion button span {
    width: 24px !important;
    height: 24px !important;
    font-size: 14px !important; }
  #accordionLoop,
  #accordionLoop2,
  div#accordionLoop1 {
    padding-left: 30px !important; }
  .community_Tab .accordion button::after {
    left: -50px !important; }
  .bg-gradient-loop.community_Tab.partners .ui.container {
    padding: 0; }
  .ui.grid.financial_freedom {
    padding: 60px 20px 50px; }
  .financial_freedom .appDownload_box:nth-child(1) {
    order: 1;
    margin-top: 50px !important; }
  .join-community-card,
  .start-learning-card {
    flex-direction: column;
    margin-left: 7px; }
  .join-community-card .card,
  .start-learning-card .card {
    width: 100% !important;
    margin-bottom: 30px !important; }
  .loop-text {
    padding-left: 15px; } }

@media screen and (max-width: 420px) {
  li.grphImg {
    display: none !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex .learnMore {
    margin-left: 55px !important; }
  a.learnMore {
    margin-left: 0 !important;
    margin-top: 34px;
    margin-bottom: 20px !important; }
  .row.newsletter-section .field {
    margin: 0 0px 10px 0 !important;
    width: 100%;
    height: 55px; }
  .row.newsletter-section form.ui.form {
    flex-direction: column; }
  .row.newsletter-section button.ui.button {
    width: 100%; } }

@media screen and (max-width: 360px) {
  .ui.grid.hero_features .innerBox {
    width: 100%; } }

:root {
  --bs-blue: #2937f0;
  --bs-indigo: #6610f2;
  --bs-purple: #9f1ae2;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #2937f0;
  --bs-secondary: #9f1ae2;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  ); }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Lexend", sans-serif;
  font-weight: 600;
  line-height: 1.2; }

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw); }

@media (min-width: 1450px) {
  h1,
  .h1 {
    font-size: 2.5rem; } }

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw); }

@media (min-width: 1450px) {
  h2,
  .h2 {
    font-size: 2rem; } }

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw); }

@media (min-width: 1450px) {
  h3,
  .h3 {
    font-size: 1.75rem; } }

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw); }

@media (min-width: 1450px) {
  h4,
  .h4 {
    font-size: 1.5rem; } }

h5,
.h5 {
  font-size: 1.25rem; }

h6,
.h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small,
.small {
  font-size: 0.875em; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #2937f0;
  text-decoration: underline; }

a:hover {
  color: #212cc0; }

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: "Lexend", sans-serif;
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: "Lexend";
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

select:disabled {
  opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }

@media (min-width: 1450px) {
  legend {
    font-size: 1.5rem; } }

legend + * {
  clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1450px) {
  .display-1 {
    font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1450px) {
  .display-2 {
    font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1450px) {
  .display-3 {
    font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1450px) {
  .display-4 {
    font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1450px) {
  .display-5 {
    font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }

@media (min-width: 1450px) {
  .display-6 {
    font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote > :last-child {
  margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }

.blockquote-footer::before {
  content: "— "; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px; } }

@media (min-width: 1450px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5); }

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; } }

@media (min-width: 1450px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1450px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333333%; }
  .offset-xxl-2 {
    margin-left: 16.66666667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333333%; }
  .offset-xxl-5 {
    margin-left: 41.66666667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333333%; }
  .offset-xxl-8 {
    margin-left: 66.66666667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333333%; }
  .offset-xxl-11 {
    margin-left: 91.66666667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }

.table > tbody {
  vertical-align: inherit; }

.table > thead {
  vertical-align: bottom; }

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #d4d7fc;
  --bs-table-striped-bg: #c9ccef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfc2e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4c7e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bfc2e3; }

.table-secondary {
  --bs-table-bg: #ecd1f9;
  --bs-table-striped-bg: #e0c7ed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4bce0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dac1e6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d4bce0; }

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.community_slider .swiper-container .swiper-slide.swiper-slide-prev,
.community_slider .swiper-container .swiper-slide.swiper-slide-duplicate-prev {
  opacity: 0; }

.bg-gradient-loop.community_Tab.partners.community_dex
.column
img.ui.image.img-fluid.mob_show {
  display: none; }

@media (max-width: 767px) {
  .mainSection.cz-exchange.exchange_banner_area .headerContent {
    text-shadow: none; }
  .link-design h2,
  .address-design h2 {
    margin-top: 30px !important; }
  .about-company p {
    font-size: 14px; }
  .address-design p span {
    margin-right: 0px; }
  .bg-gradient-loop.marketpalce_wrap.community_Tab.partners.community_dex {
    background: none; }
  .bg-gradient-loop.community_Tab.partners.community_dex .ui.container {
    max-width: 100% !important;
    padding-right: 0; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row {
    padding: 0 !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex {
    background: none;
    padding-top: 30px !important; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
    padding-left: 15px !important; }
  .bg-gradient-loop.marketpalce_wrap.community_Tab.partners.community_dex
.img-fluid {
    display: block !important;
    margin-bottom: 50px; }
  .bg-gradient-loop.marketpalce_wrap.community_Tab.partners.community_dex
.column.yield {
    margin-top: 0 !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex
.column
img.ui.image.img-fluid.mob_show {
    display: block;
    margin-left: 30px; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
    padding-left: 15px; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.markets_header {
    font-size: 24px !important;
    text-align: left; }
  .community_slider .swiper-container .swiper-slide .img-box {
    margin-right: 20px; }
  .community_slider .swiper-container .swiper-slide .img-box img {
    width: 100%;
    height: auto; }
  .community_slider .swiper-slide p {
    font-size: 12px; }
  .community_slider {
    z-index: 1;
    -webkit-backdrop-filter: blur(73.58px) !important;
    backdrop-filter: blur(73.58px) !important;
    padding: 40px 30px 40px !important;
    position: relative;
    background: url(/img/community_bg_mobile.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .bg-gradient-loop.footer_wrap * {
    padding: 0 3px !important; }
  .appDownload p {
    margin-top: 5px;
    margin-bottom: 30px; }
  .row.appDownload .appDownload_box {
    padding-bottom: 0px; }
  .bg-gradient-loop.newsletter-wrap .ui.container {
    padding: 0; }
  .bg-gradient-loop.community_Tab.partners.community_partners .innerboxx_image {
    width: 100% !important; }
  .community_slider {
    padding: 40px 15px !important; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .ui.container {
    max-width: 100% !important; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .ui.container {
    max-width: 100% !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex .column.yield {
    width: 100% !important;
    margin-top: 35px !important;
    max-width: 100%;
    height: auto;
    order: 2 !important; }
  .bg-gradient-loop.community_Tab.partners.community_dex .column {
    width: 100% !important;
    max-width: 100%;
    height: auto;
    order: 1 !important; }
  header .ui.container .container a.navbar-brand.fw-bold {
    text-align: left !important; }
  .cztoggle-menu-mobile {
    width: 35px; }
  header .ui.container .container a.navbar-brand.fw-bold img {
    height: 40px !important; }
  .community_wrap .community_slider .swiper-button-prev,
  .community_wrap .community_slider .swiper-button-next {
    display: block;
    text-align: center; }
  .community_slider .swiper-container .swiper-slide.swiper-slide-next {
    opacity: 0; }
  .row.pre_loader_wrap .column.pre_loader_box:last-child,
  .row.pre_loader_wrap .column.pre_loader_box:nth-last-child(2) {
    display: none; }
  .row.pre_loader_wrap .column.pre_loader_box {
    width: 100%;
    max-width: 100%; }
  .bg-gradient-loop.community_Tab.partners.community_partners
.ui.container
.innerboxx {
    margin-top: 70px;
    order: 1;
    padding-right: 15px; }
  body .bg-gradient-loop.community_Tab.partners .column {
    width: 100% !important;
    max-width: 100%; } }

@media (max-width: 767.98px) {
  .row.team-section {
    margin-top: 30px; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .ui.container {
    max-width: 95% !important;
    margin: 0 auto !important; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td {
    display: flex !important;
    width: 100% !important; }
  .bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide.swiper-slide-next
+ div {
    opacity: 0; }
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .link-design h2,
  .address-design h2 {
    margin-top: 30px !important; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx_image {
    width: 100% !important;
    order: 2;
    max-width: 100%; }
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
    margin-top: 0 !important;
    width: 100% !important;
    max-width: 100%;
    padding-left: 15px;
    margin-top: 0px;
    order: 1;
    margin-bottom: 40px;
    margin-top: 40px !important; }
  .bg-gradient-loop.team-section .innerboxx {
    width: 50% !important; }
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .row.support-section {
    padding-top: 50px !important; }
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control[type="file"] {
  overflow: hidden; }

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #949bf8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.form-control::-webkit-date-and-time-value {
  height: 1.5em; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3; }

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none; } }

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem; }

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem; }

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px)); }

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px)); }

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px)); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem; }

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer; }

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem; }

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none; } }

.form-select:focus {
  border-color: #949bf8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none; }

.form-select:disabled {
  background-color: #e9ecef; }

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact; }

.form-check-input[type="checkbox"] {
  border-radius: 0.25em; }

.form-check-input[type="radio"] {
  border-radius: 50%; }

.form-check-input:active {
  filter: brightness(90%); }

.form-check-input:focus {
  border-color: #949bf8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.form-check-input:checked {
  background-color: #2937f0;
  border-color: #2937f0; }

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #2937f0;
  border-color: #2937f0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5; }

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none; } }

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23949bf8'/%3e%3c/svg%3e"); }

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.form-range:focus {
  outline: 0; }

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.form-range::-moz-focus-outer {
  border: 0; }

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2937f0;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none; } }

.form-range::-webkit-slider-thumb:active {
  background-color: #bfc3fb; }

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2937f0;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none; } }

.form-range::-moz-range-thumb:active {
  background-color: #bfc3fb; }

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.form-range:disabled {
  pointer-events: none; }

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.form-floating {
  position: relative; }

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25; }

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none; } }

.form-floating > .form-control {
  padding: 1rem 0.75rem; }

.form-floating > .form-control::-moz-placeholder {
  color: transparent; }

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent; }

.form-floating > .form-control::placeholder {
  color: transparent; }

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem; }

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3; }

.input-group .btn {
  position: relative;
  z-index: 2; }

.input-group .btn:focus {
  z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation)
> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation
> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group
> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #198754; }

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #198754; }

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #198754; }

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em; }

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545; }

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dc3545; }

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dc3545; }

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em; }

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529; }

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #2937f0;
  border-color: #2937f0; }

.btn-primary:hover {
  color: #fff;
  background-color: #232fcc;
  border-color: #212cc0; }

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #232fcc;
  border-color: #212cc0;
  box-shadow: 0 0 0 0.25rem rgba(73, 85, 242, 0.5); }

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #212cc0;
  border-color: #1f29b4; }

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(73, 85, 242, 0.5); }

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #2937f0;
  border-color: #2937f0; }

.btn-secondary {
  color: #fff;
  background-color: #9f1ae2;
  border-color: #9f1ae2; }

.btn-secondary:hover {
  color: #fff;
  background-color: #8716c0;
  border-color: #7f15b5; }

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #8716c0;
  border-color: #7f15b5;
  box-shadow: 0 0 0 0.25rem rgba(173, 60, 230, 0.5); }

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #7f15b5;
  border-color: #7714aa; }

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(173, 60, 230, 0.5); }

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: #9f1ae2;
  border-color: #9f1ae2; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43; }

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f; }

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }

.btn-success:disabled,
.btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2; }

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2; }

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720; }

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720; }

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37; }

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834; }

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb; }

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21; }

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f; }

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-outline-primary {
  color: #2937f0;
  border-color: #2937f0; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2937f0;
  border-color: #2937f0; }

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.5); }

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #2937f0;
  border-color: #2937f0; }

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.5); }

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #2937f0;
  background-color: transparent; }

.btn-outline-secondary {
  color: #9f1ae2;
  border-color: #9f1ae2; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #9f1ae2;
  border-color: #9f1ae2; }

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(159, 26, 226, 0.5); }

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #9f1ae2;
  border-color: #9f1ae2; }

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(159, 26, 226, 0.5); }

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #9f1ae2;
  background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #198754;
  background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent; }

.btn-link {
  font-weight: 500;
  color: #2937f0;
  text-decoration: underline; }

.btn-link:hover {
  color: #212cc0; }

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d; }

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0; }

.dropdown-menu-end {
  --bs-position: end; }

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1450px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 500;
  color: #fff;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef; }

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2937f0; }

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #2937f0; }

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd; }

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15); }

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6; }

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto; }

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0; }

.dropstart .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%; }

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px; }

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #2937f0;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none; } }

.nav-link:hover,
.nav-link:focus {
  color: #212cc0; }

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2937f0; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin: auto;
  margin-left: 0; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none; } }

.navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 6px 0.001rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin-left: 100px; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (min-width: 1450px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xxl .navbar-toggler {
    display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }

.navbar-expand .navbar-nav {
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.navbar-expand .navbar-nav-scroll {
  overflow: visible; }

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit; }

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none; } }

.accordion-button:not(.collapsed) {
  color: #2532d8;
  background-color: #eaebfe;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232532d8'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg); }

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none; } }

.accordion-button:hover {
  z-index: 2; }

.accordion-button:focus {
  z-index: 3;
  border-color: #949bf8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.125); }

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.accordion-item:not(:first-of-type) {
  border-top: 0; }

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.accordion-flush .accordion-item:first-child {
  border-top: 0; }

.accordion-flush .accordion-item:last-child {
  border-bottom: 0; }

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/"); }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #2937f0;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none; } }

.page-link:hover {
  z-index: 2;
  color: #212cc0;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 3;
  color: #212cc0;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2937f0;
  border-color: #2937f0; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem; }

.alert-primary {
  color: #192190;
  background-color: #d4d7fc;
  border-color: #bfc3fb; }

.alert-primary .alert-link {
  color: #141a73; }

.alert-secondary {
  color: #5f1088;
  background-color: #ecd1f9;
  border-color: #e2baf6; }

.alert-secondary .alert-link {
  color: #4c0d6d; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }

.alert-success .alert-link {
  color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }

.alert-info .alert-link {
  color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }

.alert-warning .alert-link {
  color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }

.alert-danger .alert-link {
  color: #6a1a21; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light .alert-link {
  color: #4f5050; }

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf; }

.alert-dark .alert-link {
  color: #101214; }

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2937f0;
  transition: width 0.6s ease; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit; }

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2937f0;
  border-color: #2937f0; }

.list-group-item + .list-group-item {
  border-top-width: 0; }

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0; }

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0; }

.list-group-horizontal > .list-group-item.active {
  margin-top: 0; }

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0; }

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1450px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }

.list-group-flush > .list-group-item {
  border-width: 0 0 1px; }

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #192190;
  background-color: #d4d7fc; }

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #192190;
  background-color: #bfc2e3; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #192190;
  border-color: #192190; }

.list-group-item-secondary {
  color: #5f1088;
  background-color: #ecd1f9; }

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #5f1088;
  background-color: #d4bce0; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5f1088;
  border-color: #5f1088; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75; }

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(41, 55, 240, 0.25);
  opacity: 1; }

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.toast:not(.showing):not(.show) {
  opacity: 0; }

.toast.hide {
  display: none; }

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none; }

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px); }

@media (max-width: 639px) {
  .mainSection.cz-exchange.exchange_banner_area .headerContent {
    font-size: 20px; }
  .bg-gradient-loop
.ui.container.cz-support.cz-support-set
.row.join-community.start-learning
.start-learning-card
.swiper-container
.swiper-slide.swiper-slide-next {
    opacity: 0; } }

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  transform: none; }

.modal.modal-static .modal-dialog {
  transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden; }

.modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }

.modal-footer > * {
  margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1450px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0; }

.modal-fullscreen .modal-header {
  border-radius: 0; }

.modal-fullscreen .modal-body {
  overflow-y: auto; }

.modal-fullscreen .modal-footer {
  border-radius: 0; }

@media (max-width: 575.98px) {
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
.icon-reading
p {
    font-size: 12px !important; }
  .community_Tab.partners.performers_wrap
.innerboxx_image
.markets_widget
.ui.table
.portfolio-table-row
td
p
small {
    font-size: 12px; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box
p {
    font-size: 13px !important;
    line-height: 22px; }
  .community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row
td
.icon-reading {
    padding-right: 5px !important; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
.icon-reading {
    width: 80px; }
  .community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-reading::after {
    width: 100%; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child {
    height: 80px; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
.icon-box,
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row:first-child
.icon-reading {
    padding: 9.8px 0; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-box,
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.icon-reading {
    padding: 15px 0px; }
  .community_Tab.partners.performers_wrap
.ui.container
.markets_widget
.portfolio-table-row
td
.graph-box
.ui.image {
    width: 100% !important; }
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 767.98px) {
  .bg-gradient-loop.footer_wrap {
    padding-top: 60px !important;
    padding-bottom: 30px !important; }
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0; }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto; }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: 0.9; }

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem; }

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000; }

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000; }

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000; }

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem; }

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff; }

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none; } }

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none; } }

.carousel-indicators .active {
  opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none; } }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #2937f0; }

.link-primary:hover,
.link-primary:focus {
  color: #212cc0; }

.link-secondary {
  color: #9f1ae2; }

.link-secondary:hover,
.link-secondary:focus {
  color: #7f15b5; }

.link-success {
  color: #198754; }

.link-success:hover,
.link-success:focus {
  color: #146c43; }

.link-info {
  color: #0dcaf0; }

.link-info:hover,
.link-info:focus {
  color: #3dd5f3; }

.link-warning {
  color: #ffc107; }

.link-warning:hover,
.link-warning:focus {
  color: #ffcd39; }

.link-danger {
  color: #dc3545; }

.link-danger:hover,
.link-danger:focus {
  color: #b02a37; }

.link-light {
  color: #f8f9fa; }

.link-light:hover,
.link-light:focus {
  color: #f9fafb; }

.link-dark {
  color: #212529; }

.link-dark:hover,
.link-dark:focus {
  color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: ""; }

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1450px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2937f0 !important; }

.border-secondary {
  border-color: #9f1ae2 !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: "Lexend", sans-serif; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 500 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  color: #2937f0 !important; }

.text-secondary {
  color: #9f1ae2 !important; }

.text-success {
  color: #198754 !important; }

.text-info {
  color: #0dcaf0 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #212529 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.bg-primary {
  background-color: #2937f0 !important; }

.bg-secondary {
  background-color: #9f1ae2 !important; }

.bg-success {
  background-color: #198754 !important; }

.bg-info {
  background-color: #0dcaf0 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #212529 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1450px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1450px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

html {
  scroll-padding-top: 4.5rem; }

.bg-black {
  background-color: #000 !important; }

.bg-gradient-primary-to-secondary {
  background: linear-gradient(45deg, #2937f0, #9f1ae2) !important; }

.font-alt {
  font-family: "Lexend", sans-serif; }

.text-gradient {
  background: -webkit-linear-gradient(#2937f0, #9f1ae2);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

aside,
section {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.app-badge {
  height: 3rem; }

#mainNav {
  padding: 0;
  backdrop-filter: blur(38px);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.04), rgba(255, 255, 255, 0.04));
  z-index: 9999999; }

#mainNav .navbar-brand {
  font-family: "Lexend", sans-serif; }

#mainNav .navbar-brand:hover,
#mainNav .navbar-brand:active {
  color: #2937f0; }

#mainNav .navbar-toggler {
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  color: #fff; }

#mainNav .navbar-nav .nav-item .nav-link {
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px; }

#mainNav .navbar-nav .nav-item .nav-link.active {
  color: red; }

.btn.headerBtn {
  display: flex !important;
  justify-content: space-around !important;
  background: linear-gradient(90.24deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  border-radius: 5px !important;
  color: #fff !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 0; }
  .btn.headerBtn--login {
    background: none !important;
    color: #fff !important; }
  .btn.headerBtn--connect {
    cursor: default;
    width: 200px !important;
    border-radius: 10px !important;
    color: #59d6e6 !important;
    font-weight: 600;
    font-size: 13px !important;
    line-height: 19px;
    background: transparent !important;
    border: 2px solid #59d6e6 !important;
    text-transform: uppercase !important;
    transition: ease-in-out 0.3; }
    .btn.headerBtn--connect img {
      filter: grayscale(0) brightness(100%);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      float: right; }
    .btn.headerBtn--connect:hover {
      background: #59d6e6 !important;
      color: #fff !important;
      cursor: pointer; }
    .btn.headerBtn--connect:focus {
      transform: scale(0.9); }
    .btn.headerBtn--connect:disabled {
      color: #ffffff30 !important;
      font-weight: 600;
      font-size: 13px !important;
      line-height: 19px;
      background: transparent !important;
      border: 2px solid #2b2a37 !important; }
      .btn.headerBtn--connect:disabled img {
        filter: grayscale(1) brightness(30%); }
  .btn.headerBtn--connected {
    cursor: default;
    border-radius: 10px !important;
    color: #59d6e6 !important;
    font-weight: 600;
    font-size: 13px !important;
    line-height: 19px;
    background: #0402207a !important;
    text-transform: uppercase !important;
    transition: ease-in-out 0.3; }
    .btn.headerBtn--connected img {
      filter: grayscale(0) brightness(100%);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      float: right;
      border: 2px solid #59d6e6 !important;
      margin: 0 20px; }
    .btn.headerBtn--connected:hover {
      background: #59d6e6 !important;
      color: #fff !important;
      cursor: pointer; }
    .btn.headerBtn--connected:focus {
      transform: scale(0.9); }
    .btn.headerBtn--connected:disabled {
      color: #ffffff30 !important;
      font-weight: 600;
      font-size: 13px !important;
      line-height: 19px;
      background: transparent !important;
      border: 2px solid #2b2a37 !important; }
      .btn.headerBtn--connected:disabled img {
        filter: grayscale(1) brightness(30%); }

.darkHeader {
  background: linear-gradient(0deg, #120510, #120510) !important; }

.device-wrapper {
  max-width: 300px;
  width: 100%; }

.device {
  position: relative;
  background-size: cover; }

.device::after {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.device .screen {
  position: absolute;
  background-size: cover;
  pointer-events: auto; }

.device .button {
  position: absolute;
  cursor: pointer; }

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 198.898071625%; }

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
.screen {
  top: 3.1855955679%;
  left: 6.8870523416%;
  width: 86.2258953168%;
  height: 93.6288088643%; }

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]::after {
  content: ""; }

.device[data-device="iPhoneX"][data-orientation="portrait"][data-color="black"]
.button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%; }

section.cta {
  position: relative;
  padding: 15rem 0;
  background-image: url("https://source.unsplash.com/5g41N3uRPf8/1500x800");
  background-position: center;
  background-size: cover; }

section.cta .cta-content {
  position: relative;
  z-index: 1; }

section.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); }

.icon-feature {
  font-size: 4rem; }

.features-device-mockup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }

.features-device-mockup .circle {
  position: absolute;
  left: 0;
  fill: url(#circleGradient);
  width: 70%;
  display: none; }

.features-device-mockup .circle .gradient-start-color {
  stop-color: #2937f0; }

.features-device-mockup .circle .gradient-end-color {
  stop-color: #9f1ae2; }

.features-device-mockup .shape-1 {
  position: absolute;
  height: 1rem;
  width: 1rem;
  transform: rotate(10deg);
  fill: #2937f0;
  top: 15%;
  right: 10%; }

.features-device-mockup .shape-2 {
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  fill: #2937f0;
  bottom: 15%;
  left: 10%; }

.features-device-mockup .device-wrapper {
  max-width: 10rem; }

@media (min-width: 576px) {
  .features-device-mockup .circle {
    width: 70%;
    display: block;
    left: auto; }
  .features-device-mockup .device-wrapper {
    max-width: 12.5rem; } }

@media (min-width: 768px) {
  .features-device-mockup .circle {
    width: 60%;
    left: auto; }
  .features-device-mockup .device-wrapper {
    max-width: 15rem; } }

@media (min-width: 992px) {
  .features-device-mockup .circle {
    width: 90%;
    left: -25%; }
  .features-device-mockup .shape-1 {
    top: 5%;
    right: -15%; }
  .features-device-mockup .shape-2 {
    bottom: 10%;
    left: -10%; }
  .features-device-mockup .device-wrapper {
    max-width: 15rem;
    margin-left: auto; } }

@media (min-width: 1450px) {
  .features-device-mockup .circle {
    width: 75%;
    left: 0; }
  .features-device-mockup .shape-2 {
    bottom: 15%;
    left: 10%; }
  .features-device-mockup .device-wrapper {
    max-width: 15rem; } }

@media (min-width: 1400px) {
  .features-device-mockup .circle {
    width: 80%;
    left: 0; }
  .features-device-mockup .device-wrapper {
    max-width: 17rem; } }

footer a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none; }

footer a:hover,
footer a:active,
footer a:focus {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline; }

.masthead {
  /* background-color: #f8f9fa; */
  padding-top: 9.5rem;
  padding-bottom: 5rem;
  background: linear-gradient(0deg, #120510, #120510); }

.masthead .masthead-device-mockup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }

.masthead .masthead-device-mockup .circle {
  position: absolute;
  fill: url(#circleGradient);
  width: 70%;
  display: none; }

.masthead .masthead-device-mockup .circle .gradient-start-color {
  stop-color: #2937f0; }

.masthead .masthead-device-mockup .circle .gradient-end-color {
  stop-color: #9f1ae2; }

.masthead .masthead-device-mockup .shape-1 {
  position: absolute;
  height: 1rem;
  width: 1rem;
  transform: rotate(-10deg);
  fill: #2937f0;
  bottom: 15%;
  left: 10%; }

.masthead .masthead-device-mockup .shape-2 {
  position: absolute;
  height: 0.75rem;
  width: 0.75rem;
  fill: #2937f0;
  top: 15%;
  right: 10%; }

.masthead .masthead-device-mockup .device-wrapper {
  max-width: 10rem; }

.masthead .headerContent {
  margin-bottom: 0;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  font-weight: 600;
  font-size: 56px; }

header.masthead p.subHeading {
  color: #ffffff;
  font-weight: 400;
  font-size: 22px;
  opacity: 0.8;
  margin-top: 20px; }

header.masthead a.learnMore {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin-left: 32px;
  line-height: 0;
  text-decoration: none;
  transition: 0.5s; }

header.masthead a.learnMore:hover {
  opacity: 0.8; }

header.masthead .headerBtn {
  width: 138px;
  height: 55px;
  position: relative;
  z-index: 9;
  transition: 0.5s; }

header.masthead .headerBtnBox::after {
  content: "";
  background: linear-gradient(90.24deg, rgba(250, 44, 171, 0.64) -1.6%, rgba(155, 105, 252, 0.64) 99.79%);
  filter: blur(40px);
  border-radius: 12px;
  position: absolute;
  top: 30px;
  left: 0;
  width: 138px;
  height: 55px; }

header.masthead .headerBtn:hover {
  transform: translateY(-5px); }

.analitycsText {
  margin-top: 75px; }

.analitycsText span {
  width: 1px;
  height: 37px;
  background: rgba(62, 65, 75, 0.8); }

.analitycsText .innerBox h6 {
  color: #ffffff;
  opacity: 0.9;
  font-weight: 600;
  font-size: 32px; }

.analitycsText .innerBox p {
  letter-spacing: 0.07em;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.6;
  margin-left: 16px;
  font-weight: 400; }

.bg-gradient-loop {
  background: linear-gradient(0deg, #120510, #120510);
  padding: 55px 15px 70px; }

.community_Tab .h6 {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; }

.community_Tab .h2 {
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #ffffff; }

.textGradient {
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; }

.community_Tab .accordion button {
  background: transparent;
  border: 0;
  color: #fff;
  letter-spacing: -0.015em;
  font-size: 24px;
  padding: 0;
  line-height: 0;
  outline: 0;
  display: flex;
  align-items: center;
  position: relative; }

.community_Tab .accordion button.collapsed span {
  background: #252525;
  width: 36px;
  height: 36px;
  font-weight: 600;
  font-size: 18px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px; }

.accordion-itemActive {
  border-radius: 20px !important;
  background: #221721;
  margin-bottom: 10px !important; }

.community_Tab .accordion button .community_Tab .accordion-body,
.community_Tab .accordion-body {
  padding: 9px 10px 9px 50px;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.8;
  font-size: 16px;
  font-weight: 400; }

#accordionLoop,
#accordionLoop2 {
  margin-top: 46px;
  padding-left: 46px; }

div#accordionLoop1 {
  margin-top: 46px;
  padding-left: 46px; }

.accordion .accordianLine {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 99999;
  /* height: 100%; */ }

.community_Tab .accordion button::after {
  content: url("/img/mouse.svg");
  position: absolute;
  left: -66px;
  top: 7px;
  opacity: 1;
  transition: 0.6s;
  transform: translate(-50%, 0%); }

.community_Tab .accordion button.collapsed::after {
  opacity: 0; }

.appDownload .row h2 {
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
  font-weight: 600;
  font-size: 46px; }

.appDownload .row p {
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 0.8;
  font-size: 17px;
  line-height: 25px;
  font-weight: 400; }

.swiper-container {
  width: 100%;
  height: 100%; }

.swiper-full-mobile {
  position: relative;
  margin: 50px auto;
  padding-bottom: 50px; }

/* .swiper-full-mobile:before {
  content: "";
  width: 265px;
  background: url(http://md-aqil.github.io/images/Pixel-3-XL.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 565px;
  z-index: 2;
  margin: auto;
} */
.swiper-full-mobile .swiper-slide {
  height: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.team-slider .swiper-slide.swiper {
  margin-right: 0 !important; }

.swiper-full-mobile .swiper-slide img {
  width: 100%;
  transform: scale(0.7);
  opacity: 0.3;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transform-origin: right; }

.swiper-full-mobile .swiper-slide.swiper-slide-next + .swiper-slide img {
  transform-origin: left; }

.swiper-slide.swiper-slide-active img {
  transform: scale(1);
  backdrop-filter: blur(0);
  opacity: 1; }

.swiper-full-mobile .swiper-slide.swiper-slide-prev img {
  transform: scale(0.9);
  transform-origin: left; }

.swiper-full-mobile .swiper-slide.swiper-slide-next img {
  transform: scale(0.9);
  transform-origin: right; }

.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev,
.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-next {
  width: 38px;
  height: 38px;
  padding: 0;
  background-size: contain;
  border-radius: 100px;
  background-position: center;
  top: 50%;
  position: absolute;
  background: linear-gradient(151.28deg, rgba(227, 227, 227, 0.3) 0%, rgba(0, 0, 0, 0.1) 100%);
  border: 1px solid #423441; }

.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev::after,
.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-next::after {
  color: white;
  font-size: 15px; }

.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev::after {
  transform: rotate(180deg); }

.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-next {
  right: 37.5%; }

.cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev {
  transform: rotate(180deg) !important;
  left: 37.5%; }

.integrationTab .integrationTabH2 {
  letter-spacing: -0.015em !important;
  color: #ffffff !important;
  margin-top: 16px !important;
  margin-bottom: 20px !important;
  font-size: 46px;
  font-weight: 600; }

.integrationTab p {
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 0.8;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 48px; }

.integrationTab .accordion-item {
  border: 0; }

.homebg {
  position: absolute;
  top: 0; }

.terraImg {
  border-radius: 20px; }

/* jk Css start */
.homebg_section.HomeTopBanner
.partnerSection
.ui.grid
> [class*="two column"].row
> .column:first-child {
  width: 25% !important; }

.homebg_section.HomeTopBanner
.partnerSection
.ui.grid
> [class*="two column"].row
> .column:last-child {
  width: 75% !important; }

.homebg_section.HomeTopBanner
.ui.grid.homeText
> [class*="two column"].row
> .column:first-child {
  width: calc(100% - 475px) !important; }

.homebg_section.HomeTopBanner
.ui.grid.homeText
> [class*="two column"].row
> .column:nth-child(2) {
  width: 475px !important;
  position: relative;
  top: 50px;
  left: 20px; }

.productSection .communityBox.inline-photo.show-on-scroll.is-visible {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.productSection h5 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  justify-content: center;
  background: linear-gradient(16deg, #fa2cab -1.6%, #9b69fc 99.79%), #fa2cab;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 150px;
  margin: 0 auto 15px; }

.productSection h2 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff !important;
  max-width: 850px;
  margin: auto;
  position: relative; }

h2.bottomLine {
  padding-bottom: 20px;
  margin-bottom: 15px; }

h2.bottomLine::before {
  position: absolute;
  content: "";
  right: 10px;
  width: 350px;
  height: 5px;
  background-image: url(/img/bottomLine.png);
  background-repeat: no-repeat;
  bottom: 16px; }

.communityBox {
  background: #1e1a1d;
  border-radius: 20px;
  padding: 55px 35px 0 45px; }

.app-body main.homepage .ui.container {
  max-width: 1450px !important; }

.bottomImg {
  display: flex;
  justify-content: flex-end;
  padding-top: 55px; }

.communityBox h2 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  margin-bottom: 15px;
  text-align: left; }

.homepage .communityBox p {
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.7; }

button.ui.button.joinBtn {
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
  background: transparent;
  padding-left: 0 !important;
  justify-content: space-between;
  text-transform: capitalize !important;
  transition: 0.8s all ease;
  transition: width 0.8s; }
  button.ui.button.joinBtn img {
    padding-left: 10px;
    transition: 0.5s all ease; }

.column.leftEco.leftEcoSecond {
  max-width: 40% !important; }

.column.nftImgRight.nftImgRightSecond.rightEco {
  min-width: 60%; }

button.ui.button.joinBtn:hover img {
  padding-left: 15px; }

body .ui.segment.pushable {
  background: #120510 !important; }

.productSection .ecoSystem {
  padding-top: 70px; }

.marketInner {
  background: #1e1a1d;
  border-radius: 20px;
  padding: 20px 0 20px 20px; }

.marketInner .two.column.row {
  align-items: center; }

.marketInner h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 107.5%;
  letter-spacing: -0.015em;
  color: #ffffff !important;
  margin-bottom: 15px; }

.marketInner p {
  font-family: Lexend;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  opacity: 0.7; }

.column.nftImgRight {
  padding-right: 0px !important; }

.nftImg img.ui.image {
  left: 10px; }

.groupBtns button.ui.button.joinBtn {
  width: 125px !important;
  margin-right: 10px; }

.groupBtns {
  display: flex; }

.groupBtns button.ui.button.joinBtn.quest {
  width: 150px !important; }

.groupBtns button.ui.button.joinBtn.learnBtn {
  width: 100px !important;
  margin-right: 0px; }

.communityBox.seedbox {
  padding-right: 0px; }

.seedInner {
  padding-right: 35px; }

.communityBox.seedbox .bottomImg {
  padding-top: 42px; }

.productSection.earnSection .ui.grid.ecoSystem {
  padding-top: 0px; }

.AppSection button.ui.button.btn.headerBtn {
  width: auto; }

.AppSection button.ui.button.btn.headerBtn:after {
  display: none; }

.AppSection .marketInner {
  padding-left: 45px;
  padding-bottom: 0px; }

.AppSection button.ui.button.btn.headerBtn {
  width: auto;
  margin-top: 25px; }

.AppSection .two.column.row,
.AppSection .one.column.row {
  padding-bottom: 0; }

.AppSection p {
  max-width: 400px; }

.extensionSection .bottomImg {
  padding-top: 0;
  margin-top: -70px; }

.extensionInner p {
  margin-bottom: 38px; }

.productSection.extensionSection .ui.grid.ecoSystem {
  padding-top: 14px; }

.roadMapOuter {
  margin-top: 150px; }

.titleWrap {
  margin-bottom: 0px; }

.titleWrap h2 {
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 107.5%;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff !important; }

.titleWrap p {
  font-family: Lexend;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8;
  max-width: 560px;
  margin: 0 auto; }

.roadMapLeft {
  width: 45%;
  position: relative;
  float: left; }

.roadMapCenter {
  display: inline-block;
  width: 10%;
  height: 1600px;
  margin-top: 145px; }

.roadMapRight {
  width: 45%;
  float: right; }

.roadMapWrap {
  display: inline-block;
  width: 100%; }

.dateLeftWrap {
  display: flex;
  justify-content: flex-end; }

.dateyearLeft {
  background: #362a34;
  position: relative;
  border-radius: 12px;
  display: inline-block;
  padding: 12px; }

.dateyearLeft h4 {
  font-family: Lexend;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8; }

.dateyearLeft::after,
.select::after {
  display: none; }

.dateyearLeft p {
  font-family: Lexend !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 25px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8; }

.descMain {
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }

.descInner p {
  background: #1e1a1d;
  color: #fff !important;
  display: inline-block;
  padding: 29px 25px;
  border-radius: 12px;
  font-weight: 300;
  margin-bottom: 0px; }

.descInner {
  display: flex;
  width: fit-content;
  flex-direction: column; }

.descInner span {
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #c4c4c4; }

.roadMapLeft::after {
  opacity: 0;
  content: "";
  position: absolute;
  right: -12%;
  width: 4px;
  height: 86%;
  background: linear-gradient(180deg, #120510 0%, #51454e 5.44%, #544851 40.38%, #554952 85.3%, #1c0f19 100%);
  top: 150px; }

.dateLeftWrap .dateyearLeft.rightDesc p {
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.01em;
  color: #ffffff !important; }

.dateyearLeft.rightDesc {
  padding: 25px; }

.dateyearRight h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8; }

.descRight {
  margin-bottom: 0px; }

.dateyearRight {
  background: #362a34;
  position: relative;
  border-radius: 12px;
  display: inline-block;
  padding: 12px 15px; }

.dateyearRight p {
  font-family: Lexend !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 25px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff !important;
  opacity: 0.8; }

.mb-60 {
  margin-bottom: 60px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-75 {
  margin-bottom: 75px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-100 {
  margin-bottom: 100px; }

.mb-110 {
  margin-bottom: 110px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-130 {
  margin-bottom: 130px; }

.descInner.listing li {
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  opacity: 0.7;
  padding: 8px 0; }

.descInner.listing {
  background: #1e1a1d;
  border-radius: 12px;
  color: #fff;
  padding: 20px; }

.descInner.listing ul {
  margin-bottom: 0px; }

span.articleCount {
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #c4c4c4;
  padding-right: 30px; }

.dateyearLeft.rightDesc ul li {
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  opacity: 0.7;
  padding: 5px 0; }

.dateyearLeft.rightDesc ul {
  margin-bottom: 0px; }

.darkcolor {
  background: #1e1a1d; }

/* road map for mobile */
.roadMapMobile {
  margin: auto;
  text-align: center; }

.monthdate {
  background: #362a34;
  padding: 15px;
  margin-right: 10px;
  border-radius: 10px; }

.dateTxtInner {
  display: flex;
  background: #1e1a1d;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px; }

.dateTxt {
  width: auto;
  display: inline-block; }

.roadMapMobile {
  margin: auto;
  text-align: center; }

.monthContent p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  margin-bottom: 0px; }

.monthContent span {
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.015em;
  color: #c4c4c4;
  float: right; }

.monthdate h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 0.8; }

.monthContent ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  margin-bottom: 0px;
  text-align: left; }

.roadMapMobile {
  display: none; }

.HomePageFull .process.inactive::after,
.HomePageFull .process.active::before {
  display: none; }

.HomePageFull .process,
.homepage.HomePageFull .process.active {
  height: 230px !important;
  background: none !important; }

/* road map for mobile */
.descRight.launch.mb-50 {
  margin-bottom: 85px; }

.timeline {
  padding: 0 43px 0px 43px; }
  .timeline ul {
    padding: 0;
    width: 22px;
    position: relative;
    margin: 0 auto; }
  .timeline .default-line {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -2px;
    width: 4px;
    background: linear-gradient(180deg, #120510 0%, #51454e 5.44%, #544851 40.38%, #554952 85.3%, #1c0f19 100%);
    height: calc(100% - 80px); }
  .timeline .timelineEndUpcoming {
    width: 115px;
    text-align: center;
    position: relative;
    left: -40px;
    margin-top: -80px; }
  .timeline .timelineEndUpcoming span {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
    backdrop-filter: blur(220.419px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    display: block;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; }
  .timeline .timelineEndUpcoming b {
    color: #ffffff;
    opacity: 0.6;
    display: block;
    margin: 13px 0 5px; }
  .timeline .timelineEndUpcoming i {
    color: #ffffff;
    opacity: 0.3;
    font-style: normal; }
  .timeline .draw-line {
    width: 4px;
    height: 0;
    position: absolute;
    left: calc(50% - 2px);
    background: #20a474; }
  .timeline ul li {
    list-style-type: none;
    position: relative;
    width: 4px;
    margin: 0 auto;
    height: 350px;
    background: transparent; }
    .timeline ul li.in-view::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-image: url(/img/tick.png) !important;
      background: #20a474;
      background-size: 14px 14px;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.125s ease-in-out, background-color 0.2s ease-out, color 0.1s ease-out, border 0.1s ease-out; }
    .timeline ul li::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: inherit;
      background: #bdc3c7;
      transition: all 0.4s ease-in-out;
      border: 6px solid #fa2cab; }

.dateLeftWrapOuter {
  min-height: 348px; }

.timeline ul li.in-view::before {
  border: none; }

li.Activein-view.in-view::before,
.roadMapLine .timeline ul li.Activein-view.in-view::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(/img/tick.png) !important;
  background: #20a474;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.125s ease-in-out, background-color 0.2s ease-out, color 0.1s ease-out, border 0.1s ease-out; }

.timeline ul li.Activein-view::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-image: url(/img/tick.png) !important;
  background: #20a474;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.125s ease-in-out, background-color 0.2s ease-out, color 0.1s ease-out, border 0.1s ease-out;
  z-index: 1; }

.HomePageFull .bg-gradient-loop.feedback_wrap {
  padding-top: 70px; }

.three.column.row.join-community.start-learning {
  margin-top: 40px; }

.cz-support.cz-support-set .row.join-community.start-learning h2 {
  margin-bottom: 0 !important; }

.TokenWrp h2.bottomImg::after {
  display: none; }

.homeCounter .counterWrap {
  position: relative;
  bottom: 55px; }

.dateLeftWrap {
  display: flex;
  justify-content: flex-end; }

.dateLeftWrapOuter {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.timeline ul li.Activein-view {
  background: #20a474;
  width: 5px;
  left: 2px; }

.iconTxtWrp img.ui.image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover; }

.left-animation-div .dateyearLeft,
.left-animation-div .descRight,
.left-animation-div .dateRightWrap,
.left-animation-div .dateTxtInner {
  opacity: 1; }

.dateyearLeft,
.descRight,
.dateRightWrap,
.dateTxtInner {
  opacity: 0;
  transition: 0.5s all ease; }

.iconsInner .updateData {
  font-size: 9.91119px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400; }

.updateData span {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  display: inline-block;
  background-color: #20a474;
  border: 4px solid rgba(10, 36, 27, 0.5);
  display: inline-flex;
  margin-right: 5px; }

.headerBtnBox a.btn.headerBtn.px-3.mb-2.mb-lg-0:hover::after {
  content: "";
  background: linear-gradient(90.24deg, rgba(250, 44, 171, 0.64) -1.6%, rgba(155, 105, 252, 0.64) 99.79%);
  filter: blur(40px);
  border-radius: 12px;
  position: absolute;
  top: 30px;
  left: 0;
  width: 275px;
  height: 55px; }

.headerBtnBox a.btn.headerBtn:hover {
  color: #fff;
  transform: translateY(-5px); }

.headerBtnBox a.btn.headerBtn {
  width: auto !important;
  height: 55px;
  position: relative;
  transition: 0.5s all ease;
  margin-right: 10px; }

.productSection a,
.marketSection a {
  text-decoration: none; }

.ComingSoon h1 {
  color: #fff;
  text-align: center;
  font-size: 500%; }

.ToolTipOnHoverInner h1 {
  display: inline-block;
  background: #fff;
  padding: 10px 15px; }

.ToolTipOnHover {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  transition: 0.5s all ease; }

.ToolTipOnHoverInner h1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 15px;
  height: 15px;
  background-color: #fff;
  bottom: -7px;
  transform: rotate(45deg); }

.marketInner:hover .ToolTipOnHover {
  opacity: 1; }

/* jk Css End */
@media only screen and (max-width: 1250px) {
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 15px; } }

@media only screen and (max-width: 1199px) {
  h1.ui.header.headerContent {
    font-size: 50px;
    line-height: 100%; }
  .mainSection .ui.list .item a {
    font-size: 16px; }
  button.ui.button.btn.headerBtn {
    font-size: 16px;
    height: 50px; }
  .app-body .topIcons h3 {
    font-size: 20px; }
  .app-body .imgTxt p {
    font-size: 13px; }
  .app-body .rankHigh h5,
  .app-body .homepage .rankHigh p {
    font-size: 16px;
    line-height: 20px; }
  .communityBox h2,
  .marketInner h2 {
    font-size: 28px !important;
    line-height: 100%;
    margin-left: 0px;
    margin-top: 0; }
  .communityBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
  .communityBox.extensionInner button.ui.button.btn.headerBtn.px-3 {
    width: fit-content; }
  .roadMapOuter {
    margin-top: 150px; }
  .mainSection .ui.grid.hero_section {
    padding-bottom: 100px; }
  .productSection h2 {
    font-size: 36px;
    max-width: 650px; }
  .loop-text h2 {
    font-size: 36px !important;
    line-height: 40px !important; }
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    margin-right: 5px !important; }
  .main_action_btn.cz-home-set-icons {
    margin-top: 0; }
  span.articleCount {
    padding-right: 50px; }
  .WalletContent {
    margin: 0; }
  .nftImg img.ui.image {
    top: 0; } }

@media only screen and (max-width: 1150px) {
  .descInner.listing li,
  .dateyearLeft.rightDesc ul li {
    font-size: 14px !important; }
  .descRight.launch.mb-50 {
    margin-bottom: 90px; }
  .select::after {
    right: -60px; } }

@media only screen and (max-width: 1100px) {
  .select::after {
    right: -56px; }
  .dateyearLeft::after,
  .unselect .dateyearLeft::after {
    right: -65px; } }

@media only screen and (max-width: 1080px) {
  #mainNav .navbar-nav .nav-item .nav-link span {
    padding: 5px !important; }
  .select::after {
    right: -12%; }
  .dateyearLeft::after,
  .unselect .dateyearLeft::after {
    right: -62px; }
  span.articleCount {
    padding-right: 20px; } }

@media (max-width: 1024px) {
  .dateyearLeft.rightDesc ul li,
  .descInner.listing li {
    font-size: 12px !important; }
  span.articleCount {
    padding-right: 50px; }
  .homebg_section.HomeTopBanner .mainSection {
    min-height: 750px; }
  .homeCounter .counterWrap {
    bottom: 0px; }
  .counterWrap ul li::after {
    right: -20%; } }

@media (max-width: 992px) {
  .integrationTab .integrationImg {
    display: none; }
  .appDownloadSection {
    padding-bottom: 0; }
  section.bg-gradient-loop.appDownload {
    padding-top: 0; }
  .col-sm-12.col-md-12.col-lg-6.pt-4.frameYield {
    margin-top: 3em; }
  .community_Tab.partners .innerboxx {
    margin-top: 4em; }
  section.bg-gradient-loop.community_Tab.partners {
    padding-bottom: 0; }
  .loopwindow {
    padding-bottom: 0; }
  .analitycsText {
    margin-top: 50px;
    margin-bottom: 0px; }
  .analitycsText span {
    display: none; }
  section {
    border: none; }
  .analitycsText .innerBox {
    padding: 30px 0px; }
  .masthead .col-lg-10.offset-md-1.text-center img.line {
    width: 100%; }
  .homebg {
    width: 100%; }
  .column.signupBox:last-child .mobileSignUpBox {
    margin-top: 50px !important; }
  .ui.grid.loopsignup {
    padding: 10px 0; }
  h1.ui.header.headerContent {
    font-size: 34px; }
  .dateyearLeft::after,
  .unselect .dateyearLeft::after {
    right: -60px; }
  .communityBox {
    padding: 30px 20px 0 20px; }
  .descInner.listing ul {
    padding-left: 0; }
  span.articleCount {
    padding-right: 60px; }
  .bg-gradient-loop.feedback_wrap .loop-text {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px; } }

@media (max-width: 991px) {
  .loop-text button {
    margin: 0 auto !important; }
  .partnerSection .ui.grid > .row > [class*="four wide"].column,
  .partnerSection .ui.grid > .column.row > [class*="twelve wide"].column,
  .homebg_section.HomeTopBanner
.partnerSection
.ui.grid
> [class*="two column"].row
> .column:first-child,
  .homebg_section.HomeTopBanner
.partnerSection
.ui.grid
> [class*="two column"].row
> .column:last-child {
    width: 100% !important;
    text-align: center; }
  .loop-text {
    text-align: center; }
  .partnerSection .titleWrap h2 {
    text-align: center; } }

@media (max-width: 920px) {
  .dateyearLeft::after,
  .unselect .dateyearLeft::after {
    right: -55px; }
  .descInner.listing {
    padding: 25px; }
  .dateyearLeft.rightDesc {
    padding: 20px; }
  .dateRightWrap.qfour.mb-110 {
    margin-bottom: 90px; }
  span.articleCount {
    padding-right: 40px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .homepage
.bg-gradient-loop.feedback_wrap
.ui.grid
> .row
> [class*="twelve wide tablet"].column {
    width: 100% !important; } }

@media (max-width: 900px) {
  .ui.grid.hero_section .headerBtnBox.position-relative {
    max-width: 100% !important; }
  .homebg_section.HomeTopBanner
.ui.grid.homeText
> [class*="two column"].row
> .column:first-child,
  .homebg_section.HomeTopBanner
.ui.grid.homeText
> [class*="two column"].row
> .column:nth-child(2) {
    width: 100% !important; }
  .homebg_section.HomeTopBanner h1.ui.header.headerContent,
  .homebg_section.HomeTopBanner .ui.list {
    text-align: center; }
  .homebg_section.HomeTopBanner .headerBtnBox.position-relative {
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center !important; }
  .app-body .homebg_section.HomeTopBanner .iconsInner {
    float: none;
    margin: 25px auto; }
  .iconsInner .d-flex.align-items-center.justify-content-between {
    padding: 0 15px; } }

@media (max-width: 850px) {
  .homebg_section.HomeTopBanner
.ui.grid.homeText
> [class*="two column"].row
> .column:nth-child(2) {
    left: 0; }
  .descInner.listing {
    padding: 24px; }
  .dateLeftWrap .dateyearLeft.rightDesc p {
    font-size: 14px !important; }
  .descInner.listing ul {
    padding-left: 10px; }
  .descInner.listing li {
    line-height: 22px; }
  span.articleCount {
    padding-right: 10px; }
  .timeline {
    padding: 0 40px 36px 40px; }
  .app-body .iconsInner {
    width: 96%; }
  .ui.grid > [class*="two column"].row > .column.homeCounter {
    width: 100% !important; }
  .counterWrap ul li::after {
    right: -60%; } }

@media (max-width: 800px) {
  .dateyearLeft::after,
  .unselect .dateyearLeft::after {
    right: -50px; } }

@media (max-width: 768px) {
  .homepage .homebg_section .mainSection {
    min-height: auto; }
  .app-body .homepage .ui.grid {
    display: flex;
    justify-content: space-around; }
  .nftImg img.ui.image {
    left: 0px; }
  .communityBox {
    padding: 20px 20px 0 20px; }
  .descInner.listing li {
    line-height: 18px; }
  .homebg_section.HomeTopBanner .mainSection {
    display: inline-block;
    height: auto !important;
    width: 100%; } }

@media (max-width: 767px) {
  .column.leftEco.leftEcoSecond {
    max-width: 100% !important; }
  .mainSection .ui.grid > [class*="two column"].row > .column {
    width: 100% !important; }
  .app-body .iconsInner {
    float: none;
    margin: 40px auto 0;
    padding: 0px; }
  .app-body .iconProgress ul {
    padding: 14px 10px; }
  .ecoSystem.ui.grid > [class*="two column"].row > .column {
    width: 100% !important;
    margin: 15px 0; }
  .nftImg {
    display: flex;
    justify-content: flex-end; }
  .productSection .ecoSystem {
    padding-top: 20px; }
  .roadMapMobile {
    display: none; }
  .column.nftImgRight {
    margin-bottom: 0px !important; }
  .titleWrap h2 {
    font-size: 30px; }
  .titleWrap p {
    font-size: 14px; }
  .blockText {
    padding: 10px; }
  .blockText h3 {
    font-size: 20px; }
  .row.join-community .blockText p {
    font-size: 14px;
    line-height: 20px; }
  .row.join-community h2,
  .appDownload h2,
  .bg-gradient-loop.team-section h2.ui.header {
    font-size: 36px; }
  h2.bottomImg::after {
    left: 0;
    right: 0;
    margin: auto; }
  .partnerSection {
    margin-bottom: 50px; }
  .timeline {
    padding: 160px 20px 35px 20px; }
  .roadMapLeft,
  .roadMapRight {
    float: right !important;
    width: 44% !important; }
  .roadMapCenter {
    float: left !important;
    width: 59px !important;
    margin-top: 0px !important; }
  .timeline ul li.Activein-view {
    width: 4px;
    left: 0; }
  .homeText h1.ui.header.headerContent,
  .mainSection .homeText .ui.list,
  .homeText .main_action_btn.cz-home-set-icons {
    text-align: center; }
  .HomeTopBanner .ui.grid.hero_section .headerBtnBox.position-relative {
    margin: 30px auto !important; }
  .roadMapWrap.roadMapDesktop {
    display: block !important; }
  .RoadMapMView {
    width: calc(100% - 60px);
    float: right; }
  .roadMapDesktop .roadMapRight,
  .roadMapDesktop .roadMapLeft {
    display: none; }
  .roadMapOuter.normal {
    display: inline-block;
    width: 100%; }
  .roadMapWrap.roadMapDesktop .roadMapMobile {
    display: block !important; }
  .roadMapMobile {
    display: none !important; }
  .counterWrap ul li::after {
    right: -50%; }
  .roadMapMobile .roadWrap {
    display: flex;
    height: 350px;
    align-items: center; }
  .timeline ul li {
    height: 350px; }
  .communityBox .bottomImg img.ui.image {
    border-radius: 10px; }
  .roadMapOuter.normal .titleWrap {
    margin-bottom: 0px;
    position: relative;
    top: 30px; }
  .HomeTopBanner
.ui.grid.hero_section
.headerBtnBox.position-relative
a.btn.headerBtn.px-3.mb-2.mb-lg-0 {
    text-align: center;
    justify-content: center; }
  .mainSection .ui.grid.hero_section {
    padding-bottom: 0px; } }

@media screen and (max-width: 600px) {
  .homebg_section .mainSection {
    background-image: none; }
  h2.bottomImg::after {
    width: 250px; }
  .ui.grid
> [class*="three column"].join-community.start-learning.row
> .column {
    width: 100% !important; }
  .blockWrap img.ui.image {
    width: 100%; }
  .blockWrap .blockText img.ui.image {
    width: auto; }
  .blockWrap {
    height: auto;
    margin: 15px 0; }
  .partnerSection .ui.grid > [class*="three column"].row > .column {
    width: 100% !important; }
  .partnerSection .ui.segment {
    margin: 10px 0 !important; }
  .partnerSection
.ui.grid
> [class*="three column"].row
> .column
.ui.segment:last-child {
    margin-bottom: 0px !important; }
  .counterWrap ul li p,
  .counterWrap ul {
    display: block; }
  .counterWrap ul li {
    display: inline-block !important;
    width: 49% !important;
    text-align: center;
    margin-bottom: 20px; }
  div#shiva {
    display: block;
    text-align: center;
    margin-bottom: 10px; }
  .counterWrap ul li::after {
    right: 0px; }
  .counterWrap {
    margin-top: 30px; }
  .loop-text h2,
  .bg-gradient-loop.team-section h2.ui.header {
    font-size: 28px !important;
    line-height: 40px !important; }
  .bg-gradient-loop.footer_wrap .tesi-design {
    padding: 40px 15px !important;
    margin: 0px; } }

@media only screen and (max-width: 480px) {
  .monthdate {
    transform: rotate(-90deg);
    width: 40px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    justify-content: center;
    background: transparent;
    position: relative;
    left: -5px; }
    .monthdate h4 {
      margin: 0 5px; }
  button.ui.button.joinBtn {
    font-size: 12px !important; }
  .groupBtns button.ui.button.joinBtn {
    width: 100px !important; }
  .groupBtns button.ui.button.joinBtn.quest {
    width: 120px !important; }
  .groupBtns button.ui.button.joinBtn.learnBtn {
    width: 80px !important; }
  .app-body main.homepage .ui.container {
    padding: 0px; }
  .app-body .rankHigh h5,
  .app-body .homepage .rankHigh p {
    font-size: 12px; }
  .iconTxtWrp img {
    width: 30px; }
  .mainSection .ui.grid.hero_section {
    padding-bottom: 0px; }
  .productSection h2 {
    font-size: 24px; }
  .communityBox h2,
  .marketInner h2 {
    font-size: 22px !important; }
  .AppSection .marketInner {
    padding: 10px 10px 0 10px; }
  .imgTxt p,
  .app-body .imgTxt h5 {
    font-size: 12px; }
  h1.ui.header.headerContent {
    font-size: 26px;
    text-align: center;
    letter-spacing: normal; }
  .productSection .ecoSystem {
    padding-top: 0px; }
  .monthContent ul {
    padding-left: 12px;
    margin-bottom: 0px; }
  .monthContent ul li {
    font-size: 12px !important;
    line-height: 18px !important; }
  .monthdate {
    padding: 10px; }
  .dateTxtInner {
    padding: 10px; }
  .monthContent p {
    font-size: 12px !important;
    text-align: left; }
  .dateTxt {
    width: 95%;
    display: inline-block; }
  .mainSection button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
    width: auto !important; }
  h2.bottomLine::before {
    width: 230px; }
  .marketInner {
    padding: 10px 0 10px 10px; }
  .communityBox {
    padding: 20px 10px 10px 10px; }
  .groupBtns button.ui.button.joinBtn {
    margin-right: 5px; }
  .seedInner {
    padding-right: 10px; }
  .homepage .communityBox p,
  .marketInner p {
    font-size: 14px;
    line-height: 18px; }
  .roadMapOuter {
    margin-top: 50px; }
  .titleWrap {
    margin-bottom: 20px; }
  .community_Tab h2.ui.header,
  .appDownload h2,
  .financial_freedom h2.ui.header.integrationTabH2,
  .row.join-community h2,
  .row.exchange-section h2,
  .loop-text h2,
  .row.newsletter-section h2,
  .row.support-section h2,
  .partnerSection .titleWrap h2 {
    font-size: 24px !important; }
  .sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a {
    padding: 5px 7.5px !important; }
  .sidebar-wrapper .ui.segment.pushable .sidebar a,
  .sidebar-wrapper .ui.segment.pushable .sidebar button {
    font-size: 14px !important; }
  .HomePageFull .process,
  .homepage.HomePageFull .process.active {
    height: 182px !important; }
  .timeline ul li {
    height: 290px; }
  .app-body .iconsInner {
    width: 94%;
    padding: 5px; }
  .productSection h2.bottomLine::before {
    right: 0;
    left: 0;
    margin: auto;
    background-size: contain; }
  .timeline ul li.in-view:last-child {
    height: 50px; }
  .timeline .draw-line {
    width: 4px; }
  .loop-text button.ui.button.joinBtn {
    font-size: 16px !important;
    margin-left: auto;
    margin-right: auto; }
  .loop-text h6.ui.header.textGradient.integrationTabH6 {
    margin-bottom: 0; }
  .roadMapMobile .roadWrap {
    height: 290px; }
  .timeline {
    padding: 130px 20px 35px 20px; }
  .ImgBtm::before {
    width: 100px; }
  .tradeTerra h5 {
    text-align: center; }
  span.ImgBtm.Big::before {
    width: 225px; }
  .app-body ul.activehover::before {
    width: 20px;
    left: -20px; }
  .app-body ul.activehover::after {
    width: 20px;
    right: -20px; } }

@media (min-width: 576px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%;
    display: block; }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 12.5rem; } }

@media (min-width: 768px) {
  .masthead .masthead-device-mockup .circle {
    width: 60%; }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem; } }

@media (min-width: 992px) {
  .masthead .masthead-device-mockup .circle {
    width: 90%; }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem; }
  .masthead {
    height: 100vh; } }

@media (min-width: 1450px) {
  .masthead .masthead-device-mockup .circle {
    width: 75%; }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 15rem; }
  .ui.grid > [class*="two column"].row > .column.rightEco {
    width: 58% !important; }
  .ui.grid > [class*="two column"].row > .column.leftEco {
    width: 42% !important; } }

@media (min-width: 1400px) {
  .masthead .masthead-device-mockup .circle {
    width: 70%; }
  .masthead .masthead-device-mockup .device-wrapper {
    max-width: 17rem; } }

@media (max-width: 479px) {
  .homebg_section.HomeTopBanner .headerBtnBox.position-relative {
    flex-direction: column; }
  .homebg_section.HomeTopBanner .headerBtnBox.position-relative a {
    width: 280px !important; } }

@media (max-width: 350px) {
  .community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-reading::after {
    right: -12px; }
  .community_Tab.partners.performers_wrap
.markets_widget
.portfolio-table-row:first-child
td
.icon-box::before {
    left: -12px; }
  .modal.display-block.modal-team .modal-main > div * {
    transform: scale(0.95) translate(-1.2%); } }

.green-button {
  background-color: var(--green) !important;
  border: 1px solid var(--green);
  color: var(--back-color) !important;
  border-radius: 6px;
  width: auto;
  text-transform: uppercase;
  transition: all 200ms ease-in-out; }
  .green-button:hover {
    color: var(--text-color) !important;
    transition: all 200ms ease-in-out; }

.green-follow-button {
  background-color: var(--green) !important;
  border: 1px solid var(--green);
  color: var(--text-color) !important;
  border-radius: 6px;
  width: auto;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;
  /*
  &:hover:before {
    content:"Unfollow";
  }
  */ }
  .green-follow-button:hover {
    background-color: var(--red) !important;
    color: var(--text-color) !important;
    transition: all 200ms ease-in-out; }

.green-bordered-button,
.ui.button.green-bordered-button {
  background-color: transparent;
  border: 1px solid var(--green);
  color: var(--green) !important;
  border-radius: 6px;
  width: auto; }
  .green-bordered-button:hover,
  .ui.button.green-bordered-button:hover {
    background-color: var(--green);
    color: var(--page-back) !important; }

.greenButton,
.ui.button.greenButton {
  background-color: transparent;
  border: 1px solid var(--green);
  color: var(--green) !important;
  border-radius: 6px;
  width: auto; }
  .greenButton:hover,
  .ui.button.greenButton:hover {
    background-color: var(--green);
    color: var(--page-back) !important; }

.gray-rounded-button,
.ui.button.gray-rounded-button {
  background-color: var(--page-back);
  color: var(--icon-secondary);
  border-radius: 2rem; }

/* ==  card content borders === */
.ui.card .content {
  border: none; }

/* ======  Custom checkbox =========*/
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: transparent;
  border-color: var(--green); }

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: transparent;
  border-color: var(--green); }

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: transparent;
  border-color: var(--green); }

.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
  background: transparent;
  border-color: var(--green); }

.ui.checkbox .box:before,
.ui.checkbox label:before {
  background: transparent;
  border-color: var(--green); }

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: var(--green) !important; }

/* =========================== */
body {
  background-color: #03011e !important; }

h2 {
  font-weight: 600;
  font-size: 64px !important;
  line-height: 64px;
  /* identical to box height, or 100% */
  text-align: center;
  letter-spacing: -0.03em; }

.mce-content-body {
  min-height: 200px !important;
  padding: 10px;
  font-weight: 400 !important; }

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: rgba(255, 255, 255, 0.3) !important;
  padding-left: 10px; }

div.tox-tinymce--toolbar-sticky-on div.tox-editor-header {
  top: 40px !important; }

* {
  font-family: 'Lexend', sans-serif !important; }

.bg-gradient-loop.loop_screen .ui.container .hero_section .center.aligned.row {
  margin: 0; }

.triger-conditions {
  position: relative; }

body header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  padding-top: 40px;
  z-index: 999;
  padding-bottom: 27px;
  background: transparent !important;
  backdrop-filter: none !important; }

.submitConditionTooltip {
  position: absolute;
  bottom: 50px;
  width: 250px;
  right: 0;
  z-index: 1;
  background: #2b2b2b;
  border-radius: 6px; }

.hideTooltip {
  display: none; }

.submitConditionTooltip p {
  margin: 0;
  padding: 5px 15px;
  font-weight: normal; }

.submitConditionTooltip:before {
  width: 10px;
  height: 10px;
  content: '';
  background: #2b2b2b;
  position: absolute;
  bottom: -6px;
  right: 70px;
  transform: rotate(45deg); }

.modal-delete section.modal-main span {
  width: 26px;
  height: 26px;
  background: #e40092;
  display: block;
  font-size: 25px;
  line-height: 22px;
  border-radius: 100px;
  font-weight: normal;
  color: white;
  transform: rotate(45deg);
  position: absolute;
  right: -12px;
  top: -10px;
  cursor: pointer; }

.copied {
  cursor: pointer;
  position: relative; }

.copied b {
  background: black;
  border-radius: 20px;
  position: absolute;
  top: -28px;
  z-index: 1;
  color: white;
  padding: 2px 10px !important;
  left: -12px;
  font-size: 12px;
  font-weight: normal; }

.copiedTooltip {
  display: none !important; }

.sixteen.wide.column.cz-write-message span {
  color: white; }

.joinBtn {
  background: #C83E93 !important;
  width: 100%;
  /* border-radius: 10px !important; */
  color: #fff !important;
  font-weight: 600;
  font-size: 13px !important;
  /* background: transparent !important; */
  border: 2px solid #C83E93 !important;
  border-radius: 8px;
  padding: 10px 10px !important; }
  .joinBtn:hover {
    background: #c83e93a7 !important; }
  .joinBtn:active {
    transform: scale(0.9); }

.editArticleLoader .ui.active.loader {
  margin-left: 120px; }

.ui.grid.hero_section h6 {
  text-align: center;
  letter-spacing: -.02em;
  color: #fff;
  font-weight: 600;
  font-size: 56px;
  display: block;
  max-width: 920px;
  margin: 0 auto 10px;
  position: relative;
  z-index: 1; }

.ui.grid.page-sidebar.cztoggle-menu-web.cz-sidebar-expanded button.ui.button.sidebarToggle {
  width: 36px;
  left: -8px; }

button.ui.button.sidebarToggle {
  left: 0; }

.ui.inverted.link.list.sidebar-list div.item {
  min-height: 59.09px; }

.ui.inverted.link.list.sidebar-list a.item.nav-link span {
  white-space: nowrap; }

.ui.grid.page-sidebar.cztoggle-menu-web.cz-sidebar-expanded button.ui.button.sidebarToggle img.ui.image {
  transform: rotate(180deg); }

header,
.page-sidebar,
.page-sidebar *,
.button.ui.button.sidebarToggle {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out; }

.ui.grid.page-sidebar.cztoggle-menu-web.cz-sidebar-expanded {
  width: 65px !important;
  margin-left: 0 !important; }

.ui.grid.page-sidebar.cztoggle-menu-web .ui.inverted.link.list.sidebar-list {
  overflow: hidden; }

.ui.grid.page-sidebar.cztoggle-menu-web.cz-sidebar-expanded .ui.inverted.link.list.sidebar-list {
  border-radius: 0px 15px 15px 0 !important;
  width: 55px; }

.cz-sidebar-expanded .ui.inverted.link.list.sidebar-list a.item.nav-link {
  padding-left: 15px !important; }

.cz-sidebar-expanded .sidebarLogo {
  max-width: 80% !important; }

.three.wide.column.cz-dek-top-button .social-controls-red {
  padding: 0 !important; }

.three.wide.column.cz-dek-top-button .social-controls {
  padding: 0 !important; }

.ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .row.summary .six.wide.column.description {
  margin: 5px 0 0 !important; }

.ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .ui.container.feed.cz-con-new .row.post-preview .eight.wide.column.post-preview-info.cz-new-card {
  padding: 0 20px; }

.ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .ui.container.feed.cz-con-new .row.post-preview .eight.wide.column.post-preview-info.cz-new-card .top.aligned.row .two.wide.column {
  margin-left: 0; }

.ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .ui.container.feed.cz-con-new .row.post-preview .eight.wide.column.post-preview-info.cz-new-card .middle.aligned.row {
  margin: 15px 0; }

.ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .ui.container.feed.cz-con-new .row.post-preview .eight.wide.column.post-preview-info.cz-new-card .middle.aligned.row h4.post-preview-title {
  margin-bottom: 15px !important; }

.three.wide.column.cz-dek-top-button .social-controls button {
  width: 100%;
  height: 100%;
  border: none !important;
  color: white;
  font-weight: normal; }

.three.wide.column.cz-dek-top-button .social-controls button span {
  height: 100%;
  border-radius: 5px;
  padding: 15px;
  background: #C83E93;
  position: relative;
  padding-left: 36px; }

.cz-moni-button {
  width: 50%;
  padding-left: 10px !important; }

.cz-feedSection {
  display: block;
  width: 100%;
  margin: auto;
  margin-left: 15%;
  margin-right: 15%; }
  .cz-feedSection h1 {
    color: white; }

.cz-moni-button button.ui.circular.icon.right.floated.button {
  width: 100%;
  background: #c83e93 !important;
  border: none !important;
  color: white;
  font-weight: normal;
  min-width: auto; }

.tokenGraph {
  background: #1B1B1B;
  border-radius: 10px;
  margin-bottom: 80px;
  margin-top: 80px; }

.tokenGraph canvas {
  width: 100% !important;
  height: 400px !important; }

.cz-moni-button button.ui.circular.icon.right.floated.button span {
  display: flex;
  align-items: center;
  color: #000; }

.three.wide.column.cz-dek-top-button .social-controls-red button {
  background: #e245a7 !important; }

.three.wide.column.cz-dek-top-button .social-controls-red button span {
  color: black; }

.three.wide.column.cz-dek-top-button .social-controls-red button span b {
  color: white; }

.cz-moni-button button.ui.circular.icon.right.floated.button span b {
  margin-right: 10px !important;
  color: white;
  font-size: 40px; }

.cz-moni-button .social-controls {
  padding-left: 10px !important; }

.ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .sixteen.wide.column .row.summary .right.floated.sixteen.wide.column.stats .three.wide.column.cz-dek-top-button {
  padding: 0 !important;
  position: absolute;
  right: 20px;
  bottom: 7px; }

.three.wide.column.cz-dek-top-button .social-controls button span b {
  color: black;
  font-size: 40px;
  font-weight: 300;
  line-height: 0;
  position: absolute;
  left: 15px;
  top: 20px; }

.ui.grid.hero_section .headerBtnBox.position-relative a.tutorialLink button {
  width: auto !important; }

.ui.grid.hero_section .headerBtnBox.position-relative {
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: 30px auto; }

button.ui.button.cancel-button {
  color: white !important; }

.post-author.cz-post-mobile-only b:first-child a:last-child {
  float: left;
  width: calc(100% - 52px); }

.add-comment .ui.positive.message {
  background: none;
  box-shadow: none !important;
  padding: 0;
  text-align: center; }

.add-comment .ui.positive.message .header {
  color: #c83e93; }

.post-author.cz-post-mobile-only b:first-child .avatar.circular.image {
  float: left;
  margin: 0; }

.cz-mob-new-follow {
  display: none; }

/*
.sixteen.wide.column.cz-write-message .post-date .ui.divider {
  display: none;
}
*/
.cz-feedSection.tokenGraph.person-detialing {
  margin-bottom: 0;
  margin-top: 0;
  padding: 15px 0; }

.all-cz-socials span {
  color: #C83E93;
  display: flex;
  align-items: center;
  margin-right: 30px; }

.cz-feedSection.tokenGraph.person-detialing .four.wide.column.avatar.cz-profile-avtar .circle-border {
  margin-left: 25px; }

.two-butons-folder {
  position: absolute;
  right: 0;
  display: flex;
  top: 0; }

.cz-feedSection.tokenGraph.person-detialing.only-mobile-small {
  display: none; }

.two-butons-folder button {
  background: #0998B9 !important;
  border-radius: 8px !important;
  padding: 9px !important; }

.two-butons-folder button:last-child {
  background: #C83E93 !important;
  width: 129px;
  margin-left: 8px; }

.cz-dummy-text-profile p {
  color: #999;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 25px; }

.all-cz-socials span img {
  margin-right: 7px; }

.all-cz-socials {
  display: flex;
  align-items: center;
  margin: 10px 0; }

.cz-feedSection.tokenGraph.person-detialing + .cz-feedSection.tokenGraph {
  margin-top: 30px; }

.editImage {
  max-width: 893px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px; }
  .editImage button {
    color: white !important; }

.cz-feedSection.tokenGraph.person-detialing .twelve.wide.column.description.cz-decription span.name {
  color: white;
  text-transform: uppercase; }

.cz-feedSection.tokenGraph.person-detialing .twelve.wide.column.description.cz-decription span.nick {
  color: #999;
  text-transform: uppercase; }

.cz-feedSection.tokenGraph.person-detialing .twelve.wide.column.description.cz-decription .rank,
.cz-feedSection.tokenGraph.person-detialing .twelve.wide.column.description.cz-decription .rank * {
  color: white;
  font-weight: 300 !important; }

.cz-feedSection.tokenGraph.person-detialing .cz-folow-counts {
  display: none; }

span.title.cz-up-down-green {
  color: #32FE9A !important;
  position: relative;
  margin-left: 20px; }

span.title.cz-up-down-green:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid #31f394;
  left: -17px;
  top: 7px; }

.sixteen.wide.column.cz-write-message .post-author {
  margin-top: 15px; }

.sixteen.wide.column.cz-write-message .post-author span {
  font-weight: 300; }

.sidebar-wrapper .sidebar.menu {
  position: absolute;
  height: 100%;
  background: #090817;
  width: 264px !important;
  padding-top: 15px;
  border-right: 5px solid rgba(238, 238, 238, 0.14902); }

.cz-mobile-menu .app-body {
  filter: blur(3px);
  -webkit-filter: blur(3px); }

.sidebarVisible.sidebar-wrapper .sidebar.menu span.menuText {
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 0 0 15px;
  margin-bottom: 80px; }

.sidebarVisible.sidebar-wrapper .sidebar.menu p.closeBtn {
  position: absolute;
  right: 50px;
  width: 50px;
  height: 41px;
  top: 5px;
  color: #ff000000;
  cursor: pointer; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a a {
  padding-top: 0;
  padding-bottom: 0; }

/* .sidebarVisible.sidebar-wrapper .sidebar.menu p.closeBtn:before {
  content: '+';
  color: white;
  width: 100%;
  height: 100%;
  left: 3px;
  position: absolute;
  transform: rotate(45deg);
  text-align: center;
  top: -3px;
  font-size: 50px;
  font-weight: 300;
}
 */
.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list {
  float: left;
  width: 100%;
  padding: 0; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a {
  text-align: left;
  z-index: 2;
  text-decoration: none;
  font-weight: 700;
  background: no-repeat;
  padding: 15px 7.5px;
  clear: both; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a a {
  padding-top: 0;
  padding-bottom: 0; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a::before {
  display: none; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list a:hover {
  color: #C83E93 !important; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list .cz-expamd-main {
  position: relative;
  border-top: 1px solid #2b2a37;
  float: left;
  width: 100%;
  display: flex;
  align-items: center; }

.sidebarVisible.sidebar-wrapper .sidebar.menu .row .link.list .cz-expamd-main > a {
  float: left; }

.cz-expanded-items {
  float: left;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  -ms-transition: max-height 0.3s;
  -o-transition: max-height 0.3s;
  transition: max-height 0.3s;
  overflow: hidden;
  border-bottom: 1px solid #2b2a37; }

.cz-expanded-itemsActive {
  max-height: 1000px; }

.eyePosition {
  position: absolute;
  margin-top: 12px;
  right: 30px; }

.cz-expanded-items .item {
  padding: 0;
  width: 100%; }

.cz-expanded-items a {
  float: left;
  width: 100%;
  display: flex;
  align-items: center; }

.cz-expanded-items .item {
  float: left;
  width: 100%;
  padding: 0 !important; }

.cz-expanded-items a img.ui.image {
  width: 24px;
  margin-right: 12px; }

.cz-expanded-items a i.ui.image {
  width: 24px;
  margin-right: 12px;
  font-size: 17px;
  height: 24px; }

.cz-expanded-items a span span.unseenCount {
  position: absolute !important;
  left: 18px;
  font-size: 10px;
  top: -7px;
  color: #c83e93;
  font-weight: 900; }

.cz-expanded-items a span {
  font-weight: 500;
  color: #9BB0CF;
  background: transparent; }

.cz-expand span {
  cursor: pointer; }

.cz-sidebar-login a a {
  display: flex;
  align-items: center;
  padding: 0 !important; }

.cz-sidebar-login a a img.ui.image {
  margin-right: 10px; }

.cz-sidebar-login a a span {
  font-weight: 500;
  color: #9BB0CF; }

.cz-expanded-items a,
.cz-sidebar-login a {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.sidebarVisible.sidebar-wrapper.cz-mobile-menu .ui.inverted.segment.sidebar-main {
  padding: 0; }

.sidebarVisible.sidebar-wrapper.cz-mobile-menu .ui.inverted.segment.sidebar-main {
  padding: 0; }

.sidebarVisible.sidebar-wrapper.cz-mobile-menu .ui.inverted.segment.sidebar-main .ui.inverted.segment.controls-panel-wrapper {
  margin: 0;
  padding: 0; }

.sidebarVisible.sidebar-wrapper.cz-mobile-menu .ui.inverted.segment.sidebar-main .ui.inverted.segment.controls-panel-wrapper .row {
  padding: 0;
  margin: 0; }

.leaderboard.cz-leaderboard {
  margin: 0 auto;
  width: 100%;
  padding-top: 0 !important;
  max-width: calc(100% - 500px);
  padding: 0; }

.cz-tokens-mobile > div > div {
  display: flex; }

.cz-tokens-mobile .cz-tokens-mobile-td:first-child button {
  background: #2d2d2d;
  width: 24px;
  height: 24px;
  padding: 0; }

.cz-tokens-mobile > div > div .cz-tokens-mobile-td.cz-left-td:nth-child(2) .ui.grid {
  padding-left: 15px; }

.dataLoader {
  left: 56%;
  right: 44%; }

.ui.grid.settings.cz-settings form.ui.form > .field {
  display: flex;
  position: relative; }

.ui.grid.settings.cz-settings form.ui.form > .field label {
  width: 150px;
  padding-top: 8px; }

.ui.grid.settings.cz-settings input:focus,
.ui.grid.settings.cz-settings textarea:focus,
.ui.grid.settings.cz-settings input,
.ui.grid.settings.cz-settings textarea {
  border: none !important; }

.ui.grid.settings.cz-settings form.ui.form > .field .ui.grid {
  width: 100%; }

.ui.grid.settings.cz-settings form.ui.form > .field .field {
  width: 100%;
  background: #1313136b;
  border-radius: 6px; }

img.ui.image.icon-copy {
  position: absolute;
  left: 130px;
  top: 9px; }

.ui.grid.settings.cz-settings form.ui.form > .field .ui.grid .eight.wide.column + span {
  padding: 5px 0 0; }

.ui.container.cz-profile-container {
  margin-bottom: 20px; }

.ui.grid.settings.cz-settings form.ui.form > .field {
  margin-bottom: 25px; }

span.image-reolution {
  padding: 0 !important;
  margin-bottom: 10px; }

.ui.grid.settings.cz-settings form.ui.form > .field img + .field input#ref {
  padding-left: 38px;
  color: #03c2ef !important; }

.ui.grid.settings.cz-settings form.ui.form > .field .ui.grid .eight.wide.column {
  padding: 0; }

.ui.grid.settings.cz-settings form.ui.form .field.cz-referal-link {
  justify-content: flex-end; }

.ui.grid.settings.cz-settings form.ui.form .field.cz-referal-link div {
  color: #01CDFD !important; }

.cz-tokens-mobile > div > div .cz-tokens-mobile-td.cz-left-td:nth-child(2) button {
  background: #2d2d2d !important;
  border: none !important;
  border-radius: 5px;
  padding: 7px 15px;
  color: #C83E93 !important;
  color: var(--pink) !important;
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px; }

.cz-tokens-mobile > div > div:nth-child(even) {
  background: #272727; }

.ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper {
  width: 100%;
  max-width: calc(100% - 270px); }

.ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper > .ui.grid.c-row {
  flex-direction: column-reverse; }

.five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile {
  display: none; }

.navbar a {
  text-decoration: none; }

.navbar span {
  padding: 8px;
  background: transparent;
  font-weight: 400;
  border-radius: 6px;
  font-size: 15px;
  color: #fff; }

.navbar .navbar_dropdown:hover {
  cursor: pointer !important; }

.navbar .navbar_dropdown:hover .navbar_dropdown_content {
  display: grid !important;
  cursor: pointer !important; }

.navbar .navbar_dropdown_content {
  display: none;
  position: absolute;
  transition: all 0.5s ease;
  background: #232240;
  border: 1px solid #383755;
  border-radius: 6px;
  font-size: 14px;
  min-width: 225px;
  list-style: none;
  text-align: left;
  margin-top: 5px;
  padding: 0; }
  .navbar .navbar_dropdown_content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    justify-content: flex-start !important; }
    .navbar .navbar_dropdown_content a:hover {
      background: #2F2C5C !important; }
  .navbar .navbar_dropdown_content .disabled a span {
    color: #767676 !important; }
  .navbar .navbar_dropdown_content li:first-child {
    display: block !important; }

#mainNav .navbar-nav .nav-item .nav-link:hover {
  color: #c83e93; }

#mainNav .navbar-nav .nav-item .nav-link {
  position: relative;
  padding: 0px;
  display: block; }

#mainNav .navbar-nav .nav-item .nav-link span {
  padding: 8px;
  background: transparent;
  font-weight: 500;
  border-radius: 6px;
  font-size: 16px; }

#mainNav .navbar-nav .nav-item .nav-link:hover span {
  color: #c83e93 !important; }

span.cz-sidebarActive {
  background: #1b1b1b8a !important; }

.ui.grid.settings.cz-settings .row {
  color: white;
  padding: 20px 20px; }

.ui.grid.settings.cz-settings * {
  color: white !important;
  font-weight: normal; }

.ui.grid.settings.cz-settings input#name,
.ui.grid.settings.cz-settings textarea {
  color: #dadada !important; }

.row.cz-set-set .post {
  width: 100%;
  padding: 15px !important;
  background: no-repeat !important; }
  .row.cz-set-set .post hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor !important;
    border: 0;
    opacity: 0.25; }
  .row.cz-set-set .post pre {
    padding: 20px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.2) !important; }
  .row.cz-set-set .post section {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .row.cz-set-set .post p {
    font-size: 17px !important;
    line-height: 24px !important;
    font-weight: normal !important; }

.row.cz-set-set .social-buttons-container {
  text-align: center;
  padding: 0 !important;
  width: 100%;
  height: auto; }

.row.cz-set-set .social-buttons-container span {
  color: white; }

.row.cz-set-set .ratings {
  width: 100%; }

.ui.grid.cz-new-js ul h6 {
  font-size: 15px;
  font-weight: 700; }

.ui.grid.cz-new-js ul li {
  font-weight: 300;
  font-size: 14px !important;
  color: #bbb;
  line-height: 22px;
  position: relative;
  padding-left: 15px;
  list-style: none; }

.ui.grid.cz-new-js ul li:first-child {
  color: white;
  padding: 0; }

.ui.grid.cz-new-js ul li:first-child::before {
  display: none; }

.ui.grid.cz-new-js ul li:before {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #bbb;
  content: '';
  left: 0;
  top: 9px;
  border-radius: 10px; }

.ui.star.rating .active.icon {
  color: #C83E93 !important;
  text-shadow: none !important; }

.ui.grid.articlePoints {
  border: none; }

.ratings .rating i.icon {
  color: #f5efef !important; }

.row.cz-set-set .ratings div {
  color: white;
  font-weight: normal;
  text-align: center;
  font-size: 15px;
  line-height: 19px; }

.row.cz-post-header-top-enter .post .ui.header {
  color: white; }

.row.cz-post-comments .comments-container {
  width: 100%; }

.row.cz-post-comments .comments-container .ui.attached.menu {
  background: #c83e93; }

.row.cz-post-comments .comments-container * {
  color: white; }

.row.cz-post-comments .comments-container textarea {
  color: white !important; }

.row.cz-post-comments .comments-container .ui.pagination.menu {
  background: #c83e93;
  width: 270px; }

.cz-single-post {
  padding-top: 25px;
  width: 75%;
  margin: 0 auto; }

.row.cz-post-comments .comments-container .ui.inverted.form div * iframe {
  width: 100% !important; }

.row.cz-set-set .social-buttons-container div svg {
  width: 25px;
  fill: #fff;
  display: block;
  text-align: center;
  margin: 0 auto; }

.ui.segment.my-feeds-loader {
  min-height: 250px;
  background: #00000033; }

header .ui.container .container a.navbar-brand.fw-bold img {
  width: 85px; }

.ui.grid.page-wrapper.cz-categry-parent {
  padding: 0; }

.cz-single-post .ui.container.feed.cz-con-new {
  margin: 0 auto !important; }

.cz-single-post > .ui.container {
  padding: 0 0 0 10px !important; }

.eight.wide.column.post-preview-info.cz-new-card + div {
  border-radius: 0; }

.eight.wide.column.post-preview-info.cz-new-card + div {
  border-radius: 0; }

.ui.container.feed.cz-con-new .ui.grid .row {
  position: relative; }

.ui.container.feed.cz-con-new .ui.grid .row .right.floated.three.wide.column.dark-text {
  position: absolute;
  right: 20px; }

.sixteen.wide.column.following-wrapper .ui.segment.active.tab .right.floated.content {
  max-width: initial !important;
  width: 90px;
  margin: 0;
  padding: 0;
  background: #2d2d2d61;
  height: 30px; }

.following-content .wide.column.following-wrapper .row.tabs-panel .ui.segment.active.tab .list .item .right.floated.content .ui.button {
  background: transparent;
  color: #c83e93;
  font-weight: normal;
  margin: 0;
  padding: 8px; }

.page404 {
  height: 100%;
  margin-top: 25%;
  color: #fff; }

.cz-single-post > .ui.container h6 {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 30px;
  color: white; }

button.ui.button.backCategory {
  background: no-repeat;
  color: white;
  position: relative;
  font-weight: normal;
  padding-left: 30px; }

.cz-single-post > .ui.container h6 b {
  text-transform: capitalize; }

button.ui.button.backCategory:after {
  content: '';
  width: 20px;
  background: white;
  height: 2px;
  position: absolute;
  left: 4px;
  top: 17px; }

button.ui.button.backCategory:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  left: 4px;
  transform: rotate(45deg);
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  margin-top: 2px; }

.row.cz-post-header-top-enter .post .row.post-preview .ui.grid {
  width: 100%;
  position: relative; }

.row.cz-post-header-top-enter .post .row.post-preview .ui.grid .six.wide.column.content-img {
  width: 50% !important;
  position: absolute;
  right: 0;
  background-size: cover; }

header.cz-header-fixed {
  padding: 0; }

.sub-header.cz-post-header-top .post-header h1 {
  color: white;
  font-size: 26px; }

.sidebar-wrapper .ui.segment.pushable .pusher {
  margin: 0; }

.sub-header.cz-post-header-top .post-categories a {
  font-weight: normal;
  color: white;
  text-decoration: none;
  background: #c83e93;
  padding: 4px 15px;
  border-radius: 4px; }

.sub-header.cz-post-header-top .post-categories .post-category {
  background: no-repeat;
  padding: 0 !important; }

.sub-header.cz-post-header-top .post-categories .post-category.curate-category {
  font-weight: normal;
  color: white;
  text-decoration: none;
  background: #3e8fd0;
  padding: 4px 15px !important;
  border-radius: 4px; }

.sub-header.cz-post-header-top .post-categories .post-category.curate-category i {
  font-size: 16px; }

.row.sub-header.cz-post-header-top .post-header {
  padding: 15px !important;
  display: block; }

.row.cz-post-header-top-enter .post {
  width: 100%;
  padding: 0; }

.row.cz-post-header-top-enter .post .ui.header {
  padding: 0 15px; }

.row.cz-post-header-top-enter .post .author-post {
  padding: 0; }

.row.cz-post-header-top-enter .post .author-post .ui.grid {
  margin: 0;
  padding: 5px 0px;
  width: 100%; }

.row.cz-post-header-top-enter .post .author-post .ui.grid .row.post-preview {
  display: block;
  margin-left: 0;
  float: left;
  width: 100%; }

.row.cz-post-header-top-enter .post .author-post .ui.grid .row.post-preview .six.wide.column.content-img {
  width: 50% !important;
  border-radius: 0;
  float: right; }

.row.cz-post-header-top-enter .post .author-post .ui.grid .row.post-preview .eight.wide.column.post-preview-info.cz-new-card {
  width: 50% !important;
  overflow: scroll;
  padding: 10px !important; }

.sub-header.cz-post-header-top .post-date .post-author b a {
  text-decoration: none;
  font-weight: normal; }

.fal {
  font-family: 'Font Awesome 5 Pro' !important; }

i.icon {
  font-family: Icons !important; }

.post-header.post-header__visual input[type="text"] {
  color: white !important; }

.row.editor * {
  color: white; }

.tox .tox-statusbar {
  background-color: transparent !important;
  border-top: 0px solid #000 !important; }

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: #171717 !important; }

.post-author * {
  color: #fff !important; }

.post-author {
  color: white !important; }

.post-author button.ui.tiny.disabled.right.floated.button.green-bordered-button {
  margin-top: 20px;
  background: #1b1b1b;
  border: none;
  color: #c83e93 !important;
  display: none; }

.post-author button.ui.tiny.disabled.right.floated.button.greenButton {
  margin-top: 20px;
  background: #1b1b1b;
  border: none;
  color: #c83e93 !important;
  display: none; }

.tox .tox-menu {
  background-color: #1b1b1b !important; }

.tox .tox-statusbar {
  background-color: transparent !important;
  border-top: 0px solid #000 !important; }

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-color: #171717 !important; }

.upload-link {
  background: #1b1b1b; }

.ui.grid.cz-new-js {
  max-width: 893px !important;
  margin: auto !important; }

.ui.grid.cz-new-js > .ui.container {
  background: #1b1b1b;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 0; }

.ui.grid.cz-new-js > .ui.container .row {
  display: block; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid {
  margin: 0;
  display: block; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .row {
  margin: 0; }

.main_action_btn.cz-home-set-icons .headerBtnBox.position-relative {
  margin: 0 !important; }

.prerendered {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 10px;
  margin-left: 10px;
  background: var(--green);
  border-radius: 50px;
  border: solid 1px #494949; }

.prerendered-false {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 10px;
  margin-left: 10px;
  background: #2E2E2E;
  border-radius: 50px;
  border: solid 1px #494949; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual {
  display: block; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual input[type="text"] {
  font-family: 'Lexend' !important;
  font-weight: normal !important; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bbb; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual input::-moz-placeholder {
  /* Firefox 19+ */
  color: #bbb; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual input:-ms-input-placeholder {
  /* IE 10+ */
  color: #bbb; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual input:-moz-placeholder {
  /* Firefox 18- */
  color: #bbb; }

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active,
.fr-popup .fr-buttons.fr-tabs {
  background: #3a3a3a !important; }

.fr-popup .fr-checkbox input:checked + span {
  background: #131313 !important;
  border: solid 2px #ffffff !important; }

.fr-popup .fr-image-upload-layer:hover,
.fr-popup .fr-video-upload-layer:hover {
  background: #1b1b1b !important; }

.fr-popup .fr-input-line input + label,
.fr-popup .fr-input-line textarea + label {
  background-color: #131313 !important; }

.fr-popup .fr-action-buttons button.fr-command {
  color: #fff !important;
  background-color: #000; }

.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
  background: #131313 !important; }

.fr-popup .fr-input-line input[type="text"],
.fr-popup .fr-input-line textarea {
  border: 1px solid #333 !important;
  color: #777 !important; }

.fr-popup,
.fr-popup .fr-input-line input[type="text"],
.fr-popup .fr-input-line textarea {
  background: #131313 !important; }

.fr-popup .fr-input-line input.fr-not-empty + label,
.fr-popup .fr-input-line textarea.fr-not-empty + label,
.fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
.fr-popup .fr-command.fr-btn.fr-btn-active-popup,
.fr-modal .fr-command.fr-btn.fr-btn-active-popup,
.fr-desktop .fr-command:hover:not(.fr-table-cell),
.fr-desktop .fr-command:focus:not(.fr-table-cell),
.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell),
.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
  background: #000000 !important;
  border: 0 !important; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off .fr-dropdown-menu {
  background: #2b2b2b; }

.sixteen.wide.column.cz-write-message .row .post-categories-n-tags {
  width: 100%;
  margin: 0; }

.sixteen.wide.column.cz-write-message .row .column {
  padding: 0; }

.sixteen.wide.column.cz-write-message .row .post-categories button.ui.button.post-category {
  color: white !important;
  font-weight: 300; }

.sixteen.wide.column.cz-write-message .post-date {
  width: 100%; }

.sixteen.wide.column.cz-write-message .post-featured-image {
  width: 100%;
  max-width: 100%; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor {
  width: calc(100% + 20px);
  max-width: calc(100% + 20px); }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
  background: #131313; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off .fr-newline {
  opacity: 0; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .row .post-featured-image {
  max-width: 100%; }

.sixteen.wide.column.cz-write-message .post-date.cz-post-date-onmobi {
  display: none; }

.img-up-cz {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #28292b;
  padding: 10px;
  background: #1313137a; }

.sixteen.wide.column.cz-write-message .post-author {
  font-weight: 300; }

.img-up-cz img.ui.image {
  max-width: 250px; }

.upload-link label {
  display: block; }

.upload-link i.cloud.upload.massive.icon {
  opacity: 0; }

.app-body .ui.container .row.editor {
  min-width: 655px;
  position: relative;
  left: -10px; }

button.ui.button.post-category {
  background: #c83e93 !important; }

button.ui.button.post-category.curate-category {
  background: #3e8fd0 !important; }

.fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
  background: #c83e93; }

.post-header.post-header__visual input[type="text"] {
  color: white !important; }

.row.editor * {
  color: white; }

.ui.grid.cz-new-js {
  padding: 0;
  padding-top: 25px;
  width: calc(64% - 245px); }

.ui.grid.cz-new-js > .row,
.ui.grid.cz-new-js > .row .ui.grid > .row {
  padding: 0; }

.ui.grid.Wallet {
  padding: 6px 10px !important; }

.following-content .wide.column.following-wrapper .row.tabs-panel .ui.segment.active.tab .list .item .right.floated.content .ui.button.unfollow {
  background: none;
  color: #c83e93;
  font-weight: normal; }

.dropdown-container.cz-newest-filter {
  color: #919191; }

.dropdown-container.cz-newest-filter img {
  margin-right: 5px; }

.dropdown-container.cz-newest-filter .ui.floating.dropdown {
  margin-left: 3px; }

.sixteen.wide.column.cz-tabs-newest .ui.floating.dropdown i.dropdown.icon {
  color: #c1c1c1; }

.sixteen.wide.column.cz-tabs-newest span.text {
  color: #131313; }

.sixteen.wide.column.cz-tabs-newest .text {
  font-weight: normal;
  color: silver; }

.app-body {
  overflow: scroll;
  overflow-x: hidden; }

.app-body .ui.container {
  width: 100%;
  max-width: 1440px !important;
  padding: 0 15px; }

.wallet-main-view {
  background: transparent; }

.tr-container.wallet-cz {
  margin-top: 50px; }

.app .wallet-menu-container {
  background: transparent; }

.articlePoints {
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 15px !important; }

.markets span b:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #f14cb2;
  position: absolute;
  left: 3px;
  top: 4px; }

.airdrop-container.tr-power-up.bg-gradient,
.ui.segment.pushable {
  background: #252d49;
  background: linear-gradient(90deg, #252d49 0%, #32222a 100%, #32222a 100%) !important;
  min-height: 100vh; }

.icon-table {
  display: flex;
  align-items: center; }

.icon-table img.ui.image {
  margin-right: 10px; }

.green-text {
  color: #32fe91;
  position: relative;
  padding-left: 15px; }

.categories.cz-categories > .row {
  max-width: 923px;
  margin: 0 auto; }

.categories.cz-categories > .cat-cz-both {
  max-width: 893px;
  margin: 0 auto; }

.green-text:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #32fe91;
  position: absolute;
  left: 0;
  top: 5px;
  transform: rotate(180deg); }

.categories.cz-categories {
  width: 100% !important;
  padding-top: 0 !important;
  max-width: calc(100% - 233px);
  padding: 0 !important; }

.cat-cz-both .categories.categories__mt {
  margin-top: 0 !important; }

.categories.cz-categories a {
  background: #21222d;
  padding: 13px 10px 10px;
  display: block;
  border-radius: 10px;
  height: 100%; }

.categories.cz-categories a .categories_bg__active {
  margin: 0 !important;
  padding: 0;
  background: none !important;
  border: none !important;
  height: 100%; }

.categories.cz-categories a .categories_bg__active h3.ui.header {
  background: #1e1e24;
  padding: 40px 0;
  border-radius: 10px;
  height: 100%; }

.cat-cz-both {
  padding: 20px 30px;
  background: #1b1b1b80;
  border-radius: 10px;
  padding-right: 10px;
  margin-left: 0px;
  width: calc(100% - 25px); }

.column.cz-seaarch-icon {
  position: relative;
  padding: 0; }

.column.cz-seaarch-icon img.ui.image {
  position: absolute;
  top: 18px;
  left: 15px;
  z-index: 1; }

.cat-cz-both .ui.huge.fluid.input.search-input input {
  background: #1b1b1b;
  color: #fff;
  font-size: 14px;
  padding: 15px 15px 15px 35px; }
  .cat-cz-both .ui.huge.fluid.input.search-input input:focus {
    border-color: var(--pink) !important; }

.categories.categories__mt .row {
  max-width: 100%; }

.cat-cz-both .categories.categories__mb .row {
  padding: 0; }

.cz-icons {
  display: flex;
  align-items: center;
  justify-content: center; }

.cz-icons img.ui.image {
  width: 40px;
  margin: 15px 10px; }

button.ui.button.cancel-button.cz-cancel {
  background: transparent;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
  position: absolute;
  right: 0;
  top: -67px;
  width: 34px;
  margin: 0;
  padding: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center; }

button.ui.button.cancel-button.cz-cancel i.close.icon {
  margin: 0; }

button.ui.button.cancel-button.cz-cancel:hover {
  background: white; }

button.ui.button.cancel-button.cz-cancel:hover i {
  color: #333 !important; }

.main_action_btn .cz-icons {
  margin-left: 10px; }

.cat-cz-both .categories.categories__mb .row .ui.secondary.segment.categories_bg {
  margin: 0 0 20px !important;
  background: #13131333 !important;
  border: none;
  border-radius: 10px; }

.cat-cz-both .categories.categories__mb .row .ui.secondary.segment.categories_bg {
  margin: 0 !important; }

.cat-cz-both .categories.categories__mb .row a {
  padding: 0 0 10px; }

.cat-cz-both .categories.categories__mb .row a {
  float: left;
  width: 100%; }

.cat-cz-both .categories.categories__mb .row .ui.secondary.segment.categories_bg h3.ui.header {
  font-weight: normal; }

.cat-cz-both .categories.categories__mb .row .ui.secondary.segment.categories_bg h3.ui.header div {
  color: white !important;
  font-weight: 300; }

.ui.three.column.grid.categories.categories__mb {
  margin-top: 15px; }

.category-img-cz {
  color: white;
  text-align: center;
  font-size: 15px; }

.ui.three.column.grid.categories.categories__mb .column {
  padding: 0 5px; }

.ui.three.column.grid.categories.categories__mb > .row {
  padding: 0 10px; }

.category-img-cz img.ui.image {
  margin: 0 auto 10px; }

.filter-li .ui.list {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #1b1b1b;
  border-radius: 10px;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  padding-left: 75px;
  padding-right: 5px; }

.cat-cz-both .ui.grid.categories.categories__mt {
  margin-top: 10px !important; }

.cat-cz-both .categories.categories__mt .filter-li .row {
  margin-left: 0px; }

.cat-cz-both .categories.categories__mt .row {
  margin: 0; }

.cat-cz-both .categories.categories__mt .filter-li .row .ui.list {
  width: 100%; }

.filter-li .item {
  width: 28px;
  height: 28px;
  background: #2d2d2d;
  text-align: center;
  color: white;
  font-weight: 300;
  text-transform: uppercase;
  border-radius: 30px;
  margin: 10px 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 19px; }

.filter-li .ui.list button.ui.button.item:hover,
.filter-li .ui.list button.ui.button.item:focus {
  color: #32FE9A;
  background: #2d2d2d; }

.filter-li .row {
  color: #919191;
  font-weight: normal;
  position: relative;
  max-width: 100%; }

.filter-li .row .ui.label {
  font-size: 14px;
  position: absolute;
  left: 13px;
  width: 80px;
  background: none;
  color: #cccccc;
  font-weight: normal;
  padding: 0 0 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px; }

.ui.label.cz-seaarch-icon img.ui.image {
  position: absolute;
  width: 14px !important;
  left: 0; }

.tr-container.wallet-cz h1 {
  font-size: 20px;
  color: white;
  font-weight: normal; }

.tr-container.wallet-cz h1.ui.header .content.tr-block-title {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.tr-container.wallet-cz h1.ui.header .sub.header {
  background: transparent !important;
  color: white !important; }

.cz-wall-contain {
  color: white; }

.cz-wall-contain h2 {
  font-weight: normal !important; }

.cz-wallet-balance .tr-rounded-box {
  background: #1b1b1b; }

.cz-wallet-balance .tr-rounded-box h1 {
  color: #01cdfd; }

.cz-wallet-balance .tr-rounded-box h1 small {
  font-weight: normal; }

.cz-wallet-balance .tr-rounded-box .tr-rounded-box__text {
  color: white; }

.cz-wallet-fill-info .tr-rounded-box {
  background-color: #1b1b1b; }

.cz-wallet-fill-info .tr-rounded-box p {
  color: white;
  font-weight: normal; }

.cz-wallet-fill-info .tr-rounded-box .airdrop-button-container {
  border: none; }

.cz-wallet-fill-info .tr-rounded-box .airdrop-button-container input {
  background: #313131; }

.cz-walet-text h2 {
  color: white !important; }

.cz-walet-text .column .tr-expl-item {
  color: white;
  font-weight: normal; }

.cz-walet-text .column .tr-expl-item a {
  color: white; }

.cz-walet-text .column .tr-expl-item__count {
  background: #1b1b1b; }

.cz-walet-text .column .tr-expl-item__count span {
  color: white; }

.three.wide.column.wallet-menu-container {
  padding-top: 80px; }

.person-summary {
  margin-left: 30px;
  margin-right: 0; }

header .ui.container {
  padding: 0 15px; }

.pusher .ui.grid.promo {
  margin: 0; }

#mainNav,
header {
  backdrop-filter: blur(0px) !important;
  background: transparent;
  box-shadow: none !important; }

header {
  background-image: none !important; }

.ui.container.cz-profile-container .row.person-summary {
  background: #1b1b1b;
  margin: 0 auto;
  padding: 10px; }

.rank span {
  font-weight: normal !important; }

.total-events-cz .ui.grid {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0; }

.total-events-cz .ui.grid .ui.grid {
  background: #13131382;
  padding: 10px !important;
  margin: 5px 10px 0 0 !important;
  border-radius: 5px; }

.total-events-cz .ui.grid .ui.grid span {
  padding: 0;
  color: #999;
  font-weight: 400; }

.total-events-cz .ui.grid .ui.grid span.value {
  padding-left: 10px;
  font-size: 19px;
  font-weight: 700;
  color: #01CDFD; }

.ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar {
  padding-left: 30px;
  padding-top: 5px; }

.ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar .circle-border {
  width: 164px !important;
  height: 164px !important;
  max-width: 100%;
  max-height: 100%;
  border: 5px solid #368f7c !important;
  padding: 5px; }

.ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar .circle-border .online-mark {
  background: #32fe91;
  border: 12px solid #32fe9a;
  border-radius: 30px;
  right: 3px;
  top: 12px;
  box-shadow: none; }

.cz-folow-counts {
  display: flex;
  position: absolute;
  right: 0;
  top: 27px;
  width: auto; }

.cz-follow-part {
  display: flex; }

.cz-follow-part b {
  display: flex;
  align-items: center;
  width: 122px;
  font-weight: normal;
  color: #9bb0cf;
  background: #1313137a;
  justify-content: center;
  border-radius: 5px;
  padding: 4px !important;
  margin: 0 7px !important; }

.cz-follow-part img.ui.image {
  margin-right: 6px !important; }

.cz-folow-counts button.ui.button {
  margin: 0;
  display: none; }

.cz-folow-counts p {
  display: none; }

.ui.container.your-trybe-wrapper .person-summary .person .description.cz-decription {
  padding: 0;
  color: white;
  text-align: left; }

.ui.container.your-trybe-wrapper .person-summary .person .description.cz-decription span.nick {
  color: #9bb0cf;
  font-weight: normal;
  padding: 5px 0; }

.rank span {
  font-weight: normal !important; }

.total-events-cz {
  padding-top: 5px; }

.total-events-cz .four.wide.column {
  background: #1b1b1b;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px; }

.total-events-cz .four.wide.column span.title {
  font-weight: normal; }

.total-events-cz .four.wide.column span.value {
  color: #01cdfd; }

.ui.container.cz-profile-container {
  max-width: 893px !important;
  width: calc(100% - 245px);
  padding: 0; }

.ui.inverted.link.list.sidebar-list a {
  display: flex !important;
  align-items: center;
  text-decoration: none;
  font-weight: normal !important;
  color: #9BB0CF !important; }

.ui.inverted.link.list.sidebar-list a:hover {
  color: #9bb0cf !important; }

.cz-mobile-menu .combinedBtn {
  clear: both;
  flex-direction: column;
  gap: 0;
  padding-top: 7px !important; }

.cz-mobile-menu .combinedBtn button {
  justify-content: initial !important;
  background: no-repeat !important; }

a {
  cursor: pointer; }

.ui.grid.my-articles-content.cz-my-articles-content {
  max-width: 900px !important;
  margin: 0 auto;
  padding: 0 0px;
  padding-right: 0px;
  border-top: 1px solid #303030;
  background-color: #13131345;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  margin-bottom: 30px; }

.ui.grid.my-articles-content.cz-my-articles-content > .row {
  padding: 0 !important;
  border-top: 1px solid #1b1b1b; }

.ui.grid.my-articles-content.cz-my-articles-content .sixteen.wide.column {
  padding: 0; }

.ui.grid.my-articles-content.cz-my-articles-content .sixteen.wide.column .ui.container.feed.cz-con-new {
  padding: 0; }

.ui.grid.my-articles-content.cz-my-articles-content .sixteen.wide.column .ui.container.feed.cz-con-new .ui.grid .row.post-preview:last-child {
  margin: 0; }

.ui.grid.cz-tabs-profile .row {
  padding: 0;
  background: #131313;
  border-radius: 6px 6px 0 0;
  margin: 0;
  margin-top: 20px; }

.ui.grid.cz-tabs-profile .row .four.wide.column button {
  background: none !important;
  color: #9bb0cf !important;
  margin: 19.5px 0;
  padding: 5px 0 !important;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  border-radius: 0; }

.ui.grid.cz-tabs-profile .row .four.wide.column button.ui.fluid.button.active-tab-cz-new {
  border-bottom: 2px solid #c83e93;
  color: #c83e93 !important; }

.ui.grid.cz-tabs-profile .row .four.wide.column a {
  border: none;
  text-decoration: none; }

.ui.grid.cz-tabs-profile .four.wide.column {
  width: auto !important; }

.ui.grid.cz-tabs-profile > .row {
  display: flex;
  align-items: center;
  justify-content: center; }

.sixteen.wide.column.cz-tabs-newest {
  background: #131313;
  padding: 4px 0 !important; }

.sixteen.wide.column.cz-tabs-newest .ui.floating.dropdown {
  color: white; }

.sixteen.wide.column.cz-tabs-newest .ui.small.borderless.fluid.four.item.menu {
  background: none;
  border: none; }

.sixteen.wide.column.cz-tabs-newest .ui.small.borderless.fluid.four.item.menu a {
  color: #9bb0cf;
  font-weight: 500; }

.sixteen.wide.column.cz-tabs-newest .ui.small.borderless.fluid.four.item.menu a svg {
  margin-right: 6px; }

.sixteen.wide.column.cz-tabs-newest .ui.small.borderless.fluid.four.item.menu a.active.item {
  color: #c83e93;
  background: none; }

.sixteen.wide.column.cz-tabs-newest .four.wide.column .dropdown-container {
  padding-left: 10px; }

.ui.grid.following-content.cz-following-content {
  background: #1b1b1b80;
  width: 100%;
  margin: 0 !important; }

.ui.grid.following-content.cz-following-content .row {
  padding-top: 0; }

.ui.grid.following-content.cz-following-content .row .ui.text.menu {
  background: #131313;
  margin: 0;
  width: 100%;
  border-top: 1px solid #1b1b1b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0 !important; }

.ui.grid.following-content.cz-following-content .row .ui.text.menu a.active.item {
  color: #c83e93 !important; }

.ui.grid.following-content.cz-following-content .row .ui.text.menu a {
  font-weight: bold; }

.ui.divided.inverted.relaxed.list .item {
  float: left;
  width: calc(50% - 16px);
  align-items: center;
  justify-content: space-between;
  background: #00000038;
  border-radius: 6px;
  overflow: hidden;
  margin: 2px 8px;
  border: none; }

.ui.segment.active.tab {
  float: left;
  width: 100%;
  background: transparent;
  float: left;
  width: 100%;
  background: transparent;
  padding: 8px 0 0 !important; }

.ui.grid.following-content.cz-following-content .column {
  margin: 0;
  padding: 0; }

.ui.grid.following-content.cz-following-content div {
  margin: 0;
  padding: 0; }

.ui.grid.page-sidebar > .row {
  margin-left: 0; }

.ui.divided.inverted.relaxed.list .item {
  display: flex;
  position: relative;
  padding: 22px 10px;
  justify-content: space-between; }

.ui.divided.inverted.relaxed.list .item img.ui.image {
  position: absolute;
  width: 50px;
  height: 50px !important;
  object-fit: contain;
  border-radius: 100px; }

.ui.divided.inverted.relaxed.list .item .content {
  padding-left: 65px; }

.ui.divided.inverted.relaxed.list .item .content a {
  color: white !important;
  text-decoration: none;
  font-weight: normal; }

.ui.divided.inverted.relaxed.list .item .right.floated.content {
  padding-left: 0;
  position: absolute;
  right: 10px; }

.ui.grid.settings.cz-settings {
  padding: 0;
  background: #1b1b1b80;
  width: 100%;
  margin: 0px !important; }

.ui.grid.settings.cz-settings .sixteen.wide.column.menu {
  padding: 0; }

.ui.grid.settings.cz-settings .sixteen.wide.column.menu .ui.inverted.vertical.menu {
  background: #131313;
  width: 100%;
  display: flex;
  flex-direction: initial;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border-top: 1px solid #1b1b1b;
  height: 45px; }

.ui.grid.settings.cz-settings .sixteen.wide.column.menu .ui.inverted.vertical.menu a {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  border: none !important;
  color: #9bb0cf !important;
  background: none;
  font-weight: 500; }

.ui.grid.settings.cz-settings .sixteen.wide.column.menu .ui.inverted.vertical.menu a::before {
  display: npo; }

.ui.grid.settings.cz-settings .sixteen.wide.column.menu .ui.inverted.vertical.menu a:before {
  display: none; }

.ui.grid.settings.cz-settings .sixteen.wide.column.menu .ui.inverted.vertical.menu a.active.item {
  color: #c83e93 !important; }

.sixteen.wide.column.content.cz-setting-form {
  color: white;
  max-width: 660px;
  margin: 0 auto; }

.sixteen.wide.column.content.cz-setting-form form.ui.form .field label {
  color: white;
  font-weight: normal; }

.sixteen.wide.column.content.cz-setting-form form.ui.form .field {
  display: flex; }

.sixteen.wide.column.content.cz-setting-form form.ui.form .field label {
  width: 110px; }

.sixteen.wide.column.content.cz-setting-form form.ui.form .field input,
.sixteen.wide.column.content.cz-setting-form form.ui.form .field textarea {
  background: #13131370;
  border: none;
  font-size: 12px;
  color: #d8d8d8;
  padding: 13px; }

.sixteen.wide.column.content.cz-setting-form h4 {
  padding-bottom: 30px; }

.sixteen.wide.column.content.cz-setting-form h4 {
  padding-bottom: 30px; }

.field.cz-referal-link {
  color: #01ccfb; }

.field.cz-form-setting + button {
  background: #c83e93;
  border: none !important;
  color: #000;
  margin-bottom: 30px; }

.sixteen.wide.column.content.cz-setting-form form.ui.form .field input {
  min-width: 300px; }

.sixteen.wide.column.content.cz-setting-form form.ui.form .field textarea#description {
  min-width: 500px; }

.terra-account.cz-terra-account {
  margin: 0 auto;
  max-width: 600px; }

.sixteen.wide.column.cz-tabs-newest .row {
  padding: 0; }

.terra-account.cz-terra-account h3 {
  font-weight: normal;
  font-size: 14px; }

.terra-account.cz-terra-account .ui.green.compact.buttons.hide-tablet {
  background: #c83e93 !important; }

.terra-account.cz-terra-account .ui.green.compact.buttons.hide-tablet .ui.inline.dropdown.button.icon {
  background: #c83e93 !important; }

.terra-account.cz-terra-account button.ui.fluid.disabled.button {
  background: #c83e93;
  border: none !important;
  color: #000 !important;
  margin-bottom: 30px;
  float: right; }

.ui.grid.cz-tabs-profile .row .five.wide.column a.green-bordered-button button.ui.fluid.button.active-tab-cz-new,
.cz-sidebarActive {
  color: #c83e93 !important;
  font-weight: bold;
  background: #1b1b1b8a; }

.ui.grid.cz-tabs-profile .row .five.wide.column a.greenButton button.ui.fluid.button.active-tab-cz-new,
.cz-sidebarActive {
  color: #c83e93 !important;
  font-weight: bold;
  background: #1b1b1b8a; }

.airdrop-container.tr-power-up.cz-tr-power-up {
  padding-bottom: 0; }

.airdrop-container.tr-power-up.cz-tr-power-up h2 {
  color: white; }

.cz-grid-powerup {
  background: #1b1b1b;
  border-radius: 0 0 7px 7px;
  padding-top: 40px !important; }

.cz-grid-powerup .tr-expl-item__count {
  background: #131313;
  color: #01CDFD; }

.cz-grid-powerup .tr-expl-item {
  color: white;
  font-weight: normal;
  font-size: 16px; }

.airdrop-container.tr-power-up.cz-tr-power-up .power-up-button-container .power-up-button-container__button {
  background: #C83E93;
  font-weight: normal;
  color: black !important;
  padding: 4px; }

.airdrop-container.tr-power-up.cz-tr-power-up .power-up-button-container a {
  text-decoration: none; }

.airdrop-container.tr-power-up.cz-tr-power-up .power-up-button-container {
  width: 220px; }

.cz-next-payout {
  border-top: 1px solid #2d2d2d; }

.cz-grid-powerup {
  margin-bottom: 20px !important; }

.cz-next-payout .tr-airdrop-countdown__item .tr-airdrop-countdown__item-value {
  color: #01CDFD;
  font-weight: bolder;
  font-size: 22px; }

.cz-next-payout .tr-airdrop-countdown__item .tr-airdrop-countdown__item-label {
  color: white;
  font-size: 12px; }

.tr-airdrop-countdown {
  margin: 0 auto; }

.cz-tr-stake.tr-stake-wrapper {
  background: #1b1b1b8a;
  padding: 0 10px;
  border-radius: 6px;
  width: 100%; }

.ui.stackable.three.column.grid.cz-tr-stake.tr-stake-wrapper {
  margin: 0 !important; }

.cz-tr-stake.tr-stake-wrapper .column {
  margin: 20px 0 0; }

.airdrop-container .ui.grid.tr-stake-wrapper .column .info-block-item.tr-rounded-box {
  background: #1b1b1b; }

.airdrop-container .ui.grid.tr-stake-wrapper .column .info-block-item.tr-rounded-box .icon-wrapper {
  width: 70px;
  height: 70px;
  background: #151515;
  border-radius: 100px; }

.airdrop-container .ui.grid.tr-stake-wrapper .column .info-block-item.tr-rounded-box .icon-wrapper * {
  color: #01ccfc;
  font-size: 25px; }

.airdrop-container .ui.grid.tr-stake-wrapper .column .info-block-item.tr-rounded-box .icon-block-amount-text {
  color: white;
  font-weight: normal; }

.airdrop-container .ui.grid.tr-stake-wrapper .column .info-block-item.tr-rounded-box .icon-block-amount-name {
  color: var(--pink); }

.ui.stackable.two.column.grid.cz-grid-powerup {
  padding-top: 20px;
  margin: 0; }

.ui.stackable.two.column.grid.cz-stackable table.ui.table thead td.two.wide {
  color: #bbb !important;
  text-align: left; }

.ui.stackable.two.column.grid.cz-stackable table.ui.table tbody tr.portfolio-table-row td.two.wide {
  color: white;
  text-align: left;
  padding: 10px 20px; }

.ui.stackable.two.column.grid.cz-stackable table.ui.table tbody tr.portfolio-table-row td.two.wide svg {
  color: #01ccfc; }

.cz-payout-history table.ui.table.cz-payout-history-table tr td.two.wide:last-child {
  color: #bbb !important;
  text-align: right !important; }

.cz-payout-history table.ui.table.cz-payout-history-table tr td.two.wide {
  white-space: nowrap;
  text-align: left; }

.cz-tokens-mobile {
  display: none;
  margin: 15px !important; }

header.cz-header-fixed {
  -webkit-backdrop-filter: blur(30px) !important;
  backdrop-filter: blur(18px) !important;
  background: #ffffff17; }

.ui.grid.profilePic.position-relative img {
  object-fit: cover;
  width: 100%;
  border-radius: 0 !important;
  padding: 0;
  height: 100%; }

.ui.grid.profilePic.position-relative {
  overflow: hidden;
  padding: 0; }

.ui.grid.top-article * {
  text-decoration: none; }

.ui.fluid.container.your-trybe-wrapper > .ui.grid {
  margin: 0; }

.modal.display-block.modal-delete section.modal-main {
  width: 260px;
  border-radius: 10px;
  text-align: center;
  padding: 30px 0; }

.modal.display-block.modal-delete section.modal-main p {
  margin: 0 0 20px;
  padding: 0 15px; }

.modal.display-block.modal-delete section.modal-main button:last-child {
  background: #ea48ad;
  color: white; }

.cz-icons.cz-onlydesktop img {
  width: 30px !important;
  margin: 15px 5px !important; }

.ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js {
  padding: 0; }

.ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor {
  transform: scale(0.9);
  padding: 0;
  width: 100% !important; }

.ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor .sc-kTUwUJ.boRwMT {
  margin-top: 20px; }

.ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor .sc-kTUwUJ.boRwMT button {
  line-height: 0;
  padding: 15px 20px; }

.cz-textarea-setting + .actions {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 0 !important; }

.captcha_box {
  width: 100%;
  text-align: right;
  float: right;
  display: flex;
  justify-content: flex-end; }

.triger-wrap {
  width: 100%;
  margin-top: 20px; }

.triger-wrap .triger-conditions:first-child {
  float: left; }

.triger-wrap .triger-conditions:last-child {
  float: right; }

.row.editor_canonical {
  border: 1px solid #000;
  margin-top: 10px !important; }

@media only screen and (max-width: 1499px) {
  .leaderboard.cz-leaderboard {
    max-width: calc(100% - 450px); } }

@media only screen and (max-width: 1399px) {
  .bg-gradient-loop.community_Tab.partners.performers_wrap .innerboxx {
    padding-left: 55px; }
  .leaderboard.cz-leaderboard {
    max-width: calc(100% - 400px); } }

@media only screen and (max-width: 1199px) {
  .bg-gradient-loop.community_Tab.partners.performers_wrap .ui.container {
    max-width: 95% !important; }
  .bg-gradient-loop.community_Tab.partners.community_partners .ui.container .innerboxx {
    width: 100% !important; }
  .ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor .sc-kTUwUJ.boRwMT button {
    line-height: 0;
    padding: 10px 10px; }
  .leaderboard.cz-leaderboard {
    max-width: calc(100% - 250px); } }

@media only screen and (max-width: 1120px) {
  .ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar {
    padding-left: 0; } }

@media only screen and (max-width: 1024px) {
  body header {
    background: url(/img/header_bg.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 90px !important; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.cz-com-page-profile {
    width: 100% !important;
    padding: 0 15px; }
  .ui.grid.page-wrapper.editor_wrap .ui.grid.cz-new-js #editor .sc-CtfFt {
    width: 100px; }
  .leaderboard.cz-leaderboard .cz-leaderboard-table .ui.grid .three.column.row {
    margin: 0; }
  .ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar .circle-border {
    width: 144px !important;
    height: 144px !important; }
  .editArticleLoader .ui.active.loader {
    margin: 0; }
  .cz-icons.cz-onlydesktop {
    display: none; }
  .desktop {
    display: none; }
  .two-butons-folder {
    position: initial;
    margin: 10px 0;
    padding-left: 150px; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .four.wide.column.avatar.cz-profile-avtar {
    position: absolute;
    width: 170px !important; }
  .cz-feedSection.tokenGraph.person-detialing + .cz-feedSection.tokenGraph + .cz-feedSection,
  .cz-feedSection.tokenGraph.person-detialing + .cz-feedSection.tokenGraph + .cz-feedSection + .cz-feedSection {
    margin: 40px 15px 0;
    padding: 0;
    border-radius: 10px;
    overflow: hidden; }
  .ui.grid.cz-new-js {
    padding: 0; }
  .cz-feedSection.tokenGraph.person-detialing + .cz-feedSection.tokenGraph {
    margin: 30px 15px 0px; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small {
    margin-top: 0 !important; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .twelve.wide.column.description.cz-decription {
    padding-left: 30px;
    width: 100% !important; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small {
    display: block;
    margin: 0 15px; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .twelve.wide.column.description.cz-decription span.name,
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .twelve.wide.column.description.cz-decription .rank {
    padding-left: 150px; }
  .cz-dummy-text-profile {
    margin-top: 40px; }
  .all-cz-socials {
    margin: 10px 0; }
  .all-cz-socials span {
    background: #151515;
    border-radius: 6px;
    padding: 8px 15px;
    margin-right: 10px; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .total-events-cz > .ui.grid {
    display: block; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .total-events-cz > .ui.grid .ui.grid {
    float: left; }
  .empty-mobile {
    display: block;
    clear: both; }
  .cz-flex-set {
    padding: 0 !important;
    display: flex; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .total-events-cz .ui.grid .cz-flex-set .ui.grid {
    flex-direction: column;
    align-items: baseline; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .total-events-cz .ui.grid .cz-flex-set .ui.grid span.value {
    padding: 0 !important; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .total-events-cz .ui.grid .cz-flex-set .ui.grid:last-child {
    justify-content: center; }
  .cz-feedSection.tokenGraph.person-detialing {
    display: none; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small {
    display: block; }
  .cz-single-post {
    width: 100%; }
  .cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev,
  .cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-next {
    width: 35px;
    height: 35px; }
  .categories.cz-categories,
  .leaderboard.cz-leaderboard {
    margin: 0 auto;
    max-width: 100%;
    padding: 0 !important; }
  .page-sidebar {
    display: none !important; }
  .bugSidebar {
    display: none !important; }
  #mainNav .navbar-nav .nav-item .nav-link {
    text-align: center; }
  .ui.grid.cz-new-js {
    margin-left: 0px; }
  header .ui.container .container .ui.grid.user-area {
    margin: 0 auto; }
  header .ui.container .container .ui.grid.user-area .ui.grid.user-online {
    margin: 0 auto 20px; }
  header {
    height: auto; }
  .app-body .container .grid .card .content .description span {
    max-width: 100%;
    white-space: initial; }
  .app-body .container .grid .card .content .header span {
    white-space: initial; }
  .ui.grid.top-article a.ui.card:last-child {
    margin-right: 0; }
  .cz-tokens-mobile {
    display: block;
    background: #1f1f1f;
    border-radius: 15px;
    margin-top: 50px; }
  .cz-tokens-mobile .cz-tokens-mobile-tr:nth-child(even) {
    background: #131313;
    background: #1f1f1f;
    border-radius: 10px; }
  .cz-tokens-mobile-td .ui.grid {
    margin: 0;
    position: relative;
    padding-left: 50px;
    font-weight: normal;
    font-size: 11px;
    color: #999;
    padding: 0px 0 5px 60px; }
  .cz-tokens-mobile-td .ui.grid img.ui.image {
    position: absolute;
    padding: 0;
    left: 10px;
    top: 0; }
  .cz-tokens-mobile-td .ui.grid p {
    padding: 1px 0 0;
    display: block;
    font-size: 14px !important;
    color: white;
    font-weight: 400;
    width: 100%;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cz-tokens-mobile > div > div .cz-tokens-mobile-td.cz-left-td:nth-child(2) .ui.grid {
    padding-left: 20px; }
  .cz-tokens-mobile > div > div .cz-tokens-mobile-td.cz-left-td:nth-child(2) .ui.grid div {
    padding: 0; }
  .cz-up-downs {
    padding: 0 !important;
    width: 100%;
    display: flex; }
  .cz-up-downs p b {
    display: block;
    color: #32fe91;
    padding: 0 0 0 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    position: relative; }
  .cz-up-downs p {
    font-size: 12px !important;
    color: #999 !important;
    margin: 0; }
  .cz-up-downs p b:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #32fe91;
    position: absolute;
    left: 0;
    top: 5px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .cz-air-ex {
    padding: 0 !important;
    display: flex; }
  .cz-air-ex p {
    font-size: 12px !important;
    color: #999 !important;
    margin: 0; }
  .cz-air-ex p b i {
    width: 24px;
    height: 24px;
    background: #2d2d2d;
    display: block;
    border-radius: 40px;
    position: relative;
    margin-top: 2px; }
  .cz-air-ex p b i:before {
    width: 10px;
    height: 10px;
    content: '';
    position: absolute;
    background: #32fe91;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100px; }
  .cz-air-ex p button.ui.button {
    width: 100px;
    background: #2d2d2d !important;
    display: block;
    height: 23px;
    text-align: center !important;
    text-decoration: none !important;
    font-weight: 400;
    color: #c94094 !important;
    margin-top: 2px; }
  .cz-tokens-mobile > div > div {
    padding: 0 5px; }
  .cz-tokens-mobile-tr {
    display: block;
    justify-content: space-between;
    margin: 0px; }
    .cz-tokens-mobile-tr .markets {
      background: none !important;
      width: 100% !important;
      margin: auto !important;
      margin-top: 100px !important;
      display: block; }
      .cz-tokens-mobile-tr .markets h3 {
        padding-bottom: 10px; }
      .cz-tokens-mobile-tr .markets span {
        float: left;
        padding-bottom: 10px; }
  .cz-tokens-mobile-td {
    width: 50%;
    padding: 10px 0 5px; }
  .cz-tokens-mobile-td .ui.grid p.cz-up-down {
    position: relative;
    padding-left: 15px;
    color: #32fe91; }
  .cz-tokens-mobile-td .ui.grid p.cz-up-down:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #32fe91;
    position: absolute;
    left: 0;
    top: 5px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  main.homepage .section-hero {
    padding: 50px 0 0; }
  main.homepage .section-hero h1 p {
    font-size: 35px; }
  main.homepage .section-hero p.section-hero__description {
    margin-bottom: 20px; }
  .app-body .ui.vertical.tabular.menu .item {
    margin-bottom: 10px;
    padding: 15px;
    line-height: 25px; } }

.combinedBtn .btn.headerBtn--login:hover {
  background: #1b1b1b8a !important;
  color: #c83e93 !important; }

@media screen and (max-width: 991px) {
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile > div {
    text-align: center; }
  .desktop {
    display: none; }
  header .ui.container .container a.navbar-brand.fw-bold {
    width: 90px; }
  .ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper > .ui.grid.c-row {
    flex-direction: column-reverse; }
  .ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper > .ui.grid.c-row .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side {
    width: 100% !important; }
  .ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper > .ui.grid.c-row .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog {
    width: 100% !important; }
  .ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper > .ui.grid.c-row .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog .ui.grid.top-article {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
    margin-left: 0 !important; }
  .ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper > .ui.grid.c-row .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile {
    display: none !important; }
  .ui.grid.promo.cz-communityProfile .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side {
    display: block !important; }
  .ui.grid.promo.cz-communityProfile .ui.grid.page-wrapper .content-wrapper {
    max-width: 100%;
    padding: 0;
    margin: 0; }
  .ui.grid.promo.cz-communityProfile .ui.grid.tab-article {
    width: 100%;
    margin: 0 auto; }
  .ui.grid.promo.cz-communityProfile .ui.container.feed.cz-con-new {
    width: 100%;
    margin: 20px auto 0 !important; }
  .ui.grid.promo.cz-communityProfile .ui.grid.top-article .image img {
    height: 167px;
    object-fit: cover; }
  .ui.grid.promo.cz-communityProfile .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog .sixteen.wide.column .ui.container {
    margin: 0 !important; }
  .cz-communityBack {
    padding: 0 17px;
    margin-top: 15px; }
  .cz-communityBack button.ui.button {
    background: no-repeat;
    color: white;
    position: relat0ive; }
  .cz-communityBack button.ui.button.ui.button:before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    margin-top: 2px; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side {
    display: none; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profileInner {
    width: 150px;
    float: left; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details {
    width: calc(100% - 150px);
    float: left;
    display: inline-block; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.loopCard.userProfileCard {
    display: inline-block;
    width: 100%; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile {
    float: left;
    width: 100%;
    padding: 0;
    display: block; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile > div {
    float: left;
    width: 100%; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details p {
    display: block;
    text-align: left; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details button {
    background: no-repeat;
    color: #C83D93;
    border: 1px solid #C83D93;
    margin-left: 14px;
    margin-top: 10px;
    font-weight: normal;
    padding-right: 40px;
    position: relative; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details button:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    right: 15px;
    transform: rotate(45deg);
    border-right: 2px solid #C83D93;
    border-top: 2px solid #C83D93;
    margin-top: -5px;
    top: 50%; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details p.name {
    margin-top: 10px; }
  .ui.grid.top-article {
    margin-bottom: 20px; }
  .ui.divided.inverted.relaxed.list .item {
    width: calc(100% - 18px) !important; }
  .user-review-wrapper .sixteen.wide.column .row.summary .sixteen.wide.column.avatar {
    width: 300px !important; }
  .user-review-wrapper .sixteen.wide.column .row.summary .sixteen.wide.column.description {
    width: calc(100% - 300px) !important; }
  .user-review-wrapper .sixteen.wide.column .row.summary .sixteen.wide.column.avatar .circle-border {
    top: 10px; }
  .ui.grid.categories.categories__mt .fifteen.wide.column {
    width: 100% !important; }
  .ui.one.column.grid.categories .row {
    padding: 0 !important; }
  .ui.one.column.grid.categories .row .fifteen.wide.column {
    width: 100% !important; } }

@media screen and (max-width: 900px) {
  .sixteen.wide.column.some-class .ui.grid {
    width: 100%;
    margin: 0; }
  .sixteen.wide.column.some-class .fourteen.wide.column {
    width: 100% !important;
    padding: 0; }
  .sixteen.wide.column.some-class .fourteen.wide.column .sixteen.wide.column {
    padding: 0; }
  .sixteen.wide.column.some-class .fourteen.wide.column .sixteen.wide.column .ui.container {
    padding: 0; }
  .sixteen.wide.column.some-class .fourteen.wide.column .sixteen.wide.column .ui.container .content-img {
    width: 100%; }
  main.homepage a.ui.button.tr-primary-button,
  button.ui.button.tr-primary-button.white.play {
    width: 100%;
    margin: 10px 0; }
  main.homepage a.ui.button.tr-primary-button {
    margin: 0; } }

@media screen and (max-width: 850px) {
  .row.cz-set-set .social-buttons-container div {
    width: 33.33%;
    float: left;
    text-align: center !important; }
  .row.cz-set-set .social-buttons-container div > div {
    width: 100%;
    margin: 10px 0px 10px; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .total-events-cz {
    display: block; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .ui.container.feed.cz-con-new .row.post-preview .eight.wide.column.post-preview-info.cz-new-card .middle.aligned.row p {
    padding: 0; }
  .ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar .circle-border .online-mark {
    border: 9px solid #32fe9a;
    right: -3px; }
  .three.wide.column.cz-dek-top-button {
    display: none !important; }
  .user-review-wrapper.cz-user-review-wrapper .summary .person .avatar .circle-border {
    width: 100px;
    height: 100px; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .row.summary .six.wide.column.avatar {
    padding-left: 15px; }
  .user-review-wrapper.cz-user-review-wrapper .summary .person .avatar .circle-border .circle {
    width: 80px;
    height: 80px; }
  .user-review-wrapper.cz-user-review-wrapper .summary .person .avatar .circle-border .online-mark {
    border: 9px solid #32fe9a;
    right: -4px; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .row.summary .six.wide.column.avatar {
    width: 125px !important; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .row.summary .six.wide.column.description {
    width: calc(100% - 130px) !important;
    padding-right: 15px; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .sixteen.wide.column .row.summary {
    padding: 15px 0; }
  .cz-folow-counts .cz-set-p.cz-moni-button-hub .social-controls {
    position: initial !important;
    padding-right: 15px !important;
    padding-left: 15px !important; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper p {
    padding: 20px 15px 0;
    font-weight: 300; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .sixteen.wide.column .row.summary .rank + .ui.divider + span {
    display: none; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .sixteen.wide.column .row.summary .right.floated.sixteen.wide.column.stats {
    padding-left: 15px;
    padding-right: 15px; }
  .cz-folow-counts .cz-set-p.cz-moni-button-hub .cz-follow-part {
    padding-left: 15px !important; }
  .cz-folow-counts .cz-set-p.cz-moni-button-hub .cz-follow-part b {
    padding: 4px 0 !important;
    margin: 0 !important; }
  .cz-folow-counts .cz-set-p.cz-moni-button-hub {
    margin-top: 15px !important; }
  .ui.grid.post-container.cz-single-article {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px; }
  .cz-set-p {
    display: flex; }
  .cz-folow-counts button.ui.button,
  .cz-set-p .cz-follow-part {
    width: 50%; }
  .cz-set-p .cz-follow-part b {
    width: 100%; }
  .ui.container.cz-profile-container {
    width: 100%;
    padding: 0 20px; }
  .total-events-cz {
    display: none; }
  .total-events-cz.cz-mob-new-follow {
    display: block;
    padding: 15px 0 0; }
  .ui.container.your-trybe-wrapper .person-summary .person .avatar.cz-profile-avtar {
    width: 100px !important;
    height: 100px;
    padding: 0 !important; }
  .cz-folow-counts {
    position: relative;
    top: 0;
    padding: 20px 0 0;
    width: 100%;
    display: block; }
  .cz-folow-counts button.ui.button {
    height: 63px;
    min-width: 172px;
    background: #c83e93;
    color: black;
    font-weight: 500;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .cz-follow-part {
    flex-direction: column;
    justify-content: space-between;
    margin-right: 6px; }
  .cz-follow-part b {
    margin: 0; }
  .cz-folow-counts button.ui.button b {
    font-weight: 300;
    color: white;
    margin-right: 10px;
    font-size: 30px;
    position: relative;
    top: -4px; }
  .ui.container.cz-profile-container .row,
  .ui.container.cz-profile-container .seven.wide.column.person,
  .ui.container.cz-profile-container .seven.wide.column.person .ui.grid {
    padding: 0;
    margin: 0; }
  .ui.container.your-trybe-wrapper .person-summary .person .description.cz-decription {
    padding-left: 15px;
    width: calc(100% - 100px) !important;
    text-align: left; }
  .cz-folow-counts p {
    display: block;
    color: white;
    font-weight: 300;
    width: 100%;
    margin-top: 20px; }
  .row.cz-set-set {
    clear: both;
    float: left;
    width: 100%; }
  .row.cz-post-header-top-enter .post .author-post .ui.grid .row.post-preview .six.wide.column.content-img {
    float: left;
    width: 100% !important;
    max-height: 300px; }
  .row.cz-post-header-top-enter .post .author-post .ui.grid .row.post-preview .eight.wide.column.post-preview-info.cz-new-card {
    width: 100% !important; }
  .row.cz-post-header-top-enter .post .author-post .ui.grid {
    display: block; }
  .social-buttons-container {
    display: block;
    height: auto; } }

.terra-account.cz-terra-account .ui.green.compact.buttons.hide-tablet .ui.inline.dropdown.button.icon + button {
  background: #131313;
  border-radius: 0;
  margin-left: 10px; }

.ui.card.team_member {
  width: 100%; }

.saveIcon {
  width: 16px;
  position: absolute;
  margin-left: 14px;
  margin-top: 13px; }

.draft-button {
  border-radius: 33px; }

.bg-gradient-loop.start-learning {
  display: none; }

@media screen and (max-width: 768px) {
  .cz-new-card .ui.grid h4.post-preview-title {
    font-size: 18px; }
  .bg-gradient-loop.team-section .team-box .column.innerboxx {
    width: 100%; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-prev,
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    top: 60px; }
  .ui.three.column.grid.categories.cz-categories > .row .column {
    width: 100%;
    margin-bottom: 10px; }
  .row.sub-header.cz-post-header-top .post-date .post-author button {
    position: initial;
    margin-top: 10px !important; }
  .ui.three.column.grid.categories.cz-categories > .row .column .category-img-cz {
    display: flex;
    align-items: center; }
  .ui.three.column.grid.categories.cz-categories > .row .column .category-img-cz img.ui.image {
    margin-right: 10px; }
  .ui.three.column.grid.categories.cz-categories > .row .column .content {
    width: 100%;
    display: flex; }
  .cz-feedSection.cz-feedSection-padding .ui.container.feed.cz-con-new {
    margin: 0 !important; }
  .cz-feedSection.cz-feedSection-padding .ui.container.feed.cz-con-new .ui.container {
    margin: 0 !important; }
  .page404 {
    height: 100%;
    margin-top: 65%;
    color: #fff; }
  .sixteen.wide.column.cz-write-message .post-author b {
    float: left;
    width: 100%;
    margin: 0 !important; }
  .sixteen.wide.column.cz-write-message .post-author b span {
    float: left;
    width: calc(100% - 50px); }
  .sixteen.wide.column.cz-write-message .post-author b img.ui.avatar.circular.image {
    float: left; }
  .img-up-cz {
    height: 200px; }
  .sixteen.wide.column.cz-write-message .post-author i {
    font-style: normal;
    padding-left: 40px;
    font-size: 12px;
    color: #9BB0CF !important;
    position: relative;
    top: -4px; }
  .sixteen.wide.column.cz-write-message .post-date {
    display: none; }
  .sixteen.wide.column.cz-write-message .post-date.cz-post-date-onmobi {
    display: block; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .ui.container.feed.cz-con-new .ui.container {
    margin: 0 !important; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper {
    margin-top: 80px; }
  .row.sub-header.cz-post-header-top .post-date .post-author.cz-post-mobile-only {
    display: block !important; }
  .ui.grid.my-articles-content.cz-my-articles-content .ui.container.feed.cz-con-new {
    padding-bottom: 30px !important; }
  .row.sub-header.cz-post-header-top .post-date .post-author {
    display: none !important; }
  .ui.grid.post-container.cz-single-article {
    padding-top: 85px; }
  .ui.container.cz-profile-container .row.person-summary {
    margin-bottom: 20px !important; }
  .total-events-cz .ui.grid .ui.grid {
    padding: 10px 5px !important; }
  .ui.container.cz-profile-container {
    padding: 0; }
  .ui.grid.cz-tabs-profile .row .four.wide.column button {
    padding: 20px 0; }
  .cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev {
    left: 29%; }
  .cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-next {
    right: 29%; }
  .ui.grid.top-article {
    display: block;
    white-space: nowrap;
    overflow: auto; }
  .ui.container.cz-back-article {
    padding: 0; }
  .ui.grid.top-article .column:last-child {
    margin-right: 0; }
  .cz-tokens-mobile {
    margin-top: 15px; }
  .cz-toping-values {
    flex-direction: column;
    padding: 15px 20px; }
  .cz-toping-values h3 {
    font-size: 15px;
    font-weight: 700;
    color: white; }
  .cz-toping-values span {
    text-transform: capitalize;
    color: #9BB0CF;
    font-size: 12px;
    font-weight: 400; }
  .cz-toping-values span b {
    width: 15px;
    display: inline-block;
    height: 10px;
    position: relative;
    cursor: pointer; }
  .cz-toping-values span b:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #f14cb2;
    position: absolute;
    left: 3px;
    top: 4px; }
  .ui.grid.cz-new-js .ui.grid.my-grid > .ui.container {
    margin: 0 !important;
    padding: 0 12px; }
  .ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor {
    min-width: 100%;
    max-width: 100%;
    width: 100% !important; }
  .sixteen.wide.column.following-wrapper .ui.segment.active.tab .right.floated.content {
    right: 0; }
  .sixteen.wide.column.following-wrapper .ui.segment.active.tab .right.floated.content button {
    max-width: 100%;
    padding: 10px; }
  .eight.wide.column.post-preview-info.cz-new-card + div {
    border-radius: 0; }
  .ui.container.feed.cz-con-new .ui.grid .row {
    position: relative; }
  .ui.container.feed.cz-con-new .ui.grid .row .right.floated.three.wide.column.dark-text {
    position: absolute;
    right: 20px; }
  .cz-single-post .ui.container {
    padding: 0 10px !important;
    margin: 0 !important; }
  button.ui.button.backCategory {
    margin-left: 10px; }
  .cz-single-post > .ui.container h6 {
    padding-left: 10px; }
  .user-review-wrapper .sixteen.wide.column .row.summary .sixteen.wide.column.avatar {
    margin: 0 auto;
    display: block;
    width: auto !important;
    padding: 0; }
  .ui.fluid.container.user-review-wrapper {
    padding: 0 30px 0 0; }
  .user-review-wrapper .sixteen.wide.column .row.summary .sixteen.wide.column.description {
    width: 100% !important;
    text-align: center;
    margin-top: 30px; }
  .leaderboard .ui.fluid.container {
    margin: 0 !important;
    max-width: 100% !important; }
  .ui.container.feed .ui.container {
    padding-right: 70px; }
  .ui.grid.categories.categories__mt .fifteen.wide.column {
    padding-right: 30px !important; }
  .categories .categories_bg {
    margin-top: 20px !important; }
  .app-body .ui.grid .fifteen.wide.column {
    padding: 0 !important; }
  .ui.one.column.grid.categories {
    padding-right: 30px; }
  .ui.fluid.container.your-trybe-wrapper {
    margin: 0 !important; }
  .ui.fluid.container.your-trybe-wrapper .ui.container.feed {
    padding: 0 !important;
    width: calc(100% + 23px) !important;
    margin: 0 !important; }
  .ui.fluid.container.your-trybe-wrapper .ui.container.feed .ui.container {
    margin: 0 !important; }
  header .ui.container {
    padding: 0 0px; }
  img.img-responsive {
    max-width: 400px;
    margin: 0 auto;
    display: block;
    width: 100%; }
  main.homepage .section-hero h1 p,
  .app-body .section-hero__description,
  .app-body .tr-section-title {
    text-align: center; }
  main.homepage .ui.container {
    margin: 0 !important; }
  .ui.grid.promo.cz-communityProfile .content-wrapper .ui.grid.c-row .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side {
    padding: 0 20px; }
  .ui.grid.promo.cz-communityProfile .content-wrapper .ui.grid.c-row .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side {
    padding: 0 20px; }
  .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog .ui.grid.top-article {
    white-space: nowrap; }
  .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog .ui.grid.top-article a.ui.card {
    display: inline-block;
    margin-right: 20px; }
  .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog .ui.grid.top-article a.ui.card:last-child {
    margin: 0; }
  .eleven.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.bg-info.cz-set-new-blog .ui.grid.top-article a.ui.card .content {
    min-height: 125px; } }

.post-categories-n-tags .ui.container.meta-data {
  margin: 0 auto !important; }

@media screen and (max-width: 599px) {
  .ui.grid.settings.cz-settings form.ui.form > .field {
    display: block; }
  img.ui.image.icon-copy {
    left: 10px;
    top: 39px; }
  .ui.grid.settings.cz-settings .field.cz-form-setting + button {
    float: none;
    margin: 0 auto 20px;
    display: block; }
  .three.wide.column.cz-dek-top-button .social-controls-red {
    padding: 0 !important; }
  .cz-set-p.cz-moni-button-hub .cz-moni-button .social-controls-red {
    position: initial !important; }
  .row.newsletter-section {
    margin-top: 0 !important; }
  .row.appDownload {
    height: 100%; }
  .ui.fluid.container.user-review-wrapper.cz-user-review-wrapper .row.summary .six.wide.column.description {
    margin: 5px 0 0 !important;
    text-align: left; } }

@media screen and (max-width: 600px) {
  .cz-tokens-mobile-td .ui.grid {
    padding: 0px 0 5px 50px; }
  .cz-tokens-mobile-td .ui.grid img.ui.image {
    width: 35px !important; }
  .row.cz-set-set .social-buttons-container div {
    width: 150px;
    float: left;
    text-align: center !important; }
  .row.cz-post-comments .comments-container .ui.inverted.form div * iframe {
    width: 100% !important;
    -webkit-transform: scale(0.8) translate(-35px, 0px);
    transform: scale(0.8) translate(-35px, 0px); }
  .ui.grid.hero_section h6 {
    font-size: 30px; }
  .cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-prev,
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 20px !important; }
  .cz-all-screens.mobileApp.swiper-full-mobile .swiper-button-next,
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    right: 20px; }
  .row.section-about .mobile.only h2 {
    padding: 10px 0 0; }
  .row.section-about .mobile.only p {
    padding: 0; }
  .row.section-about .ui.container .ui.divided.grid > .row {
    margin-bottom: 20px; }
  .sign-up .ui.container {
    padding: 0 !important;
    margin: 0 !important; }
  .row.summary {
    width: 100%; }
  .row.summary {
    width: 100%; }
  .ui.fluid.container.user-review-wrapper .sixteen.wide.column .ui.container.feed {
    width: 100% !important;
    margin: 0 !important; }
  .ui.fluid.container.user-review-wrapper .sixteen.wide.column .ui.container.feed .ui.grid {
    width: 100%;
    margin: 0; }
  .ui.fluid.container.user-review-wrapper .sixteen.wide.column .ui.container.feed .ui.grid .sixteen.wide.column {
    padding: 0; }
  .ui.fluid.container.user-review-wrapper .sixteen.wide.column .ui.container.feed .ui.grid .sixteen.wide.column .ui.container {
    margin: 0 !important; } }

@media only screen and (max-width: 880px) {
  .ui.fluid.container.cz-leaderboard-table .column {
    width: 100% !important;
    margin-bottom: 20px; }
  .leaderboard.cz-leaderboard {
    padding: 0; }
  .categories.categories__mb .column {
    width: 50% !important; }
  .categories.cz-categories a .categories_bg__active h3.ui.header {
    padding: 10px; }
  .cat-cz-both .categories.categories__mt .filter-li .row .ui.list {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px; }
  .cat-cz-both .categories.categories__mt .filter-li .row .ui.list button {
    margin: 0px 2px; }
  .filter-li .ui.list .item {
    display: inline-block !important;
    margin: 10px 10px; }
  .sixteen.wide.column.cz-tabs-newest .row .four.wide.column {
    display: none; }
  .sixteen.wide.column.cz-tabs-newest .row .twelve.wide.column {
    display: block;
    margin: 0 auto; }
  .sixteen.wide.column.cz-tabs-newest .row {
    width: 100%;
    display: block; } }

@media only screen and (max-width: 767px) {
  .triger-wrap {
    display: flex;
    justify-content: flex-end; }
  .bg-gradient-loop.feedback_wrap .loop-text {
    width: 100%;
    max-width: 100%;
    padding-left: 15px; }
  .bg-gradient-loop.community_Tab.partners.community_partners .ui.container {
    max-width: 100% !important; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-prev,
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    top: 110px; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    left: auto;
    right: 0; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-prev {
    left: 0; }
  .ui.stackable.three.column.grid.cz-tr-stake.tr-stake-wrapper {
    margin: 0 !important;
    padding: 15px 0; }
  .bg-gradient-loop.cz-join-community .ui.container .sixteen.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.innerboxx {
    padding: 0; }
  .start-learning .innerboxx a.learnMore {
    width: 105px;
    margin: 0 auto !important; }
  .image.rewa-bg {
    width: 100%;
    top: 120px; }
  .row.support-section .eight.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.innerboxx {
    padding: 0; }
  .row.loop-section .eight.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.innerboxx {
    padding: 0;
    text-align: center; }
  .bg-gradient-loop.cz-join-community .ui.container {
    padding: 0; }
  .community_Tab h6.ui.header.textGradient.h6 {
    text-align: center;
    display: block;
    width: 100%; }
  .community_Tab h2.ui.header,
  .community_Tab p {
    text-align: center; }
  .bg-gradient-loop.cz-join-community .ui.container .sixteen.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.join-community-card {
    padding: 0;
    margin: 0; }
  .ui.inverted.link.list.post_tabs {
    padding: 8px 0 !important;
    display: block !important;
    text-align: center; }
  .ui.inverted.link.list.post_tabs a.item.nav-link {
    display: inline-block !important;
    margin: 10px 10px; }
  .ui.container.feed.cz-con-new .ui.grid {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .ui.container.feed.cz-con-new .ui.grid .two.wide.column {
    margin-left: 0; }
  .ui.container.feed.cz-con-new .ui.grid .middle.aligned.row .column {
    padding: 0; }
  .ui.container.feed.cz-con-new .bottom.aligned.row .column.dark-text {
    padding: 0; }
  .categories.categories__mb .column {
    width: 100% !important; }
  .total-events-cz .row .four.wide.column {
    width: 50% !important; }
  .ui.grid.cz-tabs-profile .row .five.wide.column {
    padding: 0 !important; }
  .ui.grid.cz-tabs-profile .row .five.wide.column button {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
  .sixteen.wide.column.cz-tabs-newest .row .twelve.wide.column {
    width: 100% !important; }
  .sixteen.wide.column.cz-tabs-newest .row .twelve.wide.column a {
    width: 50%;
    float: left; }
  .sixteen.wide.column.cz-tabs-newest .row .twelve.wide.column .ui.small.borderless.fluid.four.item.menu {
    display: block; } }

@media only screen and (max-width: 540px) {
  .row.sub-header.cz-post-header-top .post-date .post-author button {
    width: 100%; }
  .bg-gradient-loop.cz-rewards-hub .ui.container {
    padding: 0; }
  .bg-gradient-loop.cz-rewards-hub .ui.container .row.rewards-section.cz-rewards {
    margin: 0; }
  .row.rewards-section.cz-rewards > div {
    padding: 0; }
  .row.rewards-section.cz-rewards > div .four.wide.column {
    padding: 0; }
  .row.rewards-section.cz-rewards > div .stretched.twelve.wide.column {
    padding: 0; }
  .row.rewards-section.cz-rewards .four.wide.column a.item {
    width: 100%;
    align-items: center;
    justify-content: center; } }

@media only screen and (max-width: 480px) {
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .four.wide.column.avatar.cz-profile-avtar {
    width: 100px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 30px; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .four.wide.column.avatar.cz-profile-avtar .circle-border {
    margin: 0; }
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .twelve.wide.column.description.cz-decription span.name,
  .cz-feedSection.tokenGraph.person-detialing.only-mobile-small .twelve.wide.column.description.cz-decription .rank {
    padding-left: 115px; }
  .two-butons-folder {
    padding-left: 118px; }
  .cz-feedSection.tokenGraph.person-detialing + .cz-feedSection.tokenGraph .total-events-cz .ui.grid .ui.grid {
    padding: 10px 15px !important; }
  .all-cz-socials {
    display: block;
    float: left;
    width: 100%; }
  .all-cz-socials span {
    float: left;
    margin: 5px 10px 5px 0; }
  .empty-mobile {
    margin-bottom: 5px; }
  .cz-recaptcha div iframe {
    transform: scale(0.8);
    transform-origin: left; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details {
    padding: 0; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details p.name {
    padding-left: 0;
    padding-right: 0; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details p.username {
    padding-left: 0;
    padding-right: 0; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details button.ui.button.show-my-profile-mobile {
    margin-left: 0; } }

body .start-learning-card .swiper-button-next {
  left: auto !important;
  right: 10px !important; }

body .start-learning-card .swiper-button-prev {
  left: 10px !important;
  right: auto !important; }

@media only screen and (max-width: 450px) {
  .cz-recaptcha div iframe {
    transform: scale(0.7); }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-next {
    right: 0 !important; }
  .team-slider .swiper-container.swiper-full-mobile .swiper-button-prev {
    left: 0 !important; }
  .row.appDownload .sixteen.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.team-slider {
    padding: 0; }
  .bg-gradient-loop .ui.container.cz-support.cz-support-set .row.join-community.start-learning .start-learning-card .swiper-container {
    padding: 0 40px; }
  .join-community-card,
  .start-learning-card {
    padding: 0 !important;
    margin: 0; }
  .team-slider .swiper-slide.swiper-slide-active {
    width: 100% !important; }
  .row.appDownload .sixteen.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.appDownload_box {
    padding-left: 0;
    padding-right: 0; }
  .categories.cz-categories a .categories_bg__active h3.ui.header {
    padding: 0 0 5px; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.loopCard.userProfileCard {
    padding: 20px 0; }
  .ui.inverted.link.list.post_tabs {
    padding: 10px 0; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.loopCard.userProfileCard {
    padding: 20px 0;
    display: flex;
    flex-direction: inherit;
    align-items: center;
    padding-left: 15px; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profileInner {
    width: 100px; }
  .userProfileCard .profilePic {
    width: 100px !important;
    height: 100px !important; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details {
    width: calc(100% - 120px); } }

/* end token css */
.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off button svg path {
  fill: white; }

.ui.grid.cz-new-js > .ui.container .ui.grid.my-grid .ui.container .row.editor .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off button:hover {
  background: #444444; }

.fr-qi-helper {
  display: flex;
  align-items: center; }

.cz-textarea-setting + .actions {
  position: relative;
  top: -60px;
  text-align: right; }

.cz-textarea-setting + .actions button {
  background: #c83e93;
  color: black;
  font-weight: normal; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control {
  background: #111;
  border-radius: 10px; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control input.prompt {
  background: #1b1b1b;
  border: 1px solid #3a3a3a;
  padding-right: 0 !important;
  color: white; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control i.search.icon {
  color: white; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control .accordion.ui .title.accordion-wrapper {
  background: #1b1b1b; }

.sixteen.wide.column.cz-write-message .post-header.post-header__visual h3 {
  width: 100%; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control .accordion.ui .title.accordion-wrapper .accordion-text {
  color: white;
  font-weight: normal; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control .accordion.ui .title.accordion-wrapper label::before {
  background: #1b1b1b;
  border: 1px solid #333333; }

.ui.grid.cz-new-js .post-categories-n-tags .categories-control .accordion.ui .title.accordion-wrapper label::after {
  color: #c83e93 !important; }

.fr-qi-helper a {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important; }

.editor .fr-box.fr-basic .fr-wrapper {
  height: 600px !important;
  min-height: 600px !important;
  margin-bottom: 0px; }

.fr-second-toolbar:empty {
  min-height: calc(0px) !important;
  border: 0px !important; }

.page-sidebar {
  display: flex !important; }

.bugSidebar {
  display: block; }

li.nav-item {
  cursor: pointer;
  font-size: 16px;
  color: #fff !important;
  padding: 0 8px;
  text-decoration: none !important;
  padding: 3px 13px;
  margin: auto !important; }
  li.nav-item.active {
    background: #1b1b1b6b;
    border-radius: 6px; }

@media only screen and (max-width: 370px) {
  .ui.grid.hero_section .headerBtnBox.position-relative {
    flex-direction: column; }
  .ui.grid.hero_section .headerBtnBox.position-relative button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0,
  a.tutorialLink {
    margin: 0;
    width: 120px; }
  a.tutorialLink button.ui.button.btn.headerBtn.px-3.mb-2.mb-lg-0 {
    width: 100% !important; }
  .ui.inverted.link.list.post_tabs a.item.nav-link {
    margin: 5px;
    font-size: 13px !important; }
  .cz-recaptcha div iframe {
    transform: scale(0.5); }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details button {
    padding: 8px 28px 8px 8px;
    font-size: 12px;
    font-weight: 500; } }

@media only screen and (max-width: 350px) {
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profileInner {
    width: 70px !important;
    height: 70px; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profileInner .ui.grid.profilePic.position-relative {
    height: 70px !important;
    width: 70px !important; }
  .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.only-for-mobile .ui.grid.profilePic-details {
    width: calc(100% - 90px); } }

.openSidebar {
  margin-top: 250px !important;
  color: white;
  font-weight: 700; }

.viewEye {
  width: 22px; }

.passwordInput {
  width: 100%; }

.passwordField {
  display: flex; }

.cz-textarea-setting + .actions {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px; }

.actions.btn-wrap .right-btn {
  border: none !important;
  background: #c83e93 !important;
  color: #000 !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  border-radius: 4px;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  cursor: pointer;
  margin-left: 10px;
  text-transform: uppercase;
  margin: 5px 4px; }

.copyLink {
  display: inline-flex;
  justify-content: center;
  justify-items: center; }

.copyIcon {
  margin-left: 12px;
  margin-top: 14px; }

.modal,
.mini.modal {
  position: fixed;
  top: 5% !important;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6); }

.modals.dimmer .ui.scrolling.modal {
  position: fixed !important;
  left: 0;
  right: 0;
  margin: auto !important; }

.modals.dimmer.transition.visible.active {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px); }

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.display-block {
  display: block; }

.display-none {
  display: none; }

.cz-post-comments .comments-container .attached.menu {
  width: 270px;
  max-width: 270px; }

.ui.form.loginFormBox .field iframe {
  transform: scale(1) translate(0px, 10px); }

.sidebar-wrapper .sidebar.menu {
  display: none !important; }

@media only screen and (max-width: 1024px) {
  .sidebar-wrapper.cz-mobile-menu .sidebar.menu {
    display: block !important;
    z-index: 99999; }
  .ui.form.loginFormBox .field iframe {
    -webkit-transform: scale(80%) translate(-35px, 10px);
    transform: scale(80%) translate(-35px, 10px); }
  body .five.wide.computer.sixteen.wide.mobile.sixteen.wide.tablet.column.right_side.cz-com-page-profile {
    width: 100% !important;
    padding: 0 15px; }
  .cz-tokens-mobile-td .icon-table {
    padding: 0; } }

@media only screen and (min-width: 1025px) and (max-width: 1450px) and (orientation: landscape) {
  .cz-new-edit .ui.grid.cz-new-js {
    width: 64%; }
  .ui.grid.cz-new-js {
    width: 64%; } }
