@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.discover {
  width: 100%;
  //height: 970px;
  padding: 100px 50px;
  text-align: center;

  background-image: url('../../../../../staticAssets/missionbg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include mobile {
    padding: 0;

    .column {
      width: 100% !important;
    }
  }

  @include tablet {
    .row {
      display: flex;
      justify-content: center;
    }
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .container {
    max-width: 1450px !important;
    margin: auto;
  }

  .discoverContainer {
    margin-top: 63px;
  }

  .header {
    h3 {
      padding: 20px;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 53px;
      text-align: left;
      letter-spacing: -0.02em;
      color: #fff0fa;
  
      span {
        background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
      @include mobile {
        padding: 0;
        font-size: 28px;
        line-height: 24px;
      }
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 72px;

    @include mobile {
      margin-bottom: 0;
    }

    .item {
      background: transparent !important;
      border: none !important;

      .card {
        width: 100%;
        height: 100%;
        background-color: transparent !important;
        box-shadow: none;
        padding: 5px;

        @include mobile {
          margin: auto;
        }

        .content {
          display: flex;
          //justify-content: space-between;
          align-items: center;
          //gap: 80px;
          height: 100%;

          @include mobile {
            display: block;
          }

          .details {
            //width: 100%;
            width: 80%;
            text-align: left;
            h3 {
              font-weight: 600;
              font-size: 48px;
              line-height: 56px;
              letter-spacing: -0.02em;
              color: #fff0fa;
          
              margin: 20px 0 18px;
          
              span {
                background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
          
              @include mobile {
                padding: 0;
                font-size: 32px;
                line-height: 34px;
              }
            }
            .subContent {
              color: #999CB6;
              padding: 10px 0;
            }
            .sub_title {
              font-weight: 500;
              font-size: 13.2997px;
              line-height: 17px;
              letter-spacing: 0.06em;
              text-transform: uppercase;
              -webkit-font-feature-settings: "cv05" on, "cv11" on;
              font-feature-settings: "cv05" on, "cv11" on;
              color: #86E2EE;
            }
            .desc {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.02em;
              p {
                display: flex;
                gap: 14px;
                align-items: center;

                color: #FAFAFA;
                img {
                  width: 28px;
                }
              }
            }
          }
        }

        .image_container {
          width: 800px;
          border-radius: 8px;
          img {
            border-radius: 8px;
          }

          @include mobile {
            width: 100%;
            padding: 20px 0;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }

  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    background: rgb(2,0,31);
    background: linear-gradient(180deg, #03011e 0%, rgba(2,0,31,0) 50%, rgba(2,0,31,0) 50%, #03011e 100%);
  }
}