@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.section {
  margin: 170px 0;
  width: 100%;
  @include mobile {
    padding: 20px;

    .break {
      display: none !important;
    }
  }

  .header {
    margin: 40px 0;
    text-align: center;

    h3 {
      font-family: 'Lexend';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      letter-spacing: -0.03em;

      color: #FFFFFF;

    }

    b {
      color: #59d6e6;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */
      letter-spacing: 0.06em;
      text-transform: uppercase;
    }

    span {
      //display: block;
      background: -webkit-linear-gradient(358deg, rgba(148, 123, 242, 1), #2CA9F9) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }
  }

  .services {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 80px !important;

    .serviceItem {
      position: relative;
      flex-basis: calc(50% - 20px);

      width: 322px;
      min-height: 280px;
      padding: 40px;

      background: #0d0b27;
      border-radius: 16px;

      &::after {
        position: absolute;
        content: '';
        bottom: -28px;
        right: 40px;
        transform: rotate(55deg);
        border-radius: 8px;

        width: 40px;
        height: 70px;

        background-color: #0d0b27;
      }

      .quote {
        position: absolute;
        top: 28px;
        right: 20px;
      }

      .profile {
        display: flex;
        gap: 12px;
        align-items: center;
        img {
          width: 40px;
          border-radius: 100px;
        }

        .name {
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          font-feature-settings: 'cv05'on, 'cv11'on;

          color: #F6F5FF;
        }

        .role {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;

          color: rgba(246, 245, 255, 0.6);
        }
      }
    }

    @include mobile {
      display: block;
      width: 100%;

      .serviceItem {
        margin-bottom: 40px;
        padding-bottom: 60px;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 16px;
        width: 100%;
      }
    }

    div {
      width: 100%;
    }

    .serviceImage {
      width: 56px !important;
      height: 56px !important;
    }

    .content {
      padding: 20px 0;

      .readMore {
        position: absolute;
        bottom: 20px;
        left: 40px;

        display: flex;
        gap: 4px;
        align-items: center;

        img {
          width: 20px;
        }

        color: #887dea;
        text-decoration: none;

        &:hover {
          opacity: 0.7;
        }
      }

      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 44px;

        letter-spacing: -0.03em;

        color: #FFFFFF;
      }

      p {
        font-style: italic;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;

        color: #fff;
      }
    }
  }
}