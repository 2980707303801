@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}

.button {
  padding: 20px !important;
  width: 200px !important;

  background: #6959E6 !important;
  box-shadow: 2px 2px 6px rgba(33, 57, 143, 0.4);
  border-radius: 8px !important;
  border: none;

  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  transition: ease-in-out 200ms !important;

  &:hover {
    background: #4438a1 !important;
    color: #fff !important;
  }
}

.button_outline {
  background: transparent !important;
  color: #887DEA !important;
  border: solid 2px #887DEA !important;
  border-radius: 12px !important;

  &:hover {
    background: #887DEA !important;
    color: #fff !important;
  }
}

.btn_sm {
  width: fit-content !important;
  //width: 150px !important;
}