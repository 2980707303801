.p_5 {
    padding: 5px !important;
}

.p_10 {
    padding: 10px !important;
}

.p_15 {
    padding: 15px !important;
}

.p_20 {
    padding: 20px !important;
}

.p_25 {
    padding: 25px !important;
}

.pt_5 {
    padding-top: 5px !important;
}

.pt_10 {
    padding-top: 10px !important;
}

.pt_15 {
    padding-top: 15px !important;
}

.pt_20 {
    padding-top: 20px !important;
}

.pt_25 {
    padding-top: 25px !important;
}

.pb_5 {
    padding-bottom: 5px !important;
}

.pb_10 {
    padding-bottom: 10px !important;
}

.pb_15 {
    padding-bottom: 15px !important;
}

.pb_20 {
    padding-bottom: 20px !important;
}

.pb_25 {
    padding-bottom: 25px !important;
}

.px_5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.px_10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.px_15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.px_20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.px_25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}