@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap');

@mixin mobile {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 901px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg_desktop {
  @media (min-width: 1800px) {
    @content;
  }
}
.hero {
  background-image: url("./assets/digitaluni.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  padding: 50px 50px;
  margin-top: 50px;

  position: relative;
  overflow: hidden;

  @include mobile {
    display: none;
  }

  .overlay {
    background: rgb(3, 1, 30);
    background: linear-gradient(0deg, #03011e 0%, rgba(255, 255, 255, 0) 50%, #03011e 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include mobile {
      background: rgb(3, 1, 30);
      background: linear-gradient(0deg, #03011e 35%, rgba(3, 1, 30, 0) 50%, #03011e 90%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }


  position: relative;
  .images {
    position: absolute;
    display: flex;
    //gap: 50%;
    align-items: center;
    margin: auto;
    justify-content: space-between;

    bottom: 120px;

    width: 100%;
    padding-left: 200px;
    padding-right: 200px;

    img {
      width: 300px;
      opacity: 0.5;
    }

    @include tablet {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .container {
    display: flex;
    justify-content: center; /* horizontal center */
    height: 100%;
  }

  .heading {
    position: relative;
    bottom: 0;

    @include mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
    }

    .heading_content {
      text-align: center;
    }

    h3 {
      font-weight: 600;
      font-size: 48px;
      line-height: 53px;
      text-align: center;
      letter-spacing: -0.03em;

      color: #FFFFFF;

      @include mobile {
        font-size: 36px;
        line-height: 44px;
      }

      span {
        background: linear-gradient(104.76deg, #9B83F9 2.7%, #8164F5 24.47%, #27AEF8 47.07%, #31A2FA 73.41%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      text-align: center;

      color: #BFC0D1;

      @include mobile {
        font-size: 16px;
      }
    }
  }

  .button {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 209px;
    height: 56px;
    color: #02001f;
    text-decoration: none;
    background-color: #59d6e6;
    border: 1px solid #59d6e6;
    border-radius: 12px;

    margin-top: 40px;
    transition: ease-in-out 0.25s;

    .icon {
      font-size: 28px;
    }

    &:hover {
      color: #02001f;
      background-color: #33919e;
    }

    @include mobile {
      width: 100%;
    }
  }
}